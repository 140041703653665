import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { FormatHttpGetResponse, HttpGeneralResponse,characteristic,conversationsStatus,userProfileDetails} from '../main-page/formatHttpGetResponse';
import { QnA, SiteTexts} from '../site-texts'
import { Observable } from 'rxjs';
import { DomSanitizer, SafeUrl, SafeStyle, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.css']
})
export class InfoComponent implements OnInit {

  constructor(private _http: HttpClient, private sanitizer:DomSanitizer, private route: ActivatedRoute) { }
  infoID:string="1";
  qnaList: QnA[]=[];
  apiValues:string[];
  caption:string="";
  subcaption:string="";

  navBarLoggedIn:string = "undefined";

  ngOnInit() {
    
    document.body.style.overflow="auto";
    this.sendStatRequest("EnteredInfo");

    this.infoID = this.route.snapshot.paramMap.get('infoID');
    switch (this.infoID){
      case "1": {
        this.showQnA();
        break;
      }
      case "2": {
        this.showUsageCond();
        break;
      }
      case "3": {
        this.showPrivacy();
        break;
      }
      default: { 
        this.showQnA();
        break;
      } 
    }
    //this.qnaList = SiteTexts.getQnA();
    //this.caption = "הסברים עלינו";
    //this.sanitizeHtml(this.qnaList);
  }

  showQnA(){
    this.caption = "הסברים עלינו";
    this.subcaption = "";
    this.qnaList = SiteTexts.getQnA();
    this.sanitizeHtml(this.qnaList);
  }
  showUsageCond(){
    this.caption = "תנאי שימוש באתר";
    this.subcaption = "(באדיבות הפרקטיקה לתמיכה משפטית בחדשנות וביזמות, המכללה האקדמית ספיר)";
    this.qnaList = SiteTexts.getUsageCond();
    this.sanitizeHtml(this.qnaList);
  }
  showPrivacy(){
    this.caption = "מדיניות פרטיות";
    this.subcaption = "(באדיבות הפרקטיקה לתמיכה משפטית בחדשנות וביזמות, המכללה האקדמית ספיר)";
    this.qnaList = SiteTexts.getPrivacy();
    this.sanitizeHtml(this.qnaList);
  }
  sanitizeHtml(obj:QnA[]){
    for (let i:number = 0; i< obj.length ; i++){
      //question
      obj[i].questionHtml = this.sanitizer.bypassSecurityTrustHtml(obj[i].question);
      //answer
      obj[i].answerHtml=[];
      if (obj[i].answer != null){
        for (let j:number = 0; j< obj[i].answer.length; j++)
        {
          obj[i].answerHtml.push(this.sanitizer.bypassSecurityTrustHtml(obj[i].answer[j]));
        }
      }
      //complex answer
      if (obj[i].complex_answer != null){
        this.sanitizeHtml(obj[i].complex_answer);
      }
    }
  }

  async sendStatRequest(message:string=""){
    let postBody = {
      "postType" : 99,
      "message" : message,
    }
    let headers = new HttpHeaders()
      .set('Content-Type','application/json');
    let postObservable:Observable<FormatHttpGetResponse[]> = this._http.post<FormatHttpGetResponse[]>('/api/ClientMsg',postBody,{headers}); 
    postObservable.subscribe(
      dataFromServer => { this.onServerReply_GetStatAck(dataFromServer);},
      error => this.apiValues = ['error on http in checkServerMsg','error found']
    )
  }
  
  async onServerReply_GetStatAck(dataFromServer:FormatHttpGetResponse[]){
    // do nothing
  }

}
