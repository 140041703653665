import { Component, OnDestroy, OnInit } from '@angular/core';
import { PersonInfo} from '../person-info';
import { delay } from 'q';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { FormatHttpGetResponse, HttpGeneralResponse,characteristic,conversationsStatus, LocEvent, MsgLog, SigViewedReport, FeatureInfo, SearchStatus, FormSection} from '../main-page/formatHttpGetResponse';
import { Observable } from 'rxjs';
import { getParseErrors } from '@angular/compiler';
import { ConvStatComponent } from '../conv-stat/conv-stat.component';
import { DomSanitizer, SafeUrl, SafeStyle } from '@angular/platform-browser';
import { keyframes } from '@angular/animations';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-talk-request',
  templateUrl: './talk-request.component.html',
  styleUrls: ['./talk-request.component.css']
})
export class TalkRequestComponent implements OnInit {
  constructor(private _http: HttpClient, private sanitizer:DomSanitizer) { }
  thumbInfoList: PersonInfo[]=[];                  // all person info for view-people thumbs
  convStatList: conversationsStatus[]=[];          // all conversation statuses for ConvStat Modal
  convStatComponentList: ConvStatComponent[] = []; // actual components list to be able to trigger functions and change params inside 
  convStatPersonList: conversationsStatus[]=[];    // single conversation status to show as part of Person Profile Modal
  convStatPersonInfoList: PersonInfo[]=[];         // all person info for the conversation status list, so that clicking on convstatus image will open profile.  
  apiValues: string[] = [];
  capturedDataFromServer:FormatHttpGetResponse[];  // capturing data arriving from server in each server reply function, but not really using it. Need to remove.
  randButtonTxt : string = "מי פנוי/ה לשיחה?";
  otherOptText : string = "העדפות...";
  showMoreOpt : boolean = false;
  randButtonColor: string = "rgb(52, 94, 209)";
  randButtonBorder: string = "1px solid rgb(52, 94, 209)";
  randButtonFontSize: string = "30px";
  isTalkRequestInFlight: boolean = false;
  isTalkRequestActive : boolean = false;
  countDown : number = 0;
  inFocusPerson : PersonInfo;
  inFocusPersonController : ConvStatComponent;
  directUserID : number = 10000;
  privileges:string[];
  userFirstName : string = "אורח/ת";
  userPriv : boolean = false;
  userGender : string ="female";
  isLoggedIn: boolean;
  isShowLanding: boolean = false;
  hasErrMsg:boolean = false;
  hasNewWatch:boolean = false;
  errMsg:string="קרתה לנו תקלה. נסו שוב מאוחר יותר.";
  amountOfCalls:string="";
  showConvsAtRetrive:boolean=false;
  modalStack:string[] = [];
  meAsParentList:TalkRequestComponent[] = [];  // list with one element, to make the inner selector update
  navBarLoggedIn:string = "undefined";

  convReqInFlight:boolean = false;
  periodicInFlight:boolean = false;
  svr_list:SigViewedReport[] = [];

  locevents:LocEvent[];

  textEnteredByUserInChat:string ="";

  lastLogin:Date;
  statusCode:number = 0;

  //speical - changing....
  specialNotifySelect:string = "specialAddSMS";
  phone:string ="";
  hasSpecialErrMsg:boolean = false;
  specialErrMsg:string = "";
  hasSpecialSucMsg:boolean = false;
  specialSucMsg:string = "";
  isSpecialShowPhone:boolean = false;
  isSpecialShowLoad:boolean = false;

  //extend online module
  premiumFeatures:FeatureInfo[];
  searchStatuses:SearchStatus[] = [
    //new SearchStatus(0, 'מחפשים מישהו לשיחה',false),
    new SearchStatus(0, 'מחפשים מישהו לשיחה',false),
    new SearchStatus(0, 'עדיין על זה, שלחנו לעוד כמה אנשים...', true),
    //new SearchStatus(1, 'יש :) מישהו לחץ על כפתור הוואטסאפ\n, מקווים שיצור קשר בקרוב. אם אין פניה עוד כמה דקות, נסו לבקש שיחה שוב',true)
   ]
  extendOnlineSelect:string = "extendOnlineNone";
  isShowOnlineByFeedbackInfo:boolean = false;
  isShowOnlineByPayInfo:boolean = false;
  isShowOnlinePreview:boolean = false;
  isShowOnlineByPaySection:boolean = false;
  isShowOnlineByFeedbackSection:boolean = false;
  isShowOnlineAproval:boolean = false;
  isShowOnlineInfo:boolean = false;
  isShowOnlineInfoInOnlineTap:boolean = false;
  userFeedbackText:string = "";
  hasOnlineErrMsg:boolean = false;
  isOnlineShowLoad:boolean = false;
  onlineErrMsg:string = "";

  /*
    also required:
    account status
    all this relevant if onlineEntitilement is not "ON"
    info only (wihtout selection) -?
    add link to it from online page
    2nd level view - feedback, or pay
      if feedback - send statistics. feedback text and explanations (one feedback per month), and send, which will update the premium item
      if pay - send statistics. this is underconstruction, try again at September. if you want to we can give you free at this moment (up to 3 times per month) - want? 

  */



  isShowChat:boolean = false;
  isShowOnline:boolean = false;
  isOnlineSlotOpen:boolean = false;
  convInActiveChat:conversationsStatus = null;
  arrowShrink = false;
  arrowExpand = false;

  isShowRedNotify = false;
  convStatAllSigAware = "";


  
  isChatAllowed = true;

  statistics_1:number=214;
  statistics_2:number=33;
  statistics_3:number=41;


  helpnumShown:number=1;
  helpIsLast:boolean = false;

  allFormSections:FormSection[];
  userForm:FormGroup;

  profileRequestsInFlight: boolean = false;
  profileRequestText: string = "צרפו אותי";
  profileState: string = "userStartProfile";

  agreePilotTerms: boolean = true;
  selectedGender = {
    male: true,
    female: true,
    other: true
  };


  ngOnInit() {
    var modalBackdrops = document.getElementsByClassName("modal-backdrop");

    while (modalBackdrops.length > 0) {
      modalBackdrops[0].remove(); // This will completely remove the element
    }

    this.meAsParentList.push(this);
    //this.sendStatRequest("EnteredViewPeople");
    this.getPeople(0);
    document.body.style.overflow="auto";
    let dummyChar:characteristic;
    let dummyChars : characteristic[]=[];
    dummyChar = new characteristic();
    dummyChar.character="";
    dummyChar.textVal="";
    dummyChar.boolVal=false;
    dummyChars.push(dummyChar);
    dummyChars.push(dummyChar);
    this.inFocusPerson = new PersonInfo({parent:this,firstName:"לא נבחר",lastName:"לא נבחר", isAllowed:false, onlineStatus:"", neighborhood:"",city:"", imgFile:"None", age:0, publicId:0,
      characteristics:dummyChars, usertext:""});
    this.amountOfCalls="טוען..."
  }
  
  /*-------------------------------------------*/
  /*-------------------------------------------*/
  /*             SERVER REQUESTS               */
  /*-------------------------------------------*/
  /*-------------------------------------------*/


  /* Called only at init. */
  async getPeople(secDelay:number){
    await delay(secDelay*1000);
    let postBody = {
      "postType" : 1,
      "callerId" : this.directUserID,
    }
    let headers = new HttpHeaders()
      .set('Content-Type','application/json');
    let postObservable:Observable<FormatHttpGetResponse[]> = this._http.post<FormatHttpGetResponse[]>('/api/ClientMsg',postBody,{headers}); 
    postObservable.subscribe(
      dataFromServer => { this.onServerReply_GetPeople(dataFromServer);},
      error => this.apiValues = ['error on http in checkServerMsg','error found']
    )
  }

  

  /* Called only at button click " מי פנוי" */
  async searchListeners(){

    //check request in flight
    if (this.isTalkRequestInFlight || this.isTalkRequestActive) return;

    //check checkbox at bottom
    this.hasErrMsg = false;
    if (!this.agreePilotTerms) {
      this.errMsg = "חשוב לאשר שאתם מבינים ומסכימים שמספר הוואטסאפ שלכם ייחשף לכמה א/נשים";
      this.hasErrMsg = true;
      this.showErrMsg();
      return;
    }
    if (!this.selectedGender.female && !this.selectedGender.male && !this.selectedGender.other){
      this.errMsg =  "נא לבחור עם מי תהיו מוכנים לשוחח, גבר/אישה/אחר-ת";
      this.hasErrMsg = true;
      this.showErrMsg();
      return;
    }



    //check logged in
    if (!this.isLoggedIn) {this.openModal('ModalProfile','btnModalProfile'); return;}

    //execute    
    this.isTalkRequestInFlight = true;
    this.randButtonTxt = "רק רגע בבקשה";
    this.randButtonColor = "#ccc";
    this.randButtonBorder = "0px";
    this.randButtonFontSize = "20px";
    
    this.closeHelp();

    if (!this.isLoggedIn){
      this.hasErrMsg = true;
      this.errMsg = "ראשית עליכם להתחבר או לפתוח פרופיל חדש";
      this.showErrMsg()
      return;
    }


    let postBody = {
      "postType" : 401,
      "callerId" : this.directUserID,
      "userProfileDetails" : {
        "peoplePref":{
          "genderSelect" : this.selectedGender
        }
      }
    }
    let headers = new HttpHeaders()
      .set('Content-Type','application/json');
    let postObservable:Observable<FormatHttpGetResponse[]> = this._http.post<FormatHttpGetResponse[]>('/api/ClientMsg',postBody,{headers}); 
    postObservable.subscribe(
      dataFromServer => { this.onServerReply_SearchListeners(dataFromServer);},
      error => this.apiValues = ['error on http in checkServerMsg','error found']
    )
  }

  /* Called only at button click " ביטול" */
  async cancelSearch(){
    if (!this.isTalkRequestActive) return;
    if (!this.isLoggedIn){
      this.hasErrMsg = true;
      this.errMsg = "ראשית עליכם להתחבר או לפתוח פרופיל חדש";
      this.showErrMsg()
      return;
    }

    let postBody = {
      "postType" : 402,
      "callerId" : this.directUserID,
    }
    let headers = new HttpHeaders()
      .set('Content-Type','application/json');
    let postObservable:Observable<FormatHttpGetResponse[]> = this._http.post<FormatHttpGetResponse[]>('/api/ClientMsg',postBody,{headers}); 
    postObservable.subscribe(
      dataFromServer => { this.onServerReply_CancelSearchListeners(dataFromServer);},
      error => this.apiValues = ['error on http in checkServerMsg','error found']
    )

  }

  request_initcode(){
    if (this.profileRequestsInFlight) return;
    this.hasSpecialErrMsg = false;
    this.hasSpecialSucMsg = false;
    

    if (!this.userForm.get("phone.phoneNumber").valid){
      this.hasSpecialErrMsg = true;
      this.specialErrMsg = "מספר הטלפון לא תקין";
      return;
    }
    if (!this.userForm.get("personalInfo").valid || !this.userForm.get("loginInfo").valid){
      this.hasSpecialErrMsg = true;
      this.specialErrMsg = "חשוב למלא את כל הפרטים לפני שמתקדמים..";
      return;
    }

    this.profileRequestsInFlight = true;
    this.profileRequestText =  "רק רגע בבקשה";
    let phone2val:string = this.userForm.get("phone.phoneNumber").value;

    let postBody = {
      "postType" : 13,
      "messageType" : "phoneNumApproval",
      "userProfileDetails" : {
        phone:phone2val,
      }
    }
    let headers = new HttpHeaders()
      .set('Content-Type','application/json');
    let postObservable:Observable<FormatHttpGetResponse[]> = this._http.post<FormatHttpGetResponse[]>('/api/ClientMsg',postBody,{headers}); 
    postObservable.subscribe(
      dataFromServer => { this.onServerReply_RequestInitCode(dataFromServer);},
      error => this.apiValues = ['error on http in checkServerMsg','error found']
    )
  }

  open_profile(){
    if (this.profileRequestsInFlight) return;
    this.hasSpecialErrMsg = false;
    this.hasSpecialSucMsg = false;
    this.profileRequestsInFlight = true;
    this.profileRequestText =  "רק רגע בבקשה";

    let gender:string = "other";
    if (this.userForm.get("personalInfo.gender").value=="אישה") gender = "female";
    if (this.userForm.get("personalInfo.gender").value=="גבר") gender = "male";



    let postBody = {
      "postType" : 6,
      "messageType" : "talker_" + this.userForm.get("codeValidation.verificationCode").value,
      "userProfileDetails" : {
        firstName : this.userForm.get("personalInfo.privateName").value,
        email:this.userForm.get("loginInfo.email").value,
        password:this.userForm.get("loginInfo.password").value,

        age:this.userForm.get("personalInfo.age").value,
        gender:gender,
        phone:this.userForm.get("phone.phoneNumber").value,
      }
    }
      
      
      let headers = new HttpHeaders()
        .set('Content-Type','application/json');
      let postObservable:Observable<FormatHttpGetResponse[]> = this._http.post<FormatHttpGetResponse[]>('/api/ClientMsg',postBody,{headers}); 
      postObservable.subscribe(
        dataFromServer => { this.onServerReply_openProfile(dataFromServer);},
        error => this.apiValues = ['error on http in checkServerMsg','error found']
      )
  }

  close_profile_modal_and_resend_talkrequest(){
    this.closeModal('ModalProfile','btnModalProfile');
    if (this.profileState == "systemToSendTalkRequest"){
      this.searchListeners();
    }
    else {
      this.init_profile_form();
    }
  }
  
  init_profile_form(){
    for (let i:number=0 ; i< this.allFormSections.length; i++) this.allFormSections[i].isVisible = false;
    this.allFormSections.find(x=>x.name=="loginInfo").isVisible = true;
    this.allFormSections.find(x=>x.name=="personalInfo").isVisible = true;
    this.allFormSections.find(x=>x.name=="phone").isVisible = true;
    this.profileState = "userStartProfile";
    this.profileRequestText = "צרפו אותי";
    this.hasSpecialErrMsg = false;
    this.hasSpecialSucMsg = false;
  }
  
  /* Called at button click " סטטוס פניות " w/ show=true */
  /* Called at onServerReply_GetPeople  w/ show=false*/
  getConvStatus(show:boolean=false){
    if (show) this.closeHelp();
    if (this.convReqInFlight) return; this.convReqInFlight = true;

    //this.sendStatRequest("getConvStatus");
    this.showConvsAtRetrive = show;

    let postBody = {
      "postType" : 4,
      "callerId" : this.directUserID,
      "message"  : (show)?"show":"dont show",
      "msgPostText" : "v20241211a",
    }
    let headers = new HttpHeaders()
      .set('Content-Type','application/json');
    let postObservable:Observable<FormatHttpGetResponse[]> = this._http.post<FormatHttpGetResponse[]>('/api/ClientMsg',postBody,{headers}); 
    postObservable.subscribe(
      dataFromServer => { this.onServerReply_GetConvStatus(dataFromServer);},
      error => this.apiValues = ['error on http in checkServerMsg','error found']
    )

  }



  /* Called at button clicks from conv-stat components "טקסט" "פינג" ... */
  async sendConvRequest(postBody){
    if (this.convReqInFlight) return; this.convReqInFlight = true;
    postBody.callerId = this.directUserID;
    let highlightfirst:boolean=false;
    if (postBody.messageType != "clear_del") highlightfirst = true;
    let headers = new HttpHeaders()
      .set('Content-Type','application/json');
    let postObservable:Observable<FormatHttpGetResponse[]> = this._http.post<FormatHttpGetResponse[]>('/api/ClientMsg',postBody,{headers}); 
    postObservable.subscribe(
      dataFromServer => { this.onServerReply_GetConvStatus(dataFromServer,highlightfirst);},
      error => this.apiValues = ['error on http in checkServerMsg','error found']
    )
  }

  async sendStatRequest(message:string=""){
    //await delay(10);
    let postBody = {
      "postType" : 99,
      "message" : message,
      "callerId" : this.directUserID,
    }
    let headers = new HttpHeaders()
      .set('Content-Type','application/json');
    let postObservable:Observable<FormatHttpGetResponse[]> = this._http.post<FormatHttpGetResponse[]>('/api/ClientMsg',postBody,{headers}); 
    postObservable.subscribe(
      dataFromServer => { this.onServerReply_GetStatAck(dataFromServer);},
      error => this.apiValues = ['error on http in checkServerMsg','error found']
    )


  }

  async sendPeriodic(message:string=""){
    if (this.periodicInFlight) return; this.periodicInFlight = true;


    let postBody = {
      "postType" : 201,
      "message" : message,
      "callerId" : this.directUserID,
      "sigViewedReports" : this.svr_list,
    }
    let headers = new HttpHeaders()
      .set('Content-Type','application/json');
    let postObservable:Observable<FormatHttpGetResponse[]> = this._http.post<FormatHttpGetResponse[]>('/api/ClientMsg',postBody,{headers}); 
    postObservable.subscribe(
      dataFromServer => { this.onServerReply_GetPeriodic(dataFromServer);},
      error => this.apiValues = ['error on http in checkServerMsg','error found']
    )

    // In this version we are not waiting for Ack:
    this.svr_list = [];
  }

  pushSigViewed(convId,viewed_sig,amountOfMsgs){
    let svr:SigViewedReport = new SigViewedReport();
    svr.amountOfMsgs=amountOfMsgs;
    svr.convId=convId;
    svr.viewed_sig=viewed_sig;
    this.svr_list.push(svr);
  }



  async periodicCountDownDec(){
    
    if (this.isLoggedIn)
    { 
      await delay(200);
      if (document.visibilityState === "visible"){
        this.sendPeriodic(this.convStatAllSigAware);
        let nowDate:Date = new Date();
        if (nowDate.getHours()==18 || this.privileges.includes("onlineSlotOpen")) this.isOnlineSlotOpen = true;
        else this.isOnlineSlotOpen = false;
      }
      if (this.isShowChat){
        await delay(1800); //every 2sec in case of chat
      }
      else{
        await delay(4800); //every 5sec
      }
      this.periodicCountDownDec();
    }
  }




  /*-------------------------------------------*/
  /*-------------------------------------------*/
  /*             SERVER REPLYS                 */
  /*-------------------------------------------*/
  /*-------------------------------------------*/

  onServerReply_GetPeople(dataFromServer:FormatHttpGetResponse[]){
    this.capturedDataFromServer = dataFromServer;
    if (dataFromServer.length>0 && dataFromServer[0].userFirstName != ""){
      this.userFirstName = dataFromServer[0].userFirstName;
      this.userPriv = this.userFirstName.includes("_priv");
      this.lastLogin = dataFromServer[0].lastLogin;
      this.statusCode = dataFromServer[0].statusCode;
      this.privileges = dataFromServer[0].privileges;

      this.isLoggedIn = true;
      if (dataFromServer[0].userGender != ""){
        this.userGender = dataFromServer[0].userGender;
        if (this.userGender=="female"){
          //this.randButtonTxt = "אני רוצה לדבר"
          //this.randButtonTxt = "חפשו לי שיחה"
          this.randButtonTxt = "מי פנוי/ה לשיחה?"
        }
        else{
          this.randButtonTxt = "מי פנוי/ה לשיחה?"
        }
      }
    }
    if (this.userFirstName.length>20 ){
      this.userFirstName = this.userFirstName.substring(0,17)+"...";
    }
    this.locevents = dataFromServer[0].locevents;
    if (this.locevents == null) this.locevents = [];
    for (let k:number=0; k< this.locevents.length ; k++){
      this.locevents[k].imgSafe = this.sanitizer.bypassSecurityTrustStyle("url("+this.locevents[k].img+")");
    }

    this.populatePersonInfoList(dataFromServer,this.thumbInfoList);

    /*
    if (dataFromServer.length>0 && dataFromServer[0].hgr != null && dataFromServer[0].hgr.length > 0 )    {
      for (let i:number = 0; i< dataFromServer[0].hgr.length ; i++){
        let genRes = dataFromServer[0].hgr[i];
        let thumbI = new PersonInfo({
          parent:this,firstName:genRes.firstName,lastName:genRes.lastName, imgFile:genRes.imgFile , isAllowed:genRes.isAllowed, age:genRes.age, publicId:genRes.publicId, 
          characteristics:genRes.characteristics, usertext:genRes.usertext
        })
        if (genRes.isExample== null){
          thumbI.isExample=false;
        } 
        else{
          thumbI.isExample=genRes.isExample;
        }
        this.thumbInfoList.push(thumbI);
        
        //if (newAvailSlotInfo.isFuture) this.availSlotList.push(newAvailSlotInfo);

      }
    }
    */

    if (this.isLoggedIn) {
      this.getConvStatus();
      this.navBarLoggedIn = "yes";
      if (!this.privileges.includes("onlineSlotOpen") && !this.privileges.includes("onlinePremiumYetToExpire"))
      {
        //check last login was more than 3 days ago
        let lastLoginDate = new Date(this.lastLogin);
        let currentDate = new Date();
        let timeDifference = currentDate.getTime() - lastLoginDate.getTime();
        let dayDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        let datenow = currentDate.getDate();
        let monthnow = currentDate.getMonth();
        

        if (dayDifference >= 3 || (dayDifference >= 2 && datenow==26  && monthnow==8) || (datenow==25 && monthnow==8)){
          this.openModal("ModalOnlineWatchOpt","btnModalOnlineWatchOpt"); this.isShowOnlinePreview=true;
        }
      }
      if (!this.privileges.includes("onlineSlotOpen") && this.privileges.includes("onlinePremiumYetToExpire"))
      {
        this.isShowOnlineInfoInOnlineTap = true;
      }

      this.updateTalkRequestStatueses(dataFromServer);

    }
    else{
      //this.isShowLanding = true;
      this.navBarLoggedIn = "no";
      this.statistics_1 = dataFromServer[0].statistics[0];
      this.statistics_2 = dataFromServer[0].statistics[1];
      this.statistics_3 = dataFromServer[0].statistics[2];
    }

  }


  onServerReply_SearchListeners(dataFromServer:FormatHttpGetResponse[]){
    this.isTalkRequestInFlight = false;
    if (dataFromServer== null || dataFromServer.length==0) {
      this.hasErrMsg = true;
      this.errMsg = "ארעה שגיאה, נסו שוב מאוחר יותר";
      this.showErrMsg()
      return;
    }
    if (!dataFromServer[0].actRes.success){
      this.hasErrMsg = true;
      this.errMsg = dataFromServer[0].actRes.error_message;
      if (this.errMsg=="refresh"){
        window.location.reload();
        return;
      }

      this.showErrMsg()
      return;
    }

    this.updateTalkRequestStatueses(dataFromServer);
  }

  onServerReply_RequestInitCode(dataFromServer:FormatHttpGetResponse[]){
    this.profileRequestsInFlight = false;
    if (dataFromServer== null || dataFromServer.length==0) {
      this.hasSpecialErrMsg = true;
      this.specialErrMsg = "ארעה שגיאה, נסו שוב מאוחר יותר";
      this.profileRequestText =  "סגירה";
      this.profileState = "failed";
      return;
    }
    if (!dataFromServer[0].actRes.success){
      this.hasSpecialErrMsg = true;
      this.specialErrMsg = dataFromServer[0].actRes.error_message;
      this.profileRequestText =  "סגירה";
      this.profileState = "failed";
      return;
    }

    this.profileRequestText = "אישור";
    this.profileState = "userToEnterCode";
    for (let i:number=0 ; i< this.allFormSections.length; i++) this.allFormSections[i].isVisible = false;
    this.allFormSections.find(x=>x.name=="codeValidation").isVisible = true;
  }

  onServerReply_openProfile(dataFromServer:FormatHttpGetResponse[]){
    this.profileRequestsInFlight = false;
    if (dataFromServer== null || dataFromServer.length==0) {
      this.hasSpecialErrMsg = true;
      this.specialErrMsg = "ארעה שגיאה, נסו שוב מאוחר יותר";
      this.profileRequestText =  "סגירה";
      this.profileState = "failed";
      return;
    }
    if (!dataFromServer[0].actRes.success){
      this.hasSpecialErrMsg = true;
      this.specialErrMsg = dataFromServer[0].actRes.error_message;
      this.profileRequestText =  "סגירה";
      this.profileState = "failed";
      return;
    }


    if (dataFromServer.length>0 && dataFromServer[0].userFirstName != ""){
      this.userFirstName = dataFromServer[0].userFirstName;
      this.isLoggedIn = true;
      this.navBarLoggedIn = "yes";
      if (dataFromServer[0].userGender != ""){
        this.userGender = dataFromServer[0].userGender;
      }
      if (this.userFirstName.length>20 ){
        this.userFirstName = this.userFirstName.substring(0,17)+"...";
      }
  
    }


    this.hasSpecialSucMsg = true;
    this.specialSucMsg = "פרטי הפרופיל נשמרו בהצלחה";

    this.profileRequestText =  "המשיכו לחפש לי מי פנוי/ה לשיחה";
    this.profileState = "systemToSendTalkRequest";
    
  }

  onServerReply_CancelSearchListeners(dataFromServer:FormatHttpGetResponse[]){
    if (dataFromServer== null || dataFromServer.length==0) {
      this.hasErrMsg = true;
      this.errMsg = "ארעה שגיאה, נסו שוב מאוחר יותר";
      this.showErrMsg()
      return;
    }
    if (!dataFromServer[0].actRes.success){
      this.hasErrMsg = true;
      this.errMsg = dataFromServer[0].actRes.error_message;
      if (this.errMsg=="refresh"){
        window.location.reload();
        return;
      }
      this.showErrMsg()
      return;
    }

    this.updateTalkRequestStatueses(dataFromServer);

  }

  updateTalkRequestStatueses(dataFromServer: FormatHttpGetResponse[]) {
    //return if not getting data
    if (dataFromServer[0].talkRequestStatuses==null || dataFromServer[0].talkRequestStatuses.length <=0) {
      return;
    }
    //return if data is the same as current
    if (
      this.searchStatuses.length==dataFromServer[0].talkRequestStatuses.length
      &&
      this.searchStatuses[this.searchStatuses.length - 1].updateTime==dataFromServer[0].talkRequestStatuses[dataFromServer[0].talkRequestStatuses.length-1].updateTime
      )
    {
      return;
    }



    this.searchStatuses = dataFromServer[0].talkRequestStatuses;
    
    let latestStatus:SearchStatus = this.searchStatuses[this.searchStatuses.length - 1];
    if (latestStatus.statusType != 3) {
      this.isTalkRequestActive = true;
      this.randButtonTxt = "הבקשה נשלחה";
      this.randButtonColor = "#ccc";
      this.randButtonBorder = "0px";
      this.randButtonFontSize = "20px";
    }
    if (latestStatus.statusType==3){
      this.isTalkRequestActive = false;
      this.randButtonTxt = "מי פנוי/ה לשיחה?";
      this.randButtonColor = "rgb(52, 94, 209)";
      this.randButtonBorder = "1px solid rgb(52, 94, 209)";
      this.randButtonFontSize = "30px";
    }
  }

  
  onServerReply_GetAllowed(dataFromServer:FormatHttpGetResponse[]){
    if (this.isShowOnline) return;

    this.countDown =7;
    this.countDownDec();

    this.thumbInfoList = []; 

    this.capturedDataFromServer = dataFromServer;
    
    if (dataFromServer.length>0 && dataFromServer[0].userFirstName != ""){
      this.userFirstName = dataFromServer[0].userFirstName;
      this.isLoggedIn = true;
    }
    if (this.userFirstName.length>20 ){
      this.userFirstName = this.userFirstName.substring(0,17)+"...";
    }

    this.populatePersonInfoList(dataFromServer,this.thumbInfoList);


  }

  async CleanTumbInfoList(){
    this.thumbInfoList = [];
  }

  onServerReply_GetAllowedOnline(dataFromServer:FormatHttpGetResponse[]){
    if (!this.isShowOnline) return;

    if (dataFromServer== null || dataFromServer.length==0) {
      this.hasErrMsg = true;
      this.errMsg = "ארעה שגיאה, נסו שוב מאוחר יותר";
      this.showErrMsg()
      return;
    }


    if (dataFromServer[0].premiumFeatures != null){
      this.premiumFeatures = dataFromServer[0].premiumFeatures;
     }
 


    this.privileges = dataFromServer[0].privileges;
    if (!this.privileges.includes("onlineSlotOpen") && this.privileges.includes("onlinePremiumYetToExpire"))
    {
      this.isShowOnlineInfoInOnlineTap = true;
    }

    this.thumbInfoList = []; 

    this.capturedDataFromServer = dataFromServer;
    
    if (dataFromServer.length>0 && dataFromServer[0].userFirstName != ""){
      this.userFirstName = dataFromServer[0].userFirstName;
      this.isLoggedIn = true;
    }
    if (this.userFirstName.length>20 ){
      this.userFirstName = this.userFirstName.substring(0,17)+"...";
    }

    this.populatePersonInfoList(dataFromServer,this.thumbInfoList);
  }

  async onServerReply_GetConvStatus(dataFromServer:FormatHttpGetResponse[],highlightFirst:boolean=false){
    this.convReqInFlight = false;
    if (dataFromServer== null || dataFromServer.length==0) {
      this.hasErrMsg = true;
      this.errMsg = "ארעה שגיאה, נסו שוב מאוחר יותר";
      this.showErrMsg()
      return;
    }
    if (!dataFromServer[0].actRes.success){
      this.hasErrMsg = true;
      this.errMsg = dataFromServer[0].actRes.error_message;
      if (this.errMsg=="refresh"){
        window.location.reload();
        return;
      }

      this.showErrMsg()
      return;
    }
    this.hasNewWatch = false;
    this.capturedDataFromServer = dataFromServer;
    if (dataFromServer.length>0 && dataFromServer[0].convStatArr != null  )    { //&& dataFromServer[0].convStatArr.length > 0
      let oldConvStatList:conversationsStatus[] = this.convStatList;
      this.convStatList = [];
      this.convStatComponentList = [];
      for (let i:number = 0; i< dataFromServer[0].convStatArr.length ; i++){
        let convStat = dataFromServer[0].convStatArr[i];
        let convI = new conversationsStatus({
          parent:this,
          initiationID:convStat.initiationID,timeOfInitation:convStat.timeOfInitation,
          otherPersonPublicId:convStat.otherPersonPublicId,otherPersonFirstName:convStat.otherPersonFirstName,
          userGender:this.userGender,
          otherPersonGender:convStat.otherPersonGender, otherPersonOnlineStatus:convStat.otherPersonOnlineStatus,
          otherPersonPic:convStat.otherPersonPic,isMyInitiation:convStat.isMyInitiation,
          isMyTurn:convStat.isMyTurn,lastMessageType:convStat.lastMessageType,
          lastMessage:convStat.lastMessage,timeLastMessage:convStat.timeLastMessage,
        })
        convI.msgLogList = convStat.msgLogList;
        convI.isWaitingApproval = convStat.isWaitingApproval;
        if (convI.msgLogList.length > 0 && convI.msgLogList[convI.msgLogList.length-1].isNewWatch) this.hasNewWatch = true;
        let oldCs:conversationsStatus = oldConvStatList.find(x=>x.initiationID == convI.initiationID);
        if (oldCs != null) convI.isShowRedNotify=oldCs.isShowRedNotify;

        this.convStatList.push(convI);
      }
      this.amountOfCalls = this.convStatList.length.toString();
      this.convStatList.sort((a,b)=>(a.timeLastMessage < b.timeLastMessage)?1:-1);
      if (highlightFirst && this.convStatList.length>0) this.convStatList[0].highlight = "yellow";

      if (this.convStatPersonList.length>0){
        let displayedPersonId = this.convStatPersonList[0].otherPersonPublicId;

        let convWithPerson:conversationsStatus = this.convStatList.find(x=>x.otherPersonPublicId==displayedPersonId);
        if (convWithPerson != null){
          this.convStatPersonList=[];
          this.inFocusPerson.hasConv = true;
          this.convStatPersonList.push(convWithPerson);
          //this.inFocusPerson.convWithPerson = convWithPerson;
        }
        else{
          this.convStatPersonList=[];
          this.inFocusPerson.hasConv = false;
        }
      }
      if (this.convStatList.length==0){
          this.showHelp();
      }

      // recopy convInActiveChat from CS list
      /*
      if (this.isShowChat && this.convInActiveChat != null){
        let convInActiveChat:conversationsStatus = this.convStatList.find(x=>x.initiationID==this.convInActiveChat.initiationID);  
        if (convInActiveChat!=null){
          this.convInActiveChat = convInActiveChat;
        }
      }
      */

    }
    //prepare profiles to show from conv status list, and mark them as allowed    
    this.populatePersonInfoList(dataFromServer,this.convStatPersonInfoList);
    for (let i:number = 0; i< this.convStatPersonInfoList.length ; i++){
      this.convStatPersonInfoList[i].isAllowed = true;
    }
    
    


    if (this.showConvsAtRetrive){
      await delay(300);

      this.openModal("ModalConvStat","btnModalConvStat")

      /*let convStatModalBtn = document.getElementById("btnModalConvStat");
      if (document.getElementById("ModalConvStat").style.display!="block"){
        convStatModalBtn.click();
      }*/
    }

    this.periodicCountDownDec();
  }

  async onServerReply_GetConvUpdate(dataFromServer:FormatHttpGetResponse[],highlightFirst:boolean=false){
    this.convReqInFlight = false;
    if (dataFromServer== null || dataFromServer.length==0) {
      this.hasErrMsg = true;
      this.errMsg = "ארעה שגיאה, נסו שוב מאוחר יותר";
      this.showErrMsg()
      return;
    }
    if (!dataFromServer[0].actRes.success){
      this.hasErrMsg = true;
      this.errMsg = dataFromServer[0].actRes.error_message;
      this.showErrMsg()
      return;
    }
    if (dataFromServer.length==0) return //no updates from server

    this.hasNewWatch = false;
    this.capturedDataFromServer = dataFromServer;
    if (dataFromServer.length>0 && dataFromServer[0].convStatArr != null  )    { //&& dataFromServer[0].convStatArr.length > 0
      let convStat = dataFromServer[0].convStatArr[0];
      let newMsgLogList:MsgLog[] = convStat.msgLogList;
      let initIdToUpdate:string  = convStat.initiationID;
      let convToUpdate:conversationsStatus = this.convStatList.find(x=>x.initiationID==initIdToUpdate);  
      if (convToUpdate!=null){
        convToUpdate.msgLogList = newMsgLogList;
      }  
    }
  }

  async onServerReply_GetStatAck(dataFromServer:FormatHttpGetResponse[]){
    // do nothing
  }

  async onServerReply_GetSpecialNotifyAck(dataFromServer:FormatHttpGetResponse[]){
    this.isSpecialShowLoad = false;
    if (dataFromServer== null || dataFromServer.length==0) {
      this.hasSpecialErrMsg = true;
      this.specialErrMsg = "ארעה שגיאה, נסו שוב מאוחר יותר";
      return;
    }
    if (!dataFromServer[0].actRes.success){
      this.hasSpecialErrMsg = true;
      this.specialErrMsg = dataFromServer[0].actRes.error_message;
      return;
    }
    this.hasSpecialSucMsg = true;
    this.specialSucMsg = "אחלה, מעודכן!"

  }

  async onServerReply_GetPeriodic(dataFromServer:FormatHttpGetResponse[]){
    this.periodicInFlight = false;
    if (dataFromServer== null || dataFromServer.length==0) {
      return;
    }
    if (!dataFromServer[0].actRes.success){
      // dont show error message in periodic
      return;
    }

    
    this.isChatAllowed = true;

    let convStatIdsWithRedFlag:string[] = [];
    
    this.updateTalkRequestStatueses(dataFromServer);



    if (dataFromServer[0].sigUpdates != null && dataFromServer[0].sigUpdates.length > 0){

      for (let i:number = 0; i< dataFromServer[0].sigUpdates.length ; i++){
        if (dataFromServer[0].sigUpdates[i].convId=="all"){
          if (dataFromServer[0].sigUpdates[i].new_red_sig != null){
            this.convStatAllSigAware = dataFromServer[0].sigUpdates[i].new_red_sig;
            this.isShowRedNotify = true;
          }
          else{
            this.isShowRedNotify = false;
          }
        }
        else{
          // update the conv-stat
          if (dataFromServer[0].sigUpdates[i].new_red_sig != null){
            let csIndex:number =  this.convStatList.findIndex(x=>x.initiationID==dataFromServer[0].sigUpdates[i].convId);
            let cs:conversationsStatus =  this.convStatList[csIndex];
            cs.convStatAllSigAware = dataFromServer[0].sigUpdates[i].new_red_sig;
            
            if (this.isShowChat && this.convInActiveChat.initiationID==dataFromServer[0].sigUpdates[i].convId){
              this.convInActiveChat.convStatAllSigAware = dataFromServer[0].sigUpdates[i].new_red_sig;
            }
            
            cs.isShowRedNotify = true;
            convStatIdsWithRedFlag.push(dataFromServer[0].sigUpdates[i].convId);

            
            
            
            let refreshedCs:conversationsStatus = cs;
            this.convStatList.splice(csIndex,1,refreshedCs); //replace the item with itself, hopting to get the updated conv_stat by that...
            let currentMsgsOld:number = refreshedCs.msgLogList.length;
            let currentMsgs:number = refreshedCs.msgLogList.filter(x=>x.fromID==refreshedCs.otherPersonPublicId).length;
            let origCurrentMsgs:number = currentMsgs;
            let convStatObjFromServer:conversationsStatus = dataFromServer[0].sigUpdates[i].convstat_obj;
            let convStatObjFromServerReceivedMsgs:MsgLog[] = convStatObjFromServer.msgLogList.filter(x=>x.fromID==refreshedCs.otherPersonPublicId)
            let amountOfMsgsInSigUpdateOld:number = convStatObjFromServer.msgLogList.length;
            let amountOfMsgsInSigUpdate:number = convStatObjFromServerReceivedMsgs.length;
            if (this.isShowChat && this.convInActiveChat.initiationID==dataFromServer[0].sigUpdates[i].convId){
                


              for (let j=currentMsgs;  currentMsgs < amountOfMsgsInSigUpdate; j++){
              
                refreshedCs.msgLogList.push(convStatObjFromServerReceivedMsgs[j]);
                //if the current user sent message, the server will regurn all conv stats and will refresh the cslist, therefore convInActiveChat is now different entity
                if (refreshedCs!=this.convInActiveChat){
                  this.convInActiveChat.msgLogList.push(convStatObjFromServerReceivedMsgs[j]); 
                }
                currentMsgs = refreshedCs.msgLogList.length;
              }

              this.prepareAllMsg();
              


              /*class handling - need to remove probably...
              for (let j=origCurrentMsgs;  j < amountOfMsgsInSigUpdate; j++){
                dataFromServer[0].sigUpdates[i].convstat_obj.msgLogList[j].msgLogCssClassFinal = dataFromServer[0].sigUpdates[i].convstat_obj.msgLogList[j].msgLogCssClass + " msgNew";
              }

              for (let j=origCurrentMsgs;  j < amountOfMsgsInSigUpdate; j++){
                dataFromServer[0].sigUpdates[i].convstat_obj.msgLogList[j].msgLogCssClassFinal = dataFromServer[0].sigUpdates[i].convstat_obj.msgLogList[j].msgLogCssClass;
              }
              // end of class handling */


              this.scrollToLastInChat();
              this.pushSigViewed(this.convInActiveChat.initiationID,this.convInActiveChat.convStatAllSigAware,this.convInActiveChat.msgLogList.filter(x=>x.fromID==this.convInActiveChat.otherPersonPublicId).length);

            }
            else{
              let componentToUpdate:ConvStatComponent = this.convStatComponentList.find(x=>x.convStatInp.initiationID==dataFromServer[0].sigUpdates[i].convId);

              if (currentMsgs < amountOfMsgsInSigUpdate){
                componentToUpdate.convStatInp.lastMessageType = convStatObjFromServer.lastMessageType;
                componentToUpdate.convStatInp.lastMessage = convStatObjFromServer.lastMessage;
                componentToUpdate.convStatInp.timeLastMessage = convStatObjFromServer.timeLastMessage;
                componentToUpdate.convStatInp.isMyTurn = convStatObjFromServer.isMyTurn;
                componentToUpdate.getMsgFormat();
                componentToUpdate.addHighlight();

                for (let j=currentMsgs;  currentMsgs < amountOfMsgsInSigUpdate; j++){
                  
                  refreshedCs.msgLogList.push(convStatObjFromServerReceivedMsgs[j]);
                  currentMsgs = refreshedCs.msgLogList.length;
                }

                componentToUpdate.prepareAllMsg();
                
                if (componentToUpdate.isShowAllMsg) {
                  componentToUpdate.scrollToLast();
                  this.pushSigViewed(componentToUpdate.convStatInp.initiationID,componentToUpdate.convStatInp.convStatAllSigAware,componentToUpdate.convStatInp.msgLogList.filter(x=>x.fromID==componentToUpdate.convStatInp.otherPersonPublicId).length);
                }
                
              }
              else{
                //if we reached here we have a potential red flag but without new data, due to server not aligend on news. so - update the signature.
                //this.pushSigViewed(componentToUpdate.convStatInp.initiationID,componentToUpdate.convStatInp.convStatAllSigAware,componentToUpdate.convStatInp.msgLogList.filter(x=>x.fromID==componentToUpdate.convStatInp.otherPersonPublicId).length);
              }
            }
            

            
          }
          
        }
      }
      
    }

    for (let i:number=0; i< this.convStatList.length; i++){
      let csHasRedFlag:boolean = convStatIdsWithRedFlag.includes(this.convStatList[i].initiationID);
      if (!csHasRedFlag){
        this.convStatList[i].isShowRedNotify = false;
      }
    }



  }

  
  

  

  prepareAllMsg(){
    if (this.convInActiveChat.msgLogList.length > 0){
      for (let i:number = 0; i< this.convInActiveChat.msgLogList.length ; i++){
        this.convInActiveChat.msgLogList[i].index = i;
        if (this.convInActiveChat.msgLogList[i].fromID==this.convInActiveChat.otherPersonPublicId){
          this.convInActiveChat.msgLogList[i].isFromUser = false;
          this.convInActiveChat.msgLogList[i].fromFirstName= this.convInActiveChat.otherPersonFirstName;
          if (this.convInActiveChat.msgLogList[i].msgType == "ping"){
            if (this.convInActiveChat.otherPersonGender=="female") this.convInActiveChat.msgLogList[i].content="[שלחה אליך פינג]";
            if (this.convInActiveChat.otherPersonGender=="male") this.convInActiveChat.msgLogList[i].content="[שלח אליך פינג]";
          }
        }
        else{
          if (this.userGender=="female") this.convInActiveChat.msgLogList[i].fromFirstName="את";
          if (this.userGender=="male") this.convInActiveChat.msgLogList[i].fromFirstName="אתה";
          this.convInActiveChat.msgLogList[i].isFromUser = true;
          if (this.convInActiveChat.msgLogList[i].msgType == "ping"){
            if (this.convInActiveChat.otherPersonGender=="female") this.convInActiveChat.msgLogList[i].content="[שלחת אליה פינג]";
            if (this.convInActiveChat.otherPersonGender=="male") this.convInActiveChat.msgLogList[i].content="[שלחת אליו פינג]";
          }
        }
        if (this.convInActiveChat.msgLogList[i].isFromUser){
          this.convInActiveChat.msgLogList[i].msgLogCssClass="msgFromUser";
        }
        else{
          this.convInActiveChat.msgLogList[i].msgLogCssClass="msgFromOther";
        }
        this.convInActiveChat.msgLogList[i].msgLogCssClassFinal = this.convInActiveChat.msgLogList[i].msgLogCssClass;


      }
    }
  }


  public async scrollToLastInChat(isSmooth:boolean=true){
    await delay(100);

    let elementId_index:number = this.convInActiveChat.msgLogList.length-1;
    let elementId_index_string:string = elementId_index.toString();
    let elementId:string = this.convInActiveChat.initiationID+'_'+elementId_index_string;
    let itemToView = document.getElementById(elementId);

    if (itemToView != null)  {
      if (isSmooth) itemToView.scrollIntoView({behavior:'smooth',block:'center'});
      else itemToView.scrollIntoView({behavior:'auto',block:'center'});
    }
  }

  /*-------------------------------------------*/
  /*-------------------------------------------*/
  /*              GUI FUNCTIONS                */
  /*-------------------------------------------*/
  /*-------------------------------------------*/

  showMainPage(){
    document.getElementById("welcome-part-id").style.display="block";
  }
  showThumbs(){

    document.getElementById("thumbs-id").style.display="block";
  }

  showPersonProfile(publicIdClicked:number){
    this.closeHelp();
    //this.sendStatRequest("showPersonProfile"+"_"+publicIdClicked.toString());
    if (!this.isLoggedIn){
      this.errMsg = "על מנת להגיע לחברים חדשים, צריך להתחבר או לפתוח פרופיל חדש";
      this.hasErrMsg = true;
      this.showErrMsg();
      return;
    }
    let requestedFocusByThumbs = this.thumbInfoList.find(x=>x.publicId==publicIdClicked);
    let requestedFocusByConv = this.convStatPersonInfoList.find(x=>x.publicId==publicIdClicked);
    let requestedFocus = requestedFocusByConv;
    if (requestedFocus==null) requestedFocus = requestedFocusByThumbs;
    if (requestedFocus.isAllowed){
      this.inFocusPerson = requestedFocus;//this.thumbInfoList.find(x=>x.publicId==publicIdClicked);
      let convWithPerson:conversationsStatus = this.convStatList.find(x=>x.otherPersonPublicId==publicIdClicked);
      if (convWithPerson != null){
        this.convStatPersonList=[];
        this.inFocusPerson.hasConv = true;
        this.convStatPersonList.push(convWithPerson);

        //this.inFocusPerson.convWithPerson = convWithPerson;
        
      }
      else{
        this.convStatPersonList=[];
        this.inFocusPerson.hasConv = false;
      }
      this.openModal("modalPerson","btnModalPerson")
      //let personModalBtn = document.getElementById("btnModalPerson");
      //personModalBtn.click();
      
    }
    else{
      this.hasErrMsg = true;
      if (this.isLoggedIn){
        this.errMsg = "אפשר לפנות רק לחברים שהוגרלו (בתמונות הצבעוניות), או לחברים שכבר נוצר איתם קשר (מופיעים בסטטוס פניות)";
      }
      else{
        this.errMsg = "אפשר לפנות רק לחברים שהוגרלו (בתמונות הצבעוניות). פתחו כרטיס או התחברו כדי להגריל חבר.";
      }
      this.showErrMsg()
    }
  }

  showChat(initiationID:string){
    let convInActiveChat:conversationsStatus = this.convStatList.find(x=>x.initiationID==initiationID);  
      if (convInActiveChat!=null){
        this.convInActiveChat = convInActiveChat;
        this.isShowChat = true;
        this.scrollToLastInChat(false);
        this.pushSigViewed(this.convInActiveChat.initiationID,this.convInActiveChat.convStatAllSigAware,this.convInActiveChat.msgLogList.filter(x=>x.fromID==this.convInActiveChat.otherPersonPublicId).length);
      }  
  }
  hideChat(){
     this.isShowChat = false;
     this.getConvStatus(true);
  }

  async ChatTextSendClicked(){
    //if (this.convReqInFlight) return; this.convReqInFlight = true;
    //this.convReqInFlight = !this.convReqInFlight;
    if (this.textEnteredByUserInChat.length<1) return;
    if (this.textEnteredByUserInChat.length>160) {
      this.hasErrMsg = true;
      this.errMsg = "הטקסט צריך להיות באורך תו אחד לפחות, וקטן מ160 תווים. \n";
      this.errMsg += "כרגע יש " + this.textEnteredByUserInChat.length.toString() + " תווים." ;
      this.showErrMsg();
      return;
    }

    this.arrowChangeSize();
  
    let ml:MsgLog = new MsgLog();
    ml.index = this.convInActiveChat.msgLogList.length;
    ml.fromID = 0;
    ml.msgType=="text";
    ml.content = this.textEnteredByUserInChat;
    this.convInActiveChat.msgLogList.push(ml);
    this.prepareAllMsg();
    this.scrollToLastInChat();

    let postBody = {
      "postType" : 3,
      "callerId" : 0, // to be set in parent
      "referToPerson" : this.convInActiveChat.otherPersonPublicId,
      "messageType" : "text",
      "message" : this.textEnteredByUserInChat,
    }
    this.sendConvRequest(postBody);
    this.clearChatTextBox();
  }

  async clearChatTextBox(){
    await delay(100);
    this.textEnteredByUserInChat = "";
  }

  public async  arrowChangeSize(){
    
    do {
      if (!this.arrowShrink){
        this.arrowExpand = false;
        this.arrowShrink = true;
      } 
      else{
        this.arrowExpand = true;
        this.arrowShrink = false;
      }
      await delay(500);
    } while (this.convReqInFlight)
    this.arrowFixedSize();

    
  }
  
  public arrowFixedSize(){
    this.arrowExpand = false;
    this.arrowShrink = false;
  }

  showHelp(){
    //this.showNextHelp(1);

    let helpID2Open = "help";
    let helpElement2Open = document.getElementById(helpID2Open);
    if (helpElement2Open!=null) helpElement2Open.style.display="block";
    this.showNextHelpContent(1);
    
  }

  showNextHelpContent(forceHelpnum:number = 0){
    
    let helpID2Close = "helpcontent"+this.helpnumShown.toString();
    let helpElement2Close = document.getElementById(helpID2Close);
    if (helpElement2Close!=null) helpElement2Close.style.display="none";

    if (forceHelpnum >0 ) this.helpnumShown = forceHelpnum;
    else this.helpnumShown +=1;

    let helpID2Open = "helpcontent"+this.helpnumShown.toString();
    let helpElement2Open = document.getElementById(helpID2Open);
    if (helpElement2Open!=null) helpElement2Open.style.display="block";

    let nextHelpNum:number = this.helpnumShown+1;
    let helpIDNext = "helpcontent"+nextHelpNum.toString();
    let helpElementNext = document.getElementById(helpIDNext);
    if (helpElementNext==null) this.helpIsLast = true;
    else this.helpIsLast = false;

  }


  closeHelp(){
    let helpID2Close = "help";
    let helpElement2Close = document.getElementById(helpID2Close);
    if (helpElement2Close!=null) helpElement2Close.style.display="none";
  }

  showExtendOnlineOptions(){
    this.sendStatRequest("showExtendOnlineOptions");
    this.openModal("ModalOnlineWatchOpt","btnModalOnlineWatchOpt"); this.isShowOnlinePreview=true;
    this.isShowOnlineByFeedbackInfo = false;
    this.isShowOnlineByPayInfo = false;
    this.isShowOnlineAproval = false;
    this.isShowOnlineInfo = false;
    this.extendOnlineSelect = "extendOnlineNone";

  }

  extendOnlineChange(selection:string){
    if (selection=="ByFeedback"){
      this.isShowOnlineByFeedbackInfo = true;
      this.isShowOnlineByPayInfo = false;
      this.isShowOnlineAproval = false;
      this.isShowOnlineInfo = false;
    }
    else if (selection=="ByPay"){
      this.isShowOnlineByFeedbackInfo = false;
      this.isShowOnlineByPayInfo = true;
      this.isShowOnlineAproval = false;
      this.isShowOnlineInfo = false;
    }
    else {
      this.isShowOnlineByFeedbackInfo = false;
      this.isShowOnlineByPayInfo = false;
      this.isShowOnlineAproval = false;
      this.isShowOnlineInfo = false;
    }
  }

  selectExtendOnlineOption(){
    this.hasOnlineErrMsg = false;
    this.isOnlineShowLoad = false;
    if (this.extendOnlineSelect=="extendOnlineByFeedback"){
      this.sendStatRequest("extendOnlineByFeedback");
      this.isShowOnlinePreview = false;
      this.isShowOnlineByPaySection = false;
      this.isShowOnlineByFeedbackSection = true;
      this.isShowOnlineAproval = false;
      this.isShowOnlineInfo = false;
    }
    if (this.extendOnlineSelect=="extendOnlineByPay"){
      this.sendStatRequest("extendOnlineByPay");
      this.isShowOnlinePreview = false;
      this.isShowOnlineByPaySection = true;
      this.isShowOnlineByFeedbackSection = false;
      this.isShowOnlineAproval = false;
      this.isShowOnlineInfo = false;
    }
    if (this.extendOnlineSelect=="extendOnlineNone"){
        this.sendStatRequest("extendOnlineNone");
        this.closeModal('ModalOnlineWatchOpt','btnModalOnlineWatchOpt');
        this.isShowOnlinePreview = true;
        this.isShowOnlineByPaySection = false;
        this.isShowOnlineByFeedbackSection = false;
        this.isShowOnlineAproval = false;
        this.isShowOnlineInfo = false;
    }
  }
  unselectExtendOnlineOption(){
      this.hasOnlineErrMsg = false;
      this.isOnlineShowLoad = false;
      this.isShowOnlinePreview = true;
      this.isShowOnlineByPaySection = false;
      this.isShowOnlineByFeedbackSection = false;
      this.isShowOnlineAproval = false;
      this.isShowOnlineInfo = false;
  }

  showNextHelp(helpnum:number = 1){

    if (helpnum>1)
    {
      let oldHelpnum:number =helpnum-1;
      let helpID2Close = "help"+oldHelpnum.toString();
      let helpElement2Close = document.getElementById(helpID2Close);
      if (helpElement2Close!=null) helpElement2Close.style.display="none";
    }
    let helpID2Open = "help"+helpnum.toString();
    let helpElement2Open = document.getElementById(helpID2Open);
    if (helpElement2Open!=null) helpElement2Open.style.display="block";
  }
  closeAnyHelp(){
    let helpnum:number=1;
    let helpID2Close = "help"+helpnum.toString();
    let helpElement2Close = document.getElementById(helpID2Close);
    while (helpElement2Close != null){
      helpElement2Close.style.display="none";
      helpnum += 1;
      helpID2Close = "help"+helpnum.toString();
      helpElement2Close = document.getElementById(helpID2Close);
    }
    
  }



  showErrMsg(){
    this.openModal("ModalErrorMsg","btnModalErrorMsg")
    /*
    let convStatModalBtn = document.getElementById("btnModalErrorMsg");
    if (document.getElementById("ModalErrorMsg").style.display!="block"){
      convStatModalBtn.click();
    }*/
  }

  toggleOptionsNextToRand(){
    this.openModal("modalPreference","btnModalPreference")
    document.getElementById("getProfileDetailsButton").click();

    if (this.showMoreOpt){
    //  this.lessOptionsNextToRand();
    }
    else
    {
      //this.showMoreOpt = true;
      //this.otherOptText = "(סגירת אפשרויות)";
    }
  }
  lessOptionsNextToRand(){
    this.showMoreOpt = false;
    this.otherOptText = "העדפות...";
  }

  openModal(modalID:string, modalBtnID:string){
    // when opening profile from conv stat -> conv stat stays below, so that we get back to same place.
    // when we send message / chat from profile -> not opening conv stat. // use the show=false somehow when it comes from profile. i.e. when profile is open.

    //todo:
    // 1. build angular and see if all ok - partially done.
    // 2. get HGR with conv stat array. when someone clicks the image or name in conv stat - profile opens.
    // 3. add city and neighborhood for all user data classes in angular and dotnet, and in profile editing, profile showing, manager approve.

    this.closeHelp();
    if (this.modalStack.indexOf(modalID,0)> -1) 
      return;
    let modalBtn = document.getElementById(modalBtnID);
    if (document.getElementById(modalID).style.display!="block"){
      modalBtn.click();
    }
    this.modalStack.push(modalID);
    document.body.style.overflow="hidden";
  }

  closeModal(modalID:string, modalBtnID:string){
    let stackIndex = this.modalStack.indexOf(modalID,0);
    if (stackIndex == -1) 
      return;

    if (modalID=="modalPerson"){
      this.convStatPersonList=[];
    }  
    let modalBtn = document.getElementById(modalBtnID);
    if (document.getElementById(modalID).style.display=="block"){
      modalBtn.click();
    }
    this.modalStack.splice(stackIndex,1);
    if (this.modalStack.length == 0 )
      document.body.style.overflow="auto";
    else{
      let IDshown = this.modalStack[this.modalStack.length-1];
      let elementShown = document.getElementById(IDshown);
      let bodyElement = document.body;
      bodyElement.classList.add("modal-open-override");
      
    }
  }

  scrollToMoreDetails(){
    let divToScrollTo = document.getElementById("what-is-it-for-id");
    let scrollToVal = divToScrollTo.offsetTop;
    //document.body.animate({opacity: 0},800);
    // document.body.animate({scrollTop: "100px"},800);  <--- not working :(   
    divToScrollTo.scrollIntoView({behavior:'smooth',block:'start'});
  }

  gotoEditProfile(){
    //this.sendStatRequest("gotoEditProfile");
    document.getElementById("gotoEditProfile").click();
  }
  gotoExplain(){
    //this.sendStatRequest("gotoExplain");
    document.getElementById("gotoExplain").click();
  }

  async countDownDec(){
    await delay(1000);
    this.countDown = this.countDown-1;
    if (this.countDown>0){
        this.randButtonTxt = "ניתן להגריל שוב בעוד " +this.countDown.toString()+ " שניות";
        this.countDownDec();
    }
    else{
      this.randButtonTxt = "הגרל שוב!";
      if (this.userGender=="female"){
        this.randButtonTxt = "הגרילי שוב!"
      }
      else{
        this.randButtonTxt = "הגרל שוב!"
      }
    }
  }

  populatePersonInfoList(dataFromServer:FormatHttpGetResponse[],personInfoList:PersonInfo[]){
    if (dataFromServer.length>0 && dataFromServer[0].hgr != null && dataFromServer[0].hgr.length > 0 )    {
      for (let i:number = 0; i< dataFromServer[0].hgr.length ; i++){
        let genRes = dataFromServer[0].hgr[i];
        let thumbI = new PersonInfo({
          parent:this,firstName:genRes.firstName,lastName:genRes.lastName, imgFile:genRes.imgFile , isAllowed:genRes.isAllowed, onlineStatus: genRes.onlineStatus, 
          neighborhood:genRes.neighborhood,city:genRes.city,
          age:genRes.age, publicId:genRes.publicId, 
          characteristics:genRes.characteristics, usertext:genRes.usertext
        })
        if (genRes.isExample== null){
          thumbI.isExample=false;
        } 
        else{
          thumbI.isExample=genRes.isExample;
        }
        if (genRes.loceventsMatch == null){
          thumbI.loceventsMatch = [];
        }
        else{
          thumbI.loceventsMatch = [];
          for (let k:number=0; k< genRes.loceventsMatch.length ; k++){
            let locevent = this.locevents.find(x=>x.loceventId==genRes.loceventsMatch[k]);
            if (locevent != null) thumbI.loceventsMatch.push(locevent);
          }
          //thumbI.loceventsMatch = genRes.loceventsMatch;
          for (let j:number=0; j<thumbI.loceventsMatch.length; j++){
            let locevent = this.locevents.find(x=>x.loceventId==thumbI.loceventsMatch[j].loceventId);
            if (locevent != null && locevent.type == "event") thumbI.isEventMatch = true;
            if (locevent != null && locevent.type == "location") thumbI.isLocMatch = true;
          }
        }
        //this.thumbInfoList.push(thumbI);
        personInfoList.push(thumbI);
        
        //if (newAvailSlotInfo.isFuture) this.availSlotList.push(newAvailSlotInfo);

      }
    }
  }

}
