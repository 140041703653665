import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'stam-ledaber';
  ver:string = 'v08012022';

  
  showNavBar(){
    document.getElementById("nav-bar-id").style.display="block";
  }

  
}



