import { Component, Input, OnInit } from '@angular/core';
import { FindFriendsComponent } from '../find-friends/find-friends.component';
import { conversationsStatus } from '../main-page/formatHttpGetResponse';

@Component({
  selector: 'app-chats-list',
  templateUrl: './chats-list.component.html',
  styleUrls: ['./chats-list.component.css']
})
export class ChatsListComponent implements OnInit {
  @Input() parent: FindFriendsComponent;
  @Input() convStatList: conversationsStatus[] = [];
  @Input() chats: ChatPreviewItem[] = [];
  @Input() chatsLoading: boolean = false; 


  ngOnInit(): void {

  }

  openMenu(chat: ChatPreviewItem, event: Event): void {
    event.stopPropagation(); // Prevents event bubbling to the parent
    let isMenuOpen = chat.isMenuOpen
    this.chats.forEach(c => c.isMenuOpen = false); 
    chat.isMenuOpen = !isMenuOpen;
    this.parent.logActivity("open menu in chat list for U"+ chat.userId, "click");
  }
  

  deleteChat(chat: ChatPreviewItem, event: Event): void {
    // Handle chat deletion
    event.stopPropagation();
    chat.isMenuOpen = false;

    let postBody = {
      "postType" : 3,
      "callerId" : 0, // to be set in parent
      "referToPerson" : chat.userId,
      "messageType" : "delete_and_clear",
      "message" : "",
    }
    this.parent.logActivity("delete conv with U"+ chat.userId, "click");
    this.parent.sendConvRequest(postBody);
  }

  seeProfile(chat: ChatPreviewItem, event: Event): void {
    // Handle chat deletion
    event.stopPropagation();
    chat.isMenuOpen = false;
    this.showProfile(chat);
  }
  
  closeMenu(chat: ChatPreviewItem, event: Event): void {
    // Handle chat reporting
    event.stopPropagation();
    chat.isMenuOpen = false;
  }


  showChatView(chat: ChatPreviewItem){
    this.parent.logActivity("show chat from chatlist for U"+chat.userId,"click");
    this.parent.selectedChat = this.parent.convStatList.find(x=>x.otherPersonPublicId == chat.userId);
    this.parent.switchViewByClick("chatView")
  }

  showProfile(chat: ChatPreviewItem){
    this.parent.selectedProfile = this.parent.chatProfiles.find(x=>x.userId == chat.userId);
    this.parent.showProfile(this.parent.selectedProfile)
  }

   // Method to return cached image URL or original URL if not cached
   getCachedImage(url: string): string {
    return this.parent.imageCache[url] && this.parent.imageCache[url].src ? this.parent.imageCache[url].src : url;
  }

  // Handle image load and cache the image
  onImageLoad(chat: ChatPreviewItem, event: Event): void {
    chat.isImgLoaded = true;
    
    // Cache the image if it's not already cached
    //const imgElement = event.target as HTMLImageElement;
    const imgElement = (event.target as HTMLImageElement).cloneNode() as HTMLImageElement; // Clone the element
    if (!this.parent.imageCache[chat.imageUrl]) {
      this.parent.imageCache[chat.imageUrl] = imgElement;
    }
  }
}


export class ChatPreviewItem {
  initiationId: string;
  userId: number;
  imageUrl: string;
  name: string;
  lastMessage: string;
  isShowRedNotify: boolean;
  isMenuOpen: boolean;
  isImgLoaded : boolean = false;
}
