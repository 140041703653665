import { Component, OnInit } from '@angular/core';
import { AvailSlotInfo,QnA } from './avail-slot-info';
import { delay } from 'q';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { FormatHttpGetResponse, MyDate} from './formatHttpGetResponse';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.css']
})
export class MainPageComponent implements OnInit {

  //constructor() { }
  constructor(private _http: HttpClient) { }
  
  //-- Response data
  apiValues: string[] = [];


  //-- available slots info
  availSlotList: AvailSlotInfo[]=[];
  interestedSlotList: AvailSlotInfo[]=[];
  selectedSlot: AvailSlotInfo = new AvailSlotInfo({
    dateBegin:new Date(2000,0,1,12,0,0),
    dateEnd:  new Date(2000,0,1,13,0,0),
    location:"",
    isRequested:false,
    interested:0,
    msgId:0,
    parent:this
  })
  capturedDataFromServer:FormatHttpGetResponse[];
  qnaList: QnA[]=[];


  //-- user inputs
  guest_name:string = null;
  guest_age:string = null;
  guest_phone:string = null;
  guest_conversation_plan:string = null;
  guest_comm_message:string = null;
  name_wrong:boolean = false;
  age_wrong:boolean = false;
  phone_wrong:boolean = false;
  showMeetingInSuccess:boolean = false;
  getInfoPassword:string = null;

  ngOnInit() {
    
    //instructionModalBtn.click();
    let ques = new QnA({question:"",answer:""});
    this.qnaList.push(ques);




    this.checkServerMsg(0);
  }

  async checkServerMsg(secDelay:number){
    var a =1;
    //send http request to see if there are messages ready from server
    
    await delay(secDelay*1000);
    this._http.get<FormatHttpGetResponse[]>('/api/ClientMsg').subscribe(
      //on readymsg
      dataFromServer => { this.onServerMsgUpdate(dataFromServer);},
      error => this.apiValues = ['error on http in checkServerMsg','error found']
      )
    
  }


  onServerInfoUpdate(dataFromServer:FormatHttpGetResponse[]){
    this.capturedDataFromServer = dataFromServer;
    for (let i:number = 0; i< dataFromServer.length ; i++){
      let db:MyDate = dataFromServer[i].dateBegin;
      let de:MyDate = dataFromServer[i].dateEnd;
      let newAvailSlotInfo = new AvailSlotInfo({
        dateBegin:new Date(db.year,db.month,db.day,db.hours,db.minutes,db.seconds),
        dateEnd:new Date(de.year,de.month,de.day,de.hours,de.minutes,de.seconds),
        location:dataFromServer[i].location,
        isRequested:dataFromServer[i].isRequested,
        interested:dataFromServer[i].interested,
        msgId:dataFromServer[i].msgId,
        parent:this
      })
      for (let j:number=0; j< dataFromServer[i].listInterested.length; j++){
        newAvailSlotInfo.listIntersted.push(dataFromServer[i].listInterested[j]);
      }
      if (newAvailSlotInfo.isFuture) this.interestedSlotList.push(newAvailSlotInfo);
    }
    //handle update from server
    //this.handleServerMsgUpdate(dataFromServer);
    //keep checking for more updates
    //this.checkServerMsg();
  }

  onServerMsgUpdate(dataFromServer:FormatHttpGetResponse[]){
    this.capturedDataFromServer = dataFromServer;
    for (let i:number = 0; i< dataFromServer.length ; i++){
      let db:MyDate = dataFromServer[i].dateBegin;
      let de:MyDate = dataFromServer[i].dateEnd;
      let newAvailSlotInfo = new AvailSlotInfo({
        dateBegin:new Date(db.year,db.month,db.day,db.hours,db.minutes,db.seconds),
        dateEnd:new Date(de.year,de.month,de.day,de.hours,de.minutes,de.seconds),
        location:dataFromServer[i].location,
        isRequested:dataFromServer[i].isRequested,
        interested:dataFromServer[i].interested,
        msgId:dataFromServer[i].msgId,
        parent:this
      })
      if (newAvailSlotInfo.isFuture) this.availSlotList.push(newAvailSlotInfo);
    }
    //handle update from server
    //this.handleServerMsgUpdate(dataFromServer);
    //keep checking for more updates
    //this.checkServerMsg();
  }
  onServerMsgReply(dataFromServer:FormatHttpGetResponse[]){
    this.capturedDataFromServer = dataFromServer;
    if (dataFromServer.length==1){
      let reqFinalized:AvailSlotInfo =  this.availSlotList.find(x=>x.msgId==dataFromServer[0].msgId)
      if (reqFinalized != null){
        reqFinalized.isRequested = true;
      }
      this.hideModal();
      this.selectedSlot = reqFinalized;
      
      this.showSuccessModal(reqFinalized.msgId)
      
    }
    else{
      
    }
  }

  onServerMsgCommReply(dataFromServer:FormatHttpGetResponse[]){
    this.capturedDataFromServer = dataFromServer;
    if (dataFromServer.length==1){

      this.hideCommunicationModal();
      
      this.showSuccessModal(0)
      
    }
    else{
      
    }
  }

  onServerMsgStatReply(dataFromServer:FormatHttpGetResponse[]){
  }

  showModal(msgId:number){
    this.selectedSlot = this.availSlotList.find(x=>x.msgId == msgId);

    let instructionModalBtn = document.getElementById("btnModalInst");
    instructionModalBtn.click();
  }
  hideModal(){
    let instructionModalBtn = document.getElementById("btnModalInst");
    instructionModalBtn.click();
  }
  showSuccessModal(msgId:number){
    if (msgId != 0)
    {
      this.showMeetingInSuccess = true;
      this.selectedSlot = this.availSlotList.find(x=>x.msgId == msgId);
    }
    else{
      this.showMeetingInSuccess = false;
    }

    let ModalBtn = document.getElementById("btnModalSucc");
    ModalBtn.click();
  }
  hideSuccessModal(){
    let ModalBtn = document.getElementById("btnModalSucc");
    ModalBtn.click();
  }

  showCommunicationModal(){
    let ModalBtn = document.getElementById("btnModalComm");
    this.sendStat("Comm_show");
    ModalBtn.click();
  }
  hideCommunicationModal(){
    let ModalBtn = document.getElementById("btnModalComm");
    ModalBtn.click();
  }
  showQnAModal(){
    let ModalBtn = document.getElementById("btnModalQnA");
    this.sendStat("QnA_show");
    ModalBtn.click();
  }
  hideQnAModal(){
    let ModalBtn = document.getElementById("btnModalQnA");
    ModalBtn.click();
  }

  requestInfo(){
    this._http.get<FormatHttpGetResponse[]>('/api/ClientMsg/'+this.getInfoPassword).subscribe(
      //on readymsg
      dataFromServer => { this.onServerInfoUpdate(dataFromServer);},
      error => this.apiValues = ['error on http in checkServerMsg','error found']
      )
  }

  sendStat(statString:string){
    let postBody = {
      "msgPostId" : 0,
      "msgPostType" : statString,
      "msgPostText" : ""
    }
    let headers = new HttpHeaders()
      .set('Content-Type','application/json');
    let postObservable:Observable<FormatHttpGetResponse[]> = this._http.post<FormatHttpGetResponse[]>('/api/ClientMsg',postBody,{headers}); 
    postObservable.subscribe(
      dataFromServer => { this.onServerMsgStatReply(dataFromServer);},
      error => this.apiValues = ['error on http in checkServerMsg','error found']
    )
  }

  sendMessage(){
    if (this.guest_name == null || this.guest_name.length<2) this.name_wrong = true; else this.name_wrong = false;
    if (this.guest_phone == null || this.guest_phone.length<5) this.phone_wrong = true; else this.phone_wrong = false;
    if (this.phone_wrong||this.name_wrong|| this.age_wrong){ return; }
    let postBody = {
      "msgPostId" : 0,
      "msgPostType" : this.guest_name + " " + this.guest_age + " " + this.guest_phone,
      "msgPostText" : this.guest_comm_message
    }
    let headers = new HttpHeaders()
      .set('Content-Type','application/json');
    let postObservable:Observable<FormatHttpGetResponse[]> = this._http.post<FormatHttpGetResponse[]>('/api/ClientMsg',postBody,{headers}); 
    postObservable.subscribe(
      dataFromServer => { this.onServerMsgCommReply(dataFromServer);},
      error => this.apiValues = ['error on http in checkServerMsg','error found']
    )
  }

  sendRequest(msgId:number){
    if (this.guest_phone == null || this.guest_phone.length<5) this.phone_wrong = true; else this.phone_wrong = false;
    if (this.guest_name == null || this.guest_name.length<2) this.name_wrong = true; else this.name_wrong = false;
    if (this.guest_age == null || this.guest_age.length<2) this.age_wrong = true; else this.age_wrong = false;
    if (this.phone_wrong||this.name_wrong|| this.age_wrong){ return; }
    let postBody = {
      "msgPostId" : msgId,
      "msgPostType" : this.guest_name + " " + this.guest_age + " " + this.guest_phone,
      "msgPostText" : this.guest_conversation_plan
    }
    
    let headers = new HttpHeaders()
      .set('Content-Type','application/json');
    let postObservable:Observable<FormatHttpGetResponse[]> = this._http.post<FormatHttpGetResponse[]>('/api/ClientMsg',postBody,{headers}); 
    postObservable.subscribe(
      dataFromServer => { this.onServerMsgReply(dataFromServer);},
      error => this.apiValues = ['error on http in checkServerMsg','error found']
    )
  }

}

      /*{
        dateBegin : Date.now(),

//        new Date(2021,2,14,11,20,0),
        //dateEnd: new Date(2021,2,14,12,20,0),
        
      }*/