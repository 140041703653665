import { Component, Input, OnInit } from '@angular/core';
import { FormField, FormSection } from '../main-page/formatHttpGetResponse';
import { FormBuilder, FormGroup, Validators, AbstractControl, ValidatorFn } from '@angular/forms';
import { TalkResponseComponent } from '../talk-response/talk-response.component';

@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.css']
})

export class DynamicFormComponent implements OnInit {
  @Input() parent: TalkResponseComponent;

  userForm: FormGroup;
    
  section1: FormSection = {
    "name" : "loginInfo",
    "title" : "פרטי התחברות",
    "isVisible" : true,
    "fields": [
      {
        "type": "email",
        "label": "אי מייל",
        "small": "זהו גם שם המשתמש שלכם לצורך התחברות ושחזור סיסמה.",
        "name": "email",
        "placeholder" : "people@israel.win",
        "validators": ["required", "email"]
      },
      {
        "type": "password",
        "label": "סיסמה",
        "name": "password",
        "validators": ["required"]
      }
    ]
  }

  section2: FormSection = {
    "name" : "personalInfo",
    "title" : "מידע אישי",
    "isVisible" : true,
    "fields": [
      {
        "type": "text",
        "label": "שם פרטי",
        "small": "בעברית בבקשה",
        "name": "privateName",
        "validators": ["required", "minLength:2"]
      },
      {
        "type": "number",
        "label": "גיל",
        "name": "age",
        "validators": ["required", "min:18"]
      },
      /*{
        "type": "text",
        "label": "מאיפה",
        "name": "wherefrom",
        "validators": ["required"]
      },*/
      {
        "type": "select",
        "label": "מגדר",
        "name": "gender",
        "options": [
          { label: "אישה", value: "female" },
          { label: "גבר", value: "male" },
          { label: "אחר/ת", value: "other" }
        ],
        "validators": ["required"]
      }
    ]
  }

  section3: FormSection = {
    "name" : "phone",
    "title" : "מספר טלפון",
    "isVisible" : true,
    "fields": [
      {
        "type": "tel",
        "label": "מספר טלפון",
        "small": "כדי שנוכל לעדכן בסמס",
        "name": "phoneNumber",
        "placeholder" : "050-1234567",
        "validators": ["required", "pattern:^0[0-9]{2}[- ]?[0-9]{7}$"]
      }
    ]
  }

  section4: FormSection = {
    "name" : "extraInfo",
    "title" : "מידע נוסף",
    "isVisible" : false,
    "fields": [
      {
        "type": "textarea",
        "label": "טקסט לצפייה כללית",
        "name": "publicText",
        "validators": []
      },
      {
        "type": "textarea",
        "label": "טקסט למנהל המערכת",
        "name": "adminText",
        "validators": []
      },
      {
        "type": "checkbox",
        "label": "האם יש לי רקע בטיפול (פסיכולוג וכדומה)",
        "name": "hasBackgroundInTreatment",
        "validators": []
      }

    ]
  }

  section5: FormSection = {
    "name" : "codeValidation",
    "title" : "אימות קוד",
    "isVisible" : false,
    "fields": [
      {
        "type": "text",
        "label": "לאימות מספר הטלפון, הזינו את הקוד שנשלח בסמס",
        "name": "verificationCode",
        "placeholder" : "------",
        "validators": ["required", "pattern:^[a-zA-Z0-9]{6}$"]
      }
    ]
  }



  allFormSections: FormSection[] = [this.section1,this.section2, this.section3, this.section4, this.section5];
  


  constructor(private fb: FormBuilder) {
    // Initialize an empty form group.
    this.userForm = this.fb.group({});
  }

  ngOnInit() {
    if (this.parent && this.parent.allFormSections) {
      this.allFormSections = this.parent.allFormSections;
    }
    else{
      this.parent.allFormSections = this.allFormSections;
    }
    if (this.parent && this.parent.userForm) {
      this.userForm = this.parent.userForm;
    } 
    else {
      this.parent.userForm = this.userForm;
    }



    // First loop: Create form groups for each section
    this.allFormSections.forEach(section => {
      this.userForm.addControl(section.name, this.fb.group({}));
    });


    // Second loop: Add form controls for each field, including custom validation if needed
    this.allFormSections.forEach(section => {
      section.fields.forEach(field => {
        const validators = this.getValidators(field.validators);
        const currentGroup = this.userForm.get(section.name) as FormGroup; // Type assertion
        currentGroup.addControl(field.name, this.fb.control('', validators));
      });
  });
  }

  handleFileChange(files: FileList, sectionName: string, fieldName: string) {
    if (files.length > 0) {
      const fileControl = this.userForm.get(`${sectionName}.${fieldName}`); // Adjust this to match your section
      fileControl.setValue(files[0]); // Set the file object to the form control
      fileControl.updateValueAndValidity(); // Trigger validation
      this.parent.uploadFile(files); // Call the parent’s upload method
    }
  }



  private getValidators(validatorNames: string[]): ValidatorFn[] {
    const validators:ValidatorFn[] = [];

    if (validatorNames) {
      validatorNames.forEach(validatorName => {
        if (validatorName === 'required') {
          validators.push(Validators.required);
        } else if (validatorName.startsWith('minLength:')) {
          const minLength = +validatorName.split(':')[1];
          validators.push(Validators.minLength(minLength));
        } else if (validatorName.startsWith('min:')) {
          const min = +validatorName.split(':')[1];
          validators.push(Validators.min(min));
        } else if (validatorName.startsWith('pattern:')) {
          const pattern = validatorName.split(':')[1];
          validators.push(Validators.pattern(pattern));
        } else if (validatorName === 'email') {
          validators.push(Validators.email);
        } else if (validatorName === 'forceCheckTrue') {
          validators.push(this.forceCheckTrueValidator());
        }
      });
    }
    return validators;
  }

  private forceCheckTrueValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        return control.value === true ? null : { 'forceCheckTrue': { value: control.value } };
    };
  }

  request_initcode(){
    this.parent.request_initcode();
  }

}
