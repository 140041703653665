import { Component, OnInit, Input } from '@angular/core';
import { exit } from 'process';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  @Input() navBarPage : string = "";
  @Input() navBarLoggedIn : string = "undefined";
  @Input() navBarUserMessage : string ="";
  constructor() { }
  ver:string = 'v08012022';
  isVertMenuShown:boolean = false;
  ngOnInit() {

  }
  showNavBar(){
    document.getElementById("nav-bar-id").style.display="block";
  }
  showVerticalMenu(){
    if (this.isVertMenuShown) return;
    let divToExtend = document.getElementById("vert-menu-id");
    //let divToExtendHeightNum:number = divToExtend.clientHeight;
    //let addHeight:number = 100;
    //let newheight = (divToExtendHeightNum + addHeight).toString() + "px";
    //divToExtend.style.height = newheight;
    if (this.navBarLoggedIn=='yes') {
      divToExtend.style.height = "230px";
    }
    else {
      divToExtend.style.height = "180px";
    }
    this.isVertMenuShown = true;
    document.getElementById("open-menu").style.opacity = "0";
    document.getElementById("close-menu").style.opacity = "1";
    //document.getElementById("close-menu").style.display = "block";
    document.getElementById("open-menu").style.zIndex = "-1";
    document.getElementById("close-menu").style.zIndex = "0";
  }
  closeVerticalMenu(){
    if (!this.isVertMenuShown) return;
    let divToExtend = document.getElementById("vert-menu-id");
    //let divToExtendHeightNum:number = divToExtend.clientHeight;
    //let addHeight:number = -100;
    //let newheight = (divToExtendHeightNum + addHeight).toString() + "px";
    //divToExtend.style.height = newheight;
    divToExtend.style.height = "0px";
    this.isVertMenuShown = false;
    document.getElementById("open-menu").style.opacity = "1";
    document.getElementById("close-menu").style.opacity = "0";
    //document.getElementById("close-menu").style.display = "none";
    document.getElementById("open-menu").style.zIndex = "0";
    document.getElementById("close-menu").style.zIndex = "-1";
  }


}
