import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms'; // <-- NgModel lives here
import { HttpClientModule } from '@angular/common/http'; // note: name of module changes throughout angular verions
import { RouterModule, Routes} from '@angular/router';

import { AppComponent } from './app.component';
import { MainPageComponent } from './main-page/main-page.component';
import { AvailSlotComponent } from './main-page/avail-slot/avail-slot.component';
import { ViewPeopleComponent } from './view-people/view-people.component';
import { ThumbCardComponent } from './thumb-card/thumb-card.component';
import { ConvStatComponent } from './conv-stat/conv-stat.component';
import { EditProfileComponent } from './edit-profile/edit-profile.component';
import { InfoComponent } from './info/info.component';
import { LogInComponent } from './log-in/log-in.component';
import { ManageComponent } from './manage/manage.component';
import { PreferenceComponent } from './preference/preference.component';
import { NavbarComponent } from './navbar/navbar.component';
import { TalkRequestComponent } from './talk-request/talk-request.component';
import { TalkResponseComponent } from './talk-response/talk-response.component';
import { AvailabilitySlotsSelectionComponent } from './talk-response/availability-slots-selection/availability-slots-selection.component';
import { DynamicFormComponent } from './dynamic-form/dynamic-form.component';
import { ReactiveFormsModule } from '@angular/forms';
import { BottomBarComponent } from './bottom-bar/bottom-bar.component';
import { WelcomePageComponent } from './welcome-page/welcome-page.component';
import { FindFriendsComponent } from './find-friends/find-friends.component';
import { ProfileViewComponent } from './profile-view/profile-view.component';
import { TheSixComponent } from './the-six/the-six.component';
import { ChatsListComponent } from './chats-list/chats-list.component';
import { ChatViewComponent } from './chat-view/chat-view.component';
import { ProfilesLikesComponent } from './profiles-likes/profiles-likes.component';

const routes: Routes = [
  
  {path: 'welcomepage', component: WelcomePageComponent},
  {path: 'mainpage', component: MainPageComponent},
  {path: 'viewpeople', component: ViewPeopleComponent},
  {path: 'findfriends', component: FindFriendsComponent},
  {path: 'findfriends/:finderType', component: FindFriendsComponent},
  //{path: 'talkrequest', component: TalkRequestComponent},
  //{path: 'talkresponse', component: TalkResponseComponent},
  {path: 'talkrequest', component: ViewPeopleComponent},
  {path: 'talkresponse', component: ViewPeopleComponent},
  {path: 'editprofile', component: EditProfileComponent},
  {path: 'login', component: LogInComponent},
  {path: 'login/:pageTypeID', component: LogInComponent},
  {path: 'info', component: InfoComponent},
  {path: 'info/:infoID', component: InfoComponent},
  {path: 'manage', component: ManageComponent},
  {path: '', redirectTo:'/welcomepage', pathMatch:'full'}
]

@NgModule({
  declarations: [
    AppComponent,
    MainPageComponent,
    AvailSlotComponent,
    ViewPeopleComponent,
    ThumbCardComponent,
    ConvStatComponent,
    EditProfileComponent,
    InfoComponent,
    LogInComponent,
    ManageComponent,
    PreferenceComponent,
    NavbarComponent,
    TalkRequestComponent,
    TalkResponseComponent,
    AvailabilitySlotsSelectionComponent,
    DynamicFormComponent,
    BottomBarComponent,
    WelcomePageComponent,
    FindFriendsComponent,
    ProfileViewComponent,
    TheSixComponent,
    ChatsListComponent,
    ChatViewComponent,
    ProfilesLikesComponent
  ],
  imports: [
    BrowserModule, RouterModule.forRoot(routes),
        //in docs it says to have HttpClient after browser module
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule // Add this line
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
