import { Component, Input, OnInit } from '@angular/core';
import { FindFriendsComponent } from '../find-friends/find-friends.component';
import { Profile } from '../the-six/the-six.component';
import { InterestTags, RelationshipRecord, RelationshipStatus, conversationsStatus } from '../main-page/formatHttpGetResponse';

@Component({
  selector: 'app-profile-view',
  templateUrl: './profile-view.component.html',
  styleUrls: ['./profile-view.component.css']
})
export class ProfileViewComponent implements OnInit {
  RelationshipStatus = RelationshipStatus ; // Bind enum to class so the template can use it
  
  @Input() parent: FindFriendsComponent;
 // @Input() userProfile: Profile;  // Profile data from parent
  @Input()
  set userProfile(value: Profile) {
    this._userProfile = value;
    this.updateProfileData(); // Trigger update only when userProfile changes
  }
  get userProfile(): Profile {
    return this._userProfile;
  }
  private _userProfile: Profile;

  @Input() uiMode:string; // tells the UI if the user is newCommer, or other

  // Sample values for bounded params
  userProfileImage: string;
  userName: string;
  userAge: number;
  userCity: string;
  userNeighborhood: string;
  userLikes: number;
  trustRate: number;
  onlineStatus: string;
  userFreeText: string;

  isModalVisible:boolean = false;
  modalMode:number = 1;
  modalMode_1_counter:number = 0;
  isProfileUpdateRequried:boolean = false;
  

  private logList: { key: string, value: string }[] = [];



  constructor() { }

  ngOnInit() {
    this.userProfileImage = 'https://randomuser.me/api/portraits/women/2.jpg'; // Replace with actual image URL
    this.userName = 'מיכל';
    this.userAge = 39;
    this.userCity = 'תל אביב';
    this.userNeighborhood = 'פלורנטין';
    this.userLikes = 120;
    this.trustRate = 95; // Trust rate percentage
    this.onlineStatus = 'אונליין השבוע'; // Status text
    this.userFreeText = 'אני אוהבת חיות וטיולים :)'; // Free user text block


    this.updateProfileData();
  }


  updateProfileData() {
    if (this.userProfile) {
      this.userProfileImage = this.userProfile.imageUrl;
      this.userName = this.userProfile.name;
      this.userAge = this.userProfile.age;
      this.userCity = this.userProfile.city;
      this.userNeighborhood = this.userProfile.neighborhood;
      this.userLikes = this.userProfile.likes;
      this.trustRate = this.userProfile.trustRate;
      this.onlineStatus = this.userProfile.onlineStatus != "" ? 'אונליין' : 'אופליין';
      this.userFreeText = this.userProfile.usertext || '(לא נכתב תיאור... מוזמנים לבקש כזה בהודעות!)';

      if (this.modalMode == 2){ this.isModalVisible = false; }
      this.isProfileUpdateRequried = false;

    }
  }

  showChatView(){
    let existingChat: conversationsStatus | undefined = this.parent.convStatList.find(x=>x.otherPersonPublicId == this.userProfile.userId);
    if (existingChat != null){
      this.parent.selectedChat = existingChat;
      this.parent.switchViewByClick("chatView");
    }
    else {
      if (this.parent.sessionData != null){
        let newConversation = conversationsStatus.createFromSessionAndProfile(this.parent.sessionData, this.userProfile);
        this.parent.convStatList.push(newConversation);
        this.parent.selectedChat = newConversation;
        this.parent.switchViewByClick("chatView");
      }
      else{
        this.parent.showError("לא ניתן לפנות למשתמש זה כרגע");
      }
    }
  }


  handleRelationshipClick(type: 'like' | 'skip') {
    if (this.userProfile) {
      if (!this.userProfile.relRecord) {
        // Create a new RelationshipRecord with minimal parameters if it doesn't exist
        this.userProfile.relRecord = new RelationshipRecord({
          linkedUserId: this.userProfile.userId,
          dateLinked: new Date(),
          dateUpdated: new Date(),
          interestTags: new InterestTags(),
          relationshipStatusTags: []
        });
      }
      if (!this.userProfile.relRecord.interestTags){
        this.userProfile.relRecord.interestTags = new InterestTags();
      }
  
      if (type === 'like') {
        this.userProfile.relRecord.interestTags.coffeeAgree = true;
        this.userProfile.relRecord.interestTags.agreeToNone = false;
      } else if (type === 'skip') {
        this.userProfile.relRecord.interestTags.agreeToNone = true;
        this.userProfile.relRecord.interestTags.coffeeAgree = false;
      }
  
      this.userProfile.relRecord.dateUpdated = new Date();
      this.parent.updateRelationshipRecord(this.userProfile.relRecord);

      

      
      if (this.uiMode=="newCommer"){
        this.parent.getNextProfile(this);
        this.isProfileUpdateRequried = true;
        setTimeout(() => {
            if (this.modalMode_1_counter<2){
              this.modalMode_1_counter += 1;
              this.isModalVisible = true;
              this.modalMode = 1;
            }
          
          
        }, 0); // Adjust delay to match the animation duration
        
      }
      else if (this.uiMode=="startedLiking"){
        this.parent.getNextProfile(this);
        this.isProfileUpdateRequried = true;
        setTimeout(() => {
          if (this.isProfileUpdateRequried){
            this.isModalVisible = true;
            this.modalMode = 2;
          }
          
        }, 1000);
        
      }
      else {
        setTimeout(() => {
          this.parent.switchView("theSix");
        }, 1000);
      }


    }

  }


    // Method to return cached image URL or original URL if not cached
    getCachedImage(url: string): string {

      const cachedValue = this.parent.imageCache[url] && this.parent.imageCache[url].src ? this.parent.imageCache[url].src : 'Not Cached';
      if (!this.logList.find(log => log.key === url && log.value === cachedValue)) {
        this.logList.push({ key: url, value: cachedValue });
        //console.log('view - User Name:', this._userProfile.name);
        //console.log('view - Key:', url);
        //console.log('view - Value:', cachedValue);
        //this.parent.printAllCachedImages();
      }
      
      return this.parent.imageCache[url] && this.parent.imageCache[url].src ? this.parent.imageCache[url].src : url;
      
    }
  
    // Handle image load and cache the image
    onImageLoad(profile: Profile, event: Event): void {
      profile.isImgLoaded = true;
      
      // Cache the image if it's not already cached
      //const imgElement = event.target as HTMLImageElement;
      const imgElement = (event.target as HTMLImageElement).cloneNode() as HTMLImageElement; // Clone the element


      if (!this.parent.imageCache[profile.imageUrl]) {

        if (!this.logList.find(log => log.key === "view - Adding to Cache - Key:" + profile.imageUrl && log.value === 'view - Adding to Cache - Value:' + imgElement.src)) {
          this.logList.push({ key: "view - Adding to Cache - Key:" + profile.imageUrl, value: 'view - Adding to Cache - Value:' + imgElement.src });
          //console.log('view - Adding to Cache - Key:', profile.imageUrl);
          //console.log('view - Adding to Cache - Value:', imgElement.src);
          //this.parent.printAllCachedImages();
        }

        this.parent.imageCache[profile.imageUrl] = imgElement;

        if (!this.logList.find(log => log.key === "view - Added to Cache - Key:" + profile.imageUrl && log.value === 'view - Added to Cache - Value:' + imgElement.src)) {
          this.logList.push({ key: "view - Added to Cache - Key:" + profile.imageUrl, value: 'view - Added to Cache - Value:' + imgElement.src });
          //console.log('view - Added to Cache - Key:', profile.imageUrl);
          //console.log('view - Added to Cache - Value:', imgElement.src);
          //this.parent.printAllCachedImages();
        }
      }
    }

  
  closeModal() {
    //this.isModalVisible = false;
    if (this.isProfileUpdateRequried)
    {
      this.modalMode=2;
    }
    else {
      this.isModalVisible = false;
    }
    
  }

}
