import { delay } from "q";
import {MainPageComponent} from './main-page.component';
import { Time } from '@angular/common';

export class AvailSlotInfo {
    parent: MainPageComponent;
    msgId: number;
    dayInWeek: string;       //Sun-Sat
    dateBegin: Date;         //date and time for meeting start
    dateEnd: Date;           //date and time for meeting stop
    location: string;        //rough location

    daysLeft: number;
    hoursLeft : number;
    minutesLeft: number;
    timeLeft: string;

    isRequested: boolean;    //was this slot already requested by user
    interested: number;      //amount of people interested
    listIntersted: string[];

    isFuture: boolean;


    constructor({dateBegin,dateEnd,location,isRequested,interested,msgId,parent}){
        this.dateBegin=dateBegin;
        this.dateEnd=dateEnd;
        this.location=location;
        this.isRequested = isRequested;
        this.interested = interested;
        this.msgId = msgId;
        this.parent = parent;
        this.updateCalculatedVars();
      }

      updateCalculatedVars(){
        
        var now = new Date();
        var timeLeftN:number = this.dateBegin.valueOf()-Date.now().valueOf();
        var timeEndLeftN:number = this.dateEnd.valueOf()-Date.now().valueOf();

        var dateBeginDayOnly:Date= new Date(this.dateBegin.valueOf());
        dateBeginDayOnly.setUTCHours(8);dateBeginDayOnly.setUTCMinutes(0);
        var nowDayOnly:Date = new Date();
        nowDayOnly.setUTCHours(7); nowDayOnly.setUTCMinutes(0);
        var timeLeftInDaysN = dateBeginDayOnly.valueOf()-nowDayOnly.valueOf();
        var timeLeftInDaysD:Date = new Date(timeLeftInDaysN)




        if (timeLeftN > 0){
            this.isFuture = true;
            var timeLeftD:Date = new Date(timeLeftN);
            if (timeLeftInDaysD.getUTCDate()==1){ //means actually 0 days left, since when timeLeftN=0 it's 01/Jan/1970
                this.hoursLeft = timeLeftD.getUTCHours();
                this.minutesLeft = timeLeftD.getUTCMinutes();
                if (this.hoursLeft == 0){
                    this.timeLeft="עוד " + this.minutesLeft.toString() + " דקות";
                }
                if (this.hoursLeft == 1){
                    this.timeLeft="עוד שעה ו- " + this.minutesLeft.toString() + " דקות";
                }
                if (this.hoursLeft == 2){
                    this.timeLeft="עוד שעתיים ו- " + this.minutesLeft.toString() + " דקות";
                }
                if (this.hoursLeft >= 3){
                    this.timeLeft="עוד " + this.hoursLeft.toString() + " שעות ו- " + this.minutesLeft.toString() + " דקות";
                }
            }
            else{
                this.daysLeft = timeLeftInDaysD.getUTCDate()-1; //subtract one since when timeLeftN=0 it's 01/Jan/1970
                if (this.daysLeft == 1){
                    this.timeLeft="מחר...";
                }
                if (this.daysLeft == 2){
                    this.timeLeft="עוד יומיים";
                }
                if (this.daysLeft >= 3){
                    this.timeLeft="עוד " + this.daysLeft.toString() + " ימים";
                }
            }
        }
        else{
            if (timeEndLeftN > 0){
                this.isFuture = true;
                this.timeLeft="ממש עכשיו... פנוי!";
            }
            else {
                this.isFuture = false;
            }
        }

        switch(this.dateBegin.getDay()){
            case 0:{
                this.dayInWeek = "ראשון";
                break;
            }
            case 1:{
                this.dayInWeek = "שני";
                break;
            }
            case 2:{
                this.dayInWeek = "שלישי";
                break;
            }
            case 3:{
                this.dayInWeek = "רביעי";
                break;
            }
            case 4:{
                this.dayInWeek = "חמישי";
                break;
            }
            case 5:{
                this.dayInWeek = "שישי";
                break;
            }
            case 6:{
                this.dayInWeek = "שבת";
                break;
            }
        }

      }
}

export class QnA {
    question: string;
    answer: string;

    constructor({question,answer}){
        this.question=question;
        this.answer=answer;
      }
}