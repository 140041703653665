import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';

import { ActivatedRoute, Router} from '@angular/router';
import { Profile, TheSixComponent } from '../the-six/the-six.component';
import { ProfileViewComponent } from '../profile-view/profile-view.component';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { FormatHttpGetResponse, HttpGeneralResponse, MNG_logActivity, MsgLog, RelationshipRecord, SigViewedReport, conversationsStatus } from '../main-page/formatHttpGetResponse';
import { PersonInfo } from '../person-info';
import { ServerAccessService } from '../services/server-access.service';
import { CookieService, SessionData } from '../services/cookie.service';
import { ChatViewComponent } from '../chat-view/chat-view.component';
import { ChatPreviewItem } from '../chats-list/chats-list.component';
import { delay } from 'q';

@Component({
  selector: 'app-find-friends',
  templateUrl: './find-friends.component.html',
  styleUrls: ['./find-friends.component.css']
})
export class FindFriendsComponent implements OnInit {
  //Page type selection
  finderType:string ="rand_pick";

  //session
  sessionData: SessionData | undefined;

  //view control
  viewStack: ViewStackItem[] = [];
  isShowEditProfile:boolean = false;
  isShowProfile:boolean = false;
  isShowTheSix:boolean = false;
  isShowProfilesLikes:boolean = false;
  isShowChatsList:boolean = false;
  isShowChatView:boolean = false;
  isModalVisible:boolean = false;
  isShowRedNotify:boolean = false;
  isChatLoading:boolean = false;
  isLikesLoading:boolean = false;
  navBarUserMessage:string = "";

  modalMode:number = 1;
  navBarLoggedIn:string = 'no';
  bottomBarLoggedIn:boolean = false;

  //view text
  modal1_content:string;
  
  //children
  public childChatViewComponent:ChatViewComponent;

  //data based
  selectedProfile: Profile;
  selectedChat: conversationsStatus;
  sixProfiles:Profile[] = [];
  chatProfiles:Profile[] = [];
  likesProfiles:Profile[] = [];
  imageCache: { [url: string]: HTMLImageElement } = {}; 
  convStatList: conversationsStatus[]=[]; 
  chatsForList: ChatPreviewItem[] = [];
  svr_list:SigViewedReport[] = [];
  convStatAllSigAware:string = ""; //state management
  activityLog: MNG_logActivity[] = [];



  userGender:string = "male"; //delete me
  uiMode:string="newCommer";


  //server requests control
  convReqInFlight:boolean = false;
  

  constructor(private route: ActivatedRoute,  private router: Router, private cookieService: CookieService, private location: Location, private _http: HttpClient,  private serverAccessService: ServerAccessService) { }

  ngOnInit() {
    this.sessionData = this.cookieService.getSessionData();

    if (this.sessionData.userType=="registered") {
      this.navBarLoggedIn = 'yes';
      this.navBarUserMessage = 'הי ' + this.sessionData.userFirstName + '!';
      this.bottomBarLoggedIn = true;
    }

    this.finderType = this.route.snapshot.paramMap.get('finderType');

    if (this.sessionData.userType!="registered"){
      this.switchView('editProfile');
    }
    else if (!this.finderType){
      this.switchView('chatsList');
    }
    else if (this.finderType=="editprofile"){
      this.switchView('editProfile');
    }
    else if (this.finderType=="rand_pick"){
      this.switchView('theSix');
    }
    else if (this.finderType=="chats_list"){
      this.switchView('chatsList');
    }

    
    if (this.sessionData.userType=="registered"){
      this.getConvStatus();
      this.serverReq_updateLikes();
    }

  }

  showProfile(profile:Profile){
    this.logActivity("showProfile U"+ profile.userId, "click");
    this.switchView('profile', { profile: profile });
    //this.selectedProfile = profile;
    //this.isShowTheSix = false;
    //this.isShowProfile = true;
  }

  switchViewByClick(viewName:string){
    //separate function to indicate that switch view was done by user, for separate logging purposes
    this.switchView(viewName);
    this.logActivity("switchView to "+viewName,"click");
  }

  // Switch to a view with optional inputs
  switchView(viewName: string, inputs: { [key: string]: any } = {}, addToStack = true) {
    this.logActivity("switchView to "+viewName,"auto");
    this.sessionData = this.cookieService.getSessionData();
    if (this.sessionData.userType!="registered"){
      viewName = 'editProfile';
    }
    if (addToStack) {
      // Only push the view to the stack when it's a forward navigation
      this.viewStack.push(new ViewStackItem(viewName, inputs));
      // Only push a new history state if the stack has more than 1 item
      if (this.viewStack.length > 1) {
        this.location.go(this.router.url);  // Pushes a new history state without reloading the page
      }
    }
    this.resetViews();
    
    switch (viewName) {
      case 'editProfile':
        this.isShowEditProfile = true;
        break;
      case 'profile':
        this.selectedProfile = inputs.profile;
        this.isShowProfile = true;
        break;
      case 'theSix':
        this.isShowTheSix = true;
        break;
      case 'profilesLikes':
        this.isShowProfilesLikes = true;
        break;
      case 'chatsList':
        this.isShowChatsList = true;
        break;
      case 'chatView':
        this.isShowChatView = true;
        break;
    }

    this.location.replaceState('/findfriends');
  }

  private resetViews() {
    this.isShowEditProfile = false;
    this.isShowProfile = false;
    this.isShowTheSix = false;
    this.isShowProfilesLikes = false;
    this.isShowChatsList = false;
    this.isShowChatView = false;
  }

  // Handle back navigation
  goBack(event?: any) {
    if (this.viewStack.length > 1) {
      // Use the stack for internal navigation
      this.viewStack.pop();
      const lastView = this.viewStack[this.viewStack.length - 1];
      this.logActivity("goBack","click");
      this.switchView(lastView.viewName, lastView.inputs, false); // Do not add to stack

    } else {
      // No need to explicitly call this.location.back(); the browser will handle this.
      // Simply let the browser do its default behavior when the stack is empty.
    }
  }

  logActivity(activity: string, act_type: string="") {
    let newLog:MNG_logActivity = new MNG_logActivity();
    newLog.id = this.generateUniqueId();
    newLog.time = new Date();
    newLog.activity = activity;
    newLog.act_type = act_type;
    this.activityLog.push(newLog);
  }
  
  generateUniqueId(): string {
    return Math.random().toString(36).substring(2) + Date.now().toString(36);
  }

  // Override the real back button of the phone
  @HostListener('window:popstate', ['$event'])
  onPopState(event: any) {
    this.goBack(event);  // Simply call goBack and pass the event
  }

  showError(content:string){
    this.modal1_content = content;
    this.modalMode=1;
    this.isModalVisible = true;
    this.logActivity("error shown:" + content, "error");
  }

  closeModal() {
    this.isModalVisible = false;
  }

  async periodicCountDownDec(){
    
    if (this.sessionData && this.sessionData.userType=="registered")
    { 
      await delay(1000);
      if (document.visibilityState === "visible"){
        this.sendPeriodic(this.convStatAllSigAware);
        let nowDate:Date = new Date();
      }

      if (!this.isShowChatView){
        await delay(4000);
      }

      this.periodicCountDownDec();
    }
  }

  /////////// SERVER ACCESS AND REPLY
  getConvStatus(show:boolean=false){
    const postBody = {
      postType: 4,
      message: show ? "show" : "dont show",
      msgPostText: "v20241211a"
    };

    this.isChatLoading = true;
  
    this.serverAccessService.callServer(
      postBody,
      'convStatus', // inflight key
      (dataFromServer) => this.onServerReply_GetConvStatus(dataFromServer), // callback
      '/api/ClientMsg',  // API endpoint
      0,
      () => this.showError("תקלה בשרת.. לא קיבלנו נתונים. נסו שוב"), // callback
      (errorMessage) => {
        if (errorMessage=="refresh"){
          window.location.reload();
        }
        else{
          this.showError(errorMessage)
        }
      }, // callback
    );
  }

  
  async sendConvRequest(postBody, msglogItem: MsgLog = new MsgLog()) {
    let highlightFirst: boolean = postBody.messageType !== "clear_del";

    this.serverAccessService.callServer(
      postBody,
      'sendConvRequest',  // Inflight key
      (dataFromServer) => this.onServerReply_GetConvStatus(dataFromServer, msglogItem, highlightFirst), // callback
      '/api/ClientMsg',  // API endpoint
      0,
      () => { // Handle empty response
        if (msglogItem.msgID != "") {
            msglogItem.msgStatus = "error_send";
        }
        else {
          this.showError("תקלה בשרת.. לא קיבלנו נתונים. נסו שוב");
        }
        return;
      },
      (errorMessage) => this.showError(errorMessage), // callback
    );
  }

  async onServerReply_GetConvStatus(dataFromServer:FormatHttpGetResponse[],msglogItem:MsgLog = new MsgLog(), highlightFirst:boolean=false){
    this.convReqInFlight = false;
    this.isChatLoading = false;
    if (dataFromServer== null || dataFromServer.length==0) {
      if (msglogItem.msgID != ""){
        msglogItem.msgStatus = "error_send";
        return;
      }
    }

    
    if (dataFromServer.length>0 && dataFromServer[0].convStatArr != null  )    { 
      let oldConvStatList:conversationsStatus[] = this.convStatList;
      this.convStatList = [];
      for (let i:number = 0; i< dataFromServer[0].convStatArr.length ; i++){
        let convStat = dataFromServer[0].convStatArr[i];
        let convI = new conversationsStatus({
          parent:this,
          initiationID:convStat.initiationID,timeOfInitation:convStat.timeOfInitation,
          otherPersonPublicId:convStat.otherPersonPublicId,otherPersonFirstName:convStat.otherPersonFirstName,
          userGender:"male",
          otherPersonGender:convStat.otherPersonGender, otherPersonOnlineStatus:convStat.otherPersonOnlineStatus,
          otherPersonPic:convStat.otherPersonPic,isMyInitiation:convStat.isMyInitiation,
          isMyTurn:convStat.isMyTurn,lastMessageType:convStat.lastMessageType,
          lastMessage:convStat.lastMessage,timeLastMessage:convStat.timeLastMessage,
        })
        convI.msgLogList = convStat.msgLogList;
        convI.isWaitingApproval = convStat.isWaitingApproval;


        this.convStatList.push(convI);

        if (this.selectedChat != null && this.selectedChat.initiationID==convI.initiationID){
          if (convI.msgLogList.find(x=>x.msgID==msglogItem.msgID)){
            msglogItem.msgStatus="sent_approved";
            this.prepareAllMsg();
          }
        }

      }
      
      this.convStatList.sort((a,b)=>(a.timeLastMessage < b.timeLastMessage)?1:-1);
      if (highlightFirst && this.convStatList.length>0) this.convStatList[0].highlight = "yellow";



      // Use the convStatList to populate chats array
      this.chatsForList = this.convStatList.map(conv => ({
        initiationId: conv.initiationID,
        userId: conv.otherPersonPublicId,
        imageUrl: conv.otherPersonPic,
        name: conv.otherPersonFirstName,
        lastMessage: conv.lastMessage,
        isShowRedNotify: conv.isShowRedNotify,
        isMenuOpen: false
    }) as ChatPreviewItem);

      


      let personInfoList: PersonInfo[]=[]; 

      this.populatePersonInfoList(dataFromServer,personInfoList);
      this.chatProfiles=[];
      for (let i:number=0; i<personInfoList.length;i++){
        this.chatProfiles.push({ 
          userId: personInfoList[i].publicId,
          imageUrl: personInfoList[i].imgPath, 
          name: personInfoList[i].firstName, 
          age: personInfoList[i].age,
          usertext:personInfoList[i].usertext,
          isAllowed: personInfoList[i].isAllowed,
          isImgLoaded: false,
          neighborhood:personInfoList[i].neighborhood,
          city:personInfoList[i].city,
          likes:17,
          trustRate:80,
          onlineStatus:personInfoList[i].onlineStatus,
          relRecord:personInfoList[i].relRecord
        })
      }

    }
    this.periodicCountDownDec();
  }

  async sendPeriodic(message: string = "") {
    // Define the post body with the required parameters
    const postBody = {
        postType: 201,
        message: message,
        sigViewedReports: this.svr_list,
        logActivities: this.activityLog
    };

    // Call the server using serverAccessService
    this.serverAccessService.callServer(
        postBody,
        'periodicInFlight', // inflight key to prevent concurrent requests
        (dataFromServer) => this.onServerReply_GetPeriodic(dataFromServer), // callback
        '/api/ClientMsg',  // API endpoint
        0,
        () => {}, // in periodic callback is empty
        (errorMessage) => {}, // in periodic callback is empty
    );

    // Clear svr_list as we're not waiting for acknowledgment
    this.svr_list = [];
}


  async onServerReply_GetPeriodic(dataFromServer:FormatHttpGetResponse[]){

    if (dataFromServer== null || dataFromServer.length==0) {
      return;
    }
    if (!dataFromServer[0].actRes.success){
      // dont show error message in periodic
      return;
    }


    let convStatIdsWithRedFlag:string[] = [];

    if (dataFromServer[0].acknowledgedLogIds != null && dataFromServer[0].acknowledgedLogIds.length > 0){
      this.activityLog = this.activityLog.filter(
        log => !dataFromServer[0].acknowledgedLogIds.includes(log.id)
      );
    }

    if (dataFromServer[0].sigUpdates != null && dataFromServer[0].sigUpdates.length > 0){

      for (let i:number = 0; i< dataFromServer[0].sigUpdates.length ; i++){
        if (dataFromServer[0].sigUpdates[i].convId=="all"){
          if (dataFromServer[0].sigUpdates[i].new_red_sig != null){
            this.convStatAllSigAware = dataFromServer[0].sigUpdates[i].new_red_sig;
            this.isShowRedNotify = true;
          }
          else{
            this.isShowRedNotify = false;
          }
        }
        else{
          // update the conv-stat
          if (dataFromServer[0].sigUpdates[i].new_red_sig != null){
            let csIndex:number =  this.convStatList.findIndex(x=>x.initiationID==dataFromServer[0].sigUpdates[i].convId);
            let cs:conversationsStatus =  this.convStatList[csIndex];
            cs.convStatAllSigAware = dataFromServer[0].sigUpdates[i].new_red_sig;
            
            if (this.isShowChatView && this.selectedChat.initiationID==dataFromServer[0].sigUpdates[i].convId){
              this.selectedChat.convStatAllSigAware = dataFromServer[0].sigUpdates[i].new_red_sig;
            }
            
            cs.isShowRedNotify = true;
            convStatIdsWithRedFlag.push(dataFromServer[0].sigUpdates[i].convId);

            
            
            
            let refreshedCs:conversationsStatus = cs;
            this.convStatList.splice(csIndex,1,refreshedCs); //replace the item with itself, hopting to get the updated conv_stat by that...
            let currentMsgsOld:number = refreshedCs.msgLogList.length;
            let currentMsgs:number = refreshedCs.msgLogList.filter(x=>x.fromID==refreshedCs.otherPersonPublicId).length;
            let origCurrentMsgs:number = currentMsgs;
            let convStatObjFromServer:conversationsStatus = dataFromServer[0].sigUpdates[i].convstat_obj;
            let convStatObjFromServerReceivedMsgs:MsgLog[] = convStatObjFromServer.msgLogList.filter(x=>x.fromID==refreshedCs.otherPersonPublicId)
            let amountOfMsgsInSigUpdateOld:number = convStatObjFromServer.msgLogList.length;
            let amountOfMsgsInSigUpdate:number = convStatObjFromServerReceivedMsgs.length;
            if (this.isShowChatView && this.selectedChat.initiationID==dataFromServer[0].sigUpdates[i].convId){
                


              for (let j=currentMsgs;  currentMsgs < amountOfMsgsInSigUpdate; j++){
              
                refreshedCs.msgLogList.push(convStatObjFromServerReceivedMsgs[j]);
                //if the current user sent message, the server will regurn all conv stats and will refresh the cslist, therefore convInActiveChat is now different entity
                if (refreshedCs!=this.selectedChat){
                  this.selectedChat.msgLogList.push(convStatObjFromServerReceivedMsgs[j]); 
                }
                currentMsgs = refreshedCs.msgLogList.length;
              }

              this.prepareAllMsg();
              

              this.childChatViewComponent.scrollToLastInChat();
              this.pushSigViewed(this.selectedChat.initiationID,this.selectedChat.convStatAllSigAware,this.selectedChat.msgLogList.filter(x=>x.fromID==this.selectedChat.otherPersonPublicId).length);

            }
            else{
              //this is updating:
              // 1. the chat-preview list 
              // 2. the msgloglist (appending new messages only)

              let chatPreview =  this.chatsForList.find(x=>x.initiationId==dataFromServer[0].sigUpdates[i].convId)

              

              if (chatPreview && currentMsgs < amountOfMsgsInSigUpdate){

                chatPreview.lastMessage = convStatObjFromServer.lastMessage;
                chatPreview.isShowRedNotify = convStatObjFromServer.isShowRedNotify;
                for (let j=currentMsgs;  currentMsgs < amountOfMsgsInSigUpdate; j++){
                  
                  refreshedCs.msgLogList.push(convStatObjFromServerReceivedMsgs[j]);
                  currentMsgs = refreshedCs.msgLogList.length;
                }
              }
              else{
                //if we reached here we have a potential red flag but without new data, due to server not aligend on news. so - update the signature.
                //this.pushSigViewed(componentToUpdate.convStatInp.initiationID,componentToUpdate.convStatInp.convStatAllSigAware,componentToUpdate.convStatInp.msgLogList.filter(x=>x.fromID==componentToUpdate.convStatInp.otherPersonPublicId).length);
              }
            }
            

            
          }
          
        }
      }
      
    }

    for (let i:number=0; i< this.convStatList.length; i++){
      let csHasRedFlag:boolean = convStatIdsWithRedFlag.includes(this.convStatList[i].initiationID);
      if (!csHasRedFlag){
        this.convStatList[i].isShowRedNotify = false;
      }
    }



  }
  
  serverReq_updateTheSix(childComponent: TheSixComponent) {
    const postBody = {
      postType: 2,
    };
    this.serverAccessService.callServer(
      postBody,
      'updateTheSix', // inflight key
      (dataFromServer) => this.onServerReply_updateTheSix(dataFromServer, childComponent), // callback
      '/api/ClientMsg',  // API endpoint
      0,
      () => {
        childComponent.nextRequestCountDown = 7;
        childComponent.countDownDec();
        this.showError("תקלה בשרת.. לא קיבלנו נתונים. נסו שוב")
      }, // callback
      (errorMessage) => {
        childComponent.nextRequestCountDown = 7;
        childComponent.countDownDec();
        this.showError(errorMessage)
      }, // callback

      
    );
  }

  onServerReply_updateTheSix(dataFromServer: FormatHttpGetResponse[], childComponent: TheSixComponent) {
    // Error handling can be added as needed
    childComponent.nextRequestCountDown = 7;
    childComponent.countDownDec();

    const formattedIds = dataFromServer[0].hgr
      .filter((profile: HttpGeneralResponse) => profile.isAllowed)
      .map((profile: HttpGeneralResponse) => `U${profile.publicId}`);
    this.logActivity(`server updateTheSix received profiles of ${formattedIds.join(' ')}`, "serverResponse");


    let thumbInfoList: PersonInfo[] = [];
    this.populatePersonInfoList(dataFromServer, thumbInfoList);

    let profiles: Profile[] = thumbInfoList.map(person => ({
      userId: person.publicId,
      imageUrl: person.imgPath,
      name: person.firstName,
      age: person.age,
      usertext: person.usertext,
      isAllowed: person.isAllowed,
      isImgLoaded: false,
      neighborhood: person.neighborhood,
      city: person.city,
      likes: 17,
      trustRate: 80,
      onlineStatus: person.onlineStatus,
      relRecord: person.relRecord
    }));

    this.sixProfiles = profiles;
  }

  getNextProfile(childComponent : ProfileViewComponent){
    const postBody = {
      postType: 2,
      message: "getSingleNext",
    };
    this.serverAccessService.callServer(
      postBody,
      'updateSingleNext', // inflight key
      (dataFromServer) => this.onServerReply_updateSingleNext(dataFromServer, childComponent), // callback
      '/api/ClientMsg',  // API endpoint
      0,
      () => this.showError("תקלה בשרת.. לא קיבלנו נתונים. נסו שוב"), // callback
      (errorMessage) => this.showError(errorMessage), // callback
    );
  }

  onServerReply_updateSingleNext(dataFromServer: FormatHttpGetResponse[], childComponent: ProfileViewComponent) {

    const formattedIds = dataFromServer[0].hgr.map((profile: any) => `U${profile.publicId}`);
    this.logActivity(`server updateSingleNext received profile of ${formattedIds.join(' ')}`, "serverResponse");


    let thumbInfoList: PersonInfo[] = [];
    this.populatePersonInfoList(dataFromServer, thumbInfoList);

    let profiles: Profile[] = thumbInfoList.map(person => ({
      userId: person.publicId,
      imageUrl: person.imgPath,
      name: person.firstName,
      age: person.age,
      usertext: person.usertext,
      isAllowed: person.isAllowed,
      isImgLoaded: false,
      neighborhood: person.neighborhood,
      city: person.city,
      likes: 17,
      trustRate: 80,
      onlineStatus: person.onlineStatus,
      relRecord: person.relRecord
    }));

    this.selectedProfile = profiles[0];
    //childComponent.updateProfileData();
  }

  serverReq_updateLikes() {
    const postBody = {
      postType: 2,
      message: "getLikes",
    };

    this.isLikesLoading = true;

    this.serverAccessService.callServer(
      postBody,
      'updateLikes', // inflight key
      (dataFromServer) => this.onServerReply_updateLikes(dataFromServer), // callback
      '/api/ClientMsg',  // API endpoint
      0,
      () => this.showError("תקלה בשרת.. לא קיבלנו נתונים. נסו שוב"), // callback
      (errorMessage) => this.showError(errorMessage), // callback
    );
  }

  onServerReply_updateLikes(dataFromServer: FormatHttpGetResponse[]) {
    this.isLikesLoading = false;

    const formattedIds = dataFromServer[0].hgr.map((profile: any) => `U${profile.publicId}`);
    this.logActivity(`server updateLikes received profiles of ${formattedIds.join(' ')}`, "serverResponse");

    let thumbInfoList: PersonInfo[] = [];
    this.populatePersonInfoList(dataFromServer, thumbInfoList);
  
    let profiles: Profile[] = thumbInfoList.map(person => ({
      userId: person.publicId,
      imageUrl: person.imgPath,
      name: person.firstName,
      age: person.age,
      usertext: person.usertext,
      isAllowed: person.isAllowed,
      isImgLoaded: false,
      neighborhood: person.neighborhood,
      city: person.city,
      likes: 17,
      trustRate: 80,
      onlineStatus: person.onlineStatus,
      relRecord: person.relRecord
    }));
  
    this.likesProfiles = profiles;
    if (this.likesProfiles.length>5){
      this.uiMode = "manyLikes";
    }
    else if (this.likesProfiles.length>2){
      this.uiMode = "startedLiking";
    }
    else{
      this.uiMode = "newCommer";
    }
  }
  

  updateRelationshipRecord(relRecord: RelationshipRecord) {
    if (relRecord.interestTags.coffeeAgree) this.logActivity("update relRcord to U"+ relRecord.linkedUserId + " coffee agree"  );
    if (relRecord.interestTags.agreeToNone) this.logActivity("update relRcord to U"+ relRecord.linkedUserId + " dont agree"  );
    const postBody = {
      postType: 21,
      relRecord: relRecord
    };

    this.serverAccessService.callServer(
      postBody,
      'updateRelationship',  // inflight key
      (dataFromServer) => this.onServerReply_UpdateRelationshipRecord(dataFromServer), // callback
      '/api/ClientMsg',  // API endpoint
      0,
      () => this.showError("תקלה בשרת.. לא קיבלנו נתונים. נסו שוב"), // callback
      (errorMessage) => this.showError(errorMessage), // callback
    );
  }

  async onServerReply_UpdateRelationshipRecord(dataFromServer: FormatHttpGetResponse[]) {
    const updatedRelRecord: RelationshipRecord = dataFromServer[0].relRecord;
 
    this.serverReq_updateLikes();
  }

  





  /////////// DATA MANAGEMENT
  prepareAllMsg(){
    if (this.selectedChat.msgLogList.length > 0){
      for (let i:number = 0; i< this.selectedChat.msgLogList.length ; i++){
        this.selectedChat.msgLogList[i].index = i;
        if (this.selectedChat.msgLogList[i].fromID==this.selectedChat.otherPersonPublicId){
          this.selectedChat.msgLogList[i].isFromUser = false;
          this.selectedChat.msgLogList[i].fromFirstName= this.selectedChat.otherPersonFirstName;
          if (this.selectedChat.msgLogList[i].msgType == "ping"){
            if (this.selectedChat.otherPersonGender=="female") this.selectedChat.msgLogList[i].content="[שלחה אליך פינג]";
            if (this.selectedChat.otherPersonGender=="male") this.selectedChat.msgLogList[i].content="[שלח אליך פינג]";
          }
        }
        else{
          if (this.userGender=="female") this.selectedChat.msgLogList[i].fromFirstName="את";
          if (this.userGender=="male") this.selectedChat.msgLogList[i].fromFirstName="אתה";
          this.selectedChat.msgLogList[i].isFromUser = true;
          if (this.selectedChat.msgLogList[i].msgType == "ping"){
            if (this.selectedChat.otherPersonGender=="female") this.selectedChat.msgLogList[i].content="[שלחת אליה פינג]";
            if (this.selectedChat.otherPersonGender=="male") this.selectedChat.msgLogList[i].content="[שלחת אליו פינג]";
          }
        }
        if (this.selectedChat.msgLogList[i].isFromUser){
          this.selectedChat.msgLogList[i].msgLogCssClass="msgFromUser";
        }
        else{
          this.selectedChat.msgLogList[i].msgLogCssClass="msgFromOther";
        }
        this.selectedChat.msgLogList[i].msgLogCssClassFinal = this.selectedChat.msgLogList[i].msgLogCssClass;


      }
    }
  }

  populatePersonInfoList(dataFromServer:FormatHttpGetResponse[],personInfoList:PersonInfo[]){
    if (dataFromServer.length>0 && dataFromServer[0].hgr != null && dataFromServer[0].hgr.length > 0 )    {
      for (let i:number = 0; i< dataFromServer[0].hgr.length ; i++){
        let genRes = dataFromServer[0].hgr[i];
        let thumbI = new PersonInfo({
          parent:this,firstName:genRes.firstName,lastName:genRes.lastName, imgFile:genRes.imgFile , isAllowed:genRes.isAllowed, onlineStatus: genRes.onlineStatus, 
          neighborhood:genRes.neighborhood,city:genRes.city,
          age:genRes.age, publicId:genRes.publicId, 
          characteristics:genRes.characteristics, usertext:genRes.usertext, relRecord:genRes.relRecord
        })
        if (genRes.isExample== null){
          thumbI.isExample=false;
        } 
        else{
          thumbI.isExample=genRes.isExample;
        }
        
        thumbI.loceventsMatch = [];
        personInfoList.push(thumbI);
      }
    }
  }

  pushSigViewed(convId,viewed_sig,amountOfMsgs){
    let svr:SigViewedReport = new SigViewedReport();
    svr.amountOfMsgs=amountOfMsgs;
    svr.convId=convId;
    svr.viewed_sig=viewed_sig;
    this.svr_list.push(svr);
  }


  printAllCachedImages(): void {
    console.log('All Cached Images:');
    for (const [key, value] of Object.entries(this.imageCache)) {
        console.log('Key:', key, 'Value:', value && value.src ?  value.src : 'No src available');
    }
}

}


export class ViewStackItem {
  viewName: string;
  inputs: { [key: string]: any };

  constructor(viewName: string, inputs: { [key: string]: any } = {}) {
    this.viewName = viewName;
    this.inputs = inputs;
  }
}
