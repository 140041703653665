import { FormField, FormSection } from '../main-page/formatHttpGetResponse';

export const EDIT_PROFILE_SECTIONS: FormSection[] = [
  {
    name: 'basicInfo',
    title: 'פרטים בסיסיים',
    isVisible: true,
    fields: [
      { type: 'text', label: 'שם פרטי', small: "בעברית בבקשה", name: 'firstName', validators: ['required','minLength:2'] },
      { type: 'number', label: 'גיל', name: 'age', placeholder: '18-120', validators: ['required', 'min:18', 'max:120'] },
      { 
        type: "select",
        label: "עיר",
        name: "city",
        options: [
          { label: "בת ים", value: "בת ים" },
          { label: "גבעתיים", value: "גבעתיים" },
          { label: "הרצליה", value: "הרצליה" },
          { label: "רמת השרון", value: "רמת השרון" },
          { label: "חולון", value: "חולון" },
          { label: "רמת גן", value: "רמת גן" },
          { label: "תל אביב-יפו", value: "תל אביב-יפו" },
          { label: "אחר", value: "אחר" }
        ],
        validators: ["required"],
      },
      { 
        type: "select",
        label: "שכונה",
        name: "neighborhood",
        options: [], // Initially empty, will be populated based on the selected city
        validators: ["required"]
      },
      {
        type: "select",
        label: "מגדר",
        name: "gender",
        options: [
          { label: "אישה", value: "female" },
          { label: "גבר", value: "male" },
          { label: "אחר/ת", value: "other" }
        ],
        validators: ["required"]
      }
    ]
  },
  {
    name: 'contactInfo',
    title: 'פרטי יצירת קשר',
    isVisible: false,
    fields: [
        {
          type: "email",
          label: "אי מייל",
          small: "זהו גם שם המשתמש שלכם לצורך התחברות ושחזור סיסמה.",
          name: "email",
          placeholder : "someone@planet.earth",
          validators: ["required", "email"]
        },
        {
          type: "password",
          label: "סיסמה",
          name: "password",
          validators: ["required"]
        },
        {
          type: "tel",
          label: "מספר טלפון",
          small: "כדי שנוכל לעדכן בסמס",
          name: "phoneNumber",
          placeholder : "050-1234567",
          validators: ["required", "pattern:^0[0-9]{2}[- ]?[0-9]{7}$"]
        },
        {
            type: "separator",
            label: "",
            name: "",
            validators: []
        },
        {
            type: "checkbox",
            checkboxLabel:"קראתי ואני מסכימ/ה",
            label: "רגע, קראתם את תנאי השימוש?",
            name: "agreeUserTerms",
            validators: ["forceCheckTrue"],
          }
      ]
  },
  {
    name : "verifyPhone",
    title : "אימות הטלפון",
    isVisible : false,
    fields: [
      {
        type: "text",
        label: "הזינו את הקוד שנשלח בסמס",
        name: "verificationCode",
        placeholder : "------",
        validators: ["required", "pattern:^[a-zA-Z0-9]{6}$"]
      }
    ]
  },
  {
    name: 'imageAndMore',
    title: 'פרטים נוספים ותמונה',
    isVisible: false,
    fields: [
      {
        type: 'textarea',
        label: 'ספרו משהו (לפחות 20 תווים)',
        name: 'about',
        placeholder: 'לדוגמה איזו תוספת לפיצה אתם שונאים, מהו הספורט שבחיים לא תעשו, מה תרצו למצוא כאן, או כל דבר אחר שיעזור להתחיל שיחה',
        rows: 6,
        validators: ['required', 'minLength:20'],
      },
      {
        type: 'file',
        label: 'העלו תמונת פנים (עם חיוך!)',
        name: 'profileImage',
        validators: ['required'],
        small: "כדי שנראה שאתם אמיתיים! שימו לב: אנחנו צריכים לאשר את התמונות, חשוב להעלות אחת שעושה חשק להכיר, בלבוש מלא כמובן, באיכות צילום טובה",
      }
    ]
  }
  // Continue with more sections as needed
];



export const NEIGHBORHOOD_OPTIONS = {
  "בת ים": [
    { label: "מרכז העיר", value: "מרכז העיר" },
    { label: "רמת יוסף", value: "רמת יוסף" },
    { label: "שיכון עמידר", value: "שיכון עמידר" },
    { label: "נווה חוף", value: "נווה חוף" },
    { label: "עיר ימים", value: "עיר ימים" },
    { label: "נווה שלום", value: "נווה שלום" },
    { label: "נאות רחל", value: "נאות רחל" },
    { label: "ניצנה", value: "ניצנה" },
    { label: "פארק הים", value: "פארק הים" },
    { label: "עמידר", value: "עמידר" },
    { label: "אחר", value: "אחר" }
  ],

  "גבעתיים": [
    { label: "רמת חן", value: "רמת חן" },
    { label: "ביאליק", value: "ביאליק" },
    { label: "שיינקין", value: "שיינקין" },
    { label: "כפר אזר", value: "כפר אזר" },
    { label: "אפקה", value: "אפקה" },
    { label: "אורה", value: "אורה" },
    { label: "גבעת רמבם", value: "גבעת רמבם" },
    { label: "בורוכוב", value: "בורוכוב" },
    { label: "מתחם כורזין", value: "מתחם כורזין" },
    { label: "רמת יצחק", value: "רמת יצחק" },
    { label: "אחר", value: "אחר" }
  ],

  "הרצליה": [
    { label: "הרצליה פיתוח", value: "הרצליה פיתוח" },
    { label: "נווה עמל", value: "נווה עמל" },
    { label: "הרצליה הירוקה", value: "הרצליה הירוקה" },
    { label: "יד התשעה", value: "יד התשעה" },
    { label: "הרצליה ב'", value: "הרצליה ב'" },
    { label: "מרכז העיר", value: "מרכז העיר" },
    { label: "שיכון ויצמן", value: "שיכון ויצמן" },
    { label: "גליל ים", value: "גליל ים" },
    { label: "נחלת עדה", value: "נחלת עדה" },
    { label: "נווה ישראל", value: "נווה ישראל" },
    { label: "אחר", value: "אחר" }
  ],

  "רמת השרון": [
    { label: "נווה מגן", value: "נווה מגן" },
    { label: "קריית יערים", value: "קריית יערים" },
    { label: "נווה גן", value: "נווה גן" },
    { label: "נווה רום", value: "נווה רום" },
    { label: "מורשה", value: "מורשה" },
    { label: "שיכון הצנחנים", value: "שיכון הצנחנים" },
    { label: "קריית שאול", value: "קריית שאול" },
    { label: "תל ברוך", value: "תל ברוך" },
    { label: "הדר יוסף", value: "הדר יוסף" },
    { label: "רמות השבים", value: "רמות השבים" },
    { label: "אחר", value: "אחר" }
  ],

  "חולון": [
    { label: "נווה רמז", value: "נווה רמז" },
    { label: "אגרובנק", value: "אגרובנק" },
    { label: "ג'סי כהן", value: "ג'סי כהן" },
    { label: "תל גיבורים", value: "תל גיבורים" },
    { label: "קריית שרת", value: "קריית שרת" },
    { label: "רמת יוסף", value: "רמת יוסף" },
    { label: "קריית בן גוריון", value: "קריית בן גוריון" },
    { label: "רסקו", value: "רסקו" },
    { label: "נווה ארזים", value: "נווה ארזים" },
    { label: "קריית עבודה", value: "קריית עבודה" },
    { label: "אחר", value: "אחר" }
  ],

  "רמת גן": [
    { label: "רמת חן", value: "רמת חן" },
    { label: "מרום נווה", value: "מרום נווה" },
    { label: "גני ערמונים", value: "גני ערמונים" },
    { label: "רמת אפעל", value: "רמת אפעל" },
    { label: "הלל", value: "הלל" },
    { label: "מרכז העיר", value: "מרכז העיר" },
    { label: "קריית קריניצי", value: "קריית קריניצי" },
    { label: "הירקון", value: "הירקון" },
    { label: "הגפן", value: "הגפן" },
    { label: "שכונת ראשונים", value: "שכונת ראשונים" },
    { label: "אחר", value: "אחר" }
  ],

  "תל אביב-יפו": [
    { label: "פלורנטין", value: "פלורנטין" },
    { label: "נוה צדק", value: "נוה צדק" },
    { label: "לב העיר", value: "לב העיר" },
    { label: "הצפון הישן", value: "הצפון הישן" },
    { label: "שכונת התקווה", value: "שכונת התקווה" },
    { label: "יד אליהו", value: "יד אליהו" },
    { label: "רמת אביב", value: "רמת אביב" },
    { label: "יפו העתיקה", value: "יפו העתיקה" },
    { label: "קריית שלום", value: "קריית שלום" },
    { label: "אפקה", value: "אפקה" },
    { label: "אחר", value: "אחר" }
  ],

  "אחר": [
    { label: "אחר", value: "אחר" }
  ]
};
