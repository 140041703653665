import { Component, HostListener, OnInit, ViewChild } from '@angular/core';

import { delay } from 'q';
import { HttpClient, HttpParams, HttpHeaders, HttpEventType } from '@angular/common/http';
import { FormatHttpGetResponse, HttpGeneralResponse,characteristic,conversationsStatus,userProfileDetails, userNotifyPref, MNG_logActivity} from '../main-page/formatHttpGetResponse';
import { Observable } from 'rxjs';
import { DomSanitizer, SafeStyle, SafeUrl } from '@angular/platform-browser';
import { PersonInfo} from '../person-info';
import { ServerAccessService } from '../services/server-access.service';
import { Profile } from '../the-six/the-six.component';
import { CursorError } from '@angular/compiler/src/ml_parser/lexer';

const enum ImgEditStatus {
  OFF = 0,
  RESIZE_IMG = 1,
  MOVE_IMG = 2,
  RESIZE_RECT = 3,
  MOVE_RECT = 4
}
const enum IMGPOLICY {
  KEEP_ASPECT_RATIO = 0,
  FREE_STYLE = 1
}
const enum IMGROTATEFLIP {
  NOROTATE = 0,
  ROTATE90 = 1,
  ROTATE180 = 2,
  ROTATE270 = 3
  
}


@Component({
  selector: 'app-manage',
  templateUrl: './manage.component.html',
  styleUrls: ['./manage.component.css']
})
export class ManageComponent implements OnInit {

  constructor(private _http: HttpClient, private sanitizer:DomSanitizer, private serverAccessService: ServerAccessService) { }

  login_password:string="";
  apiValues:string[]=[];
  hasErrMsg:boolean = false;
  hasSucMsg:boolean = false;
  //---------edit image
  showMain:boolean = true;
  showImgEdit:boolean = false;
  showTempImgResult:boolean = false;
  updToEditImg:UPD = new UPD;

  tempImgResultPath:string = "";
  tempImgResultPathSafe:SafeUrl ;


  origImgZoomWidth:number = 300;
  ZoomedImgWidth:number = 300;
  ZoomedImgTop:number = 0;
  ZoomedImgRight:number = 0;

  RedRectWidth:number = 30;
  RedRectHeight:number = 30;
  RedRectTop:number = 0;
  RedRectRight:number = 0;

  NewImgPolicy:IMGPOLICY = IMGPOLICY.KEEP_ASPECT_RATIO;
  NewImgRotateFlip:IMGROTATEFLIP = IMGROTATEFLIP.NOROTATE;

  imgNatural_W:number = 0;
  imgNatural_H:number = 0;



  imgZoomRangeVal:number = 100;
  firstTimeZoom:boolean = true;

  tmpImgEditRandString:string = "";
  editStatus:ImgEditStatus = ImgEditStatus.OFF;
  mouseOnClickLocation:{x:number,y:number};
  mouseCurrentLocation:{x:number,y:number};
  ZoomedImgOnClickTop:number = 0;
  ZoomedImgOnClickRight:number = 0;
  RedRectOnClickTop:number = 0;
  RedRectOnClickRight:number = 0;
  RedRectOnClickWidth:number = 0;
  RedRectOnClickHeight:number = 0;
  communicationStat:string = "wait for command..";
  //--------------
  errMsg:string="";
  sucMsg:string="";
  id_check_harrasment:number;
  id_report:string;

  usersToApprove:UPD[]=[];

  convStatList: conversationsStatus[]=[];          // all conversation statuses for ConvStat Modal
  convStatPersonInfoList: PersonInfo[]=[];         // all person info for the conversation status list, so that clicking on convstatus image will open profile.  

  allConvStats : personInitiatedConvs[]=[];        // all people that initiated convs with their list of conv stats

  
  
  //----------------  NEW MANAGER PAGE PARAMS   START -------------------------------------------------------------------------------------------

  isSectionVisible = {
    password: true,
    options: true,
    report: true,
  };
  currentOption:string = "systemReport";
  currentReport:string = "";
  timeSpan:number=72;
  userIdToFetch:string = "";
  managerPassword:string="";

  onlineProfiles: Profile[];
  showLoading: boolean = false;

  showOnlineUsers: boolean = false;
  showConversations: boolean = false;
  showUserProfile: boolean = false;
  showUserLogActivities: boolean = false;

  isImageLoaded: boolean = false;
  userLogActivities : MNG_logActivity[];
  userUPD :  userProfileDetails;
  referencedProfiles : HttpGeneralResponse[];
  

  //----------------  NEW MANAGER PAGE PARAMS   END -------------------------------------------------------------------------------------------


  ngOnInit() {

  }
  setOption(option: string): void {
    this.currentOption = option;
  }

  toggleSection(section: string): void {
    this.isSectionVisible[section] = !this.isSectionVisible[section];
  }

  toggleOnlineUsersView(): void {
    this.showOnlineUsers = !this.showOnlineUsers;
  }

  toggleConversationsView(): void {
    this.showConversations = !this.showConversations;
  }

  toggleUserProfileView(): void {
    this.showUserProfile = !this.showUserProfile;
  }

  toggleUserLogActivitiesView(): void {
    this.showUserLogActivities = !this.showUserLogActivities;
  }

  fetchReport(){
    if (this.currentOption == 'systemReport') this.serverReq_getSystemReport();
    if (this.currentOption == 'userReport') this.serverReq_getUserReport(this.userIdToFetch);
    if (this.currentOption == 'profilesToApprove') this.log_in_and_get_profiles_to_approve();
  }

  onImageLoad(): void {
    this.isImageLoaded = true;
  }

  getProfileById(publicId: number): any {
    return this.referencedProfiles.find(profile => profile.publicId === publicId);
  }


  

  //------------ GET system report
  // timespan "last 5 hours", or time-axis view
  // profiles deleted 
  // unique onlines number and list
  // convs w/ message active
  // messages sent (conv users: , user1:x messages, user2: y messages)

  serverReq_getSystemReport() {
    const postBody = {
      postType: 16,
      "userProfileDetails" : {
        password:this.managerPassword,
      },
      message: this.timeSpan.toString() // Sending timespan as part of the request
    };
    this.showLoading = true;

    this.serverAccessService.callServer(
      postBody,
      'getUsersFromTimespan', // inflight key
      (dataFromServer) => this.onServerReply_getSystemReport(dataFromServer), // callback
      '/api/ClientMsg',  // API endpoint
      0,
      () => { alert("error without message"); this.showLoading = false;
      },
      (errorMessage) => { alert("error message: " + errorMessage); this.showLoading = false;
      }
    );
  }


onServerReply_getSystemReport(dataFromServer: FormatHttpGetResponse[]) {
  this.currentReport = "systemReport";
  this.showLoading = false;
  

  // --------------------- Profiles online --------------------------------
  let thumbInfoList: PersonInfo[] = [];
  this.populatePersonInfoList(dataFromServer, thumbInfoList);

  let profiles: Profile[] = thumbInfoList.map(person => ({
    userId: person.publicId,
    imageUrl: person.imgPath,
    name: person.firstName,
    age: person.age,
    usertext: person.usertext,
    isAllowed: person.isAllowed,
    isImgLoaded: false,
    neighborhood: person.neighborhood,
    city: person.city,
    likes: 17,
    trustRate: 80,
    onlineStatus: person.onlineStatus,
    relRecord: person.relRecord
  }));

  this.onlineProfiles = profiles.filter(profile => profile.onlineStatus.trim() !== "");


  //----------------------- Active chats --------------------------------

  this.convStatPersonInfoList = [];
  this.populatePersonInfoList(dataFromServer,this.convStatPersonInfoList);
  this.convStatList = [];
  this.allConvStats = [];

    if (dataFromServer.length>0 && dataFromServer[0].convStatArr != null  )    { //&& dataFromServer[0].convStatArr.length > 0
      this.convStatList = [];
      for (let i:number = 0; i< dataFromServer[0].convStatArr.length ; i++){
        let convStat = dataFromServer[0].convStatArr[i];

        let timeDiff = (new Date().getTime() - new Date(convStat.timeLastMessage).getTime()) / (1000 * 60 * 60);

        
        if (timeDiff > this.timeSpan || !convStat.isMyInitiation){
          continue;
        }

        let convI = new conversationsStatus({
          parent:this,
          initiationID:convStat.initiationID,timeOfInitation:convStat.timeOfInitation,
          otherPersonPublicId:convStat.otherPersonPublicId,otherPersonFirstName:convStat.otherPersonFirstName,
          userGender:"male",
          otherPersonGender:convStat.otherPersonGender,
          otherPersonOnlineStatus:convStat.otherPersonOnlineStatus,
          otherPersonPic:convStat.otherPersonPic,isMyInitiation:convStat.isMyInitiation,
          isMyTurn:convStat.isMyTurn,lastMessageType:convStat.lastMessageType,
          lastMessage:convStat.lastMessage,timeLastMessage:convStat.timeLastMessage
        })
        convI.msgLogList = convStat.msgLogList;
        convI.otherPersonPicSafe = this.sanitizer.bypassSecurityTrustStyle("url("+convI.otherPersonPic+")");

        let initiatorID:number = Number(convI.initiationID.substr(1,5));
        let personInitiator:PersonInfo = this.convStatPersonInfoList.find(x=>x.publicId==initiatorID);
        let personOther:PersonInfo = this.convStatPersonInfoList.find(x=>x.publicId==convI.otherPersonPublicId);

        convI.otherPersonAge = personOther.age;
        convI.myAge = personInitiator.age;
        convI.myFirstName = personInitiator.firstName;
        convI.myPublicID = personInitiator.publicId;
        convI.myImagePath = personInitiator.imgPath;
        

        this.convStatList.push(convI);
        this.convStatList.sort((a,b)=>(a.timeLastMessage < b.timeLastMessage)?1:-1);




        if (convI.isMyInitiation){  
          let initiatorID:number = Number(convI.initiationID.substr(1,5));
          let pic:personInitiatedConvs  =  this.allConvStats.find(x=>x.publicID==initiatorID);
          if (pic == null)
          {
            pic = new personInitiatedConvs();
            pic.publicID = initiatorID;
            let personI:PersonInfo = this.convStatPersonInfoList.find(x=>x.publicId==initiatorID);
            if (personI != null){
              pic.name = personI.firstName;
              pic.age = personI.age;
              pic.imgPath = "/images/people_images/" + personI.imgFile;
              pic.imgLoadedSafe = this.sanitizer.bypassSecurityTrustStyle("url("+pic.imgPath+")");
              this.allConvStats.push(pic);

            }
          }
          pic.convs.push(convI)
          pic.convs.sort((a,b)=>(a.timeLastMessage < b.timeLastMessage)?1:-1);
          pic.timeLastMessage = pic.convs[0].timeLastMessage;
          this.allConvStats.sort((a,b)=>(a.timeLastMessage < b.timeLastMessage)?1:-1);
         }

      }


    }

}


serverReq_getUserReport(userId: string) {
  const postBody = {
    postType: 17, // Unique postType for this request
    "userProfileDetails" : {
        password:this.managerPassword,
    },
    message: this.userIdToFetch,       // User ID to fetch profile
  };

  this.showLoading = true;

  this.serverAccessService.callServer(
    postBody,
    'getUserProfile', // inflight key
    (dataFromServer) => this.onServerReply_getUserReport(dataFromServer), // callback
    '/api/ClientMsg',  // API endpoint
    0,
    () => { alert("error without message"); this.showLoading = false; },
    (errorMessage) => { alert("error message: " + errorMessage); this.showLoading = false; }
  );
}

onServerReply_getUserReport(dataFromServer: FormatHttpGetResponse) {
  this.currentReport = "userReport";
  this.showLoading = false;

  this.userLogActivities = dataFromServer[0].logActivities.map(activity => {
        // Remove Uxxxxx from activity string
        activity.activity = activity.activity.replace(/U\d{5}/g, '').trim();
        return activity;
      });
  this.referencedProfiles = dataFromServer[0].hgr;
  this.userUPD = dataFromServer[0].upd;

  
}



  // ----------- GET user report
  // timespan
  // view: time, event, otheruser pic




  
  //------------ GET PROFILES
  //todo: add message: "week" - timespan for profiles. 

  async log_in_and_get_profiles_to_approve(){
    const postBody = {
      postType: 11, 
      "userProfileDetails" : {
          password:this.managerPassword,
      },
      message: this.timeSpan.toString() 
    };
  
    this.showLoading = true;
  
    this.serverAccessService.callServer(
      postBody,
      'getProfilesToApprove', // inflight key
      (dataFromServer) => this.onServerGetProfilesToApprove(dataFromServer), // callback
      '/api/ClientMsg',  // API endpoint
      0,
      () => { alert("error without message"); this.showLoading = false; },
      (errorMessage) => { alert("error message: " + errorMessage); this.showLoading = false; }
    );

  }


  onServerGetProfilesToApprove (dataFromServer:FormatHttpGetResponse[]){

    this.currentReport = "usersToApprove";
    this.showLoading = false;

    if (dataFromServer== null || dataFromServer.length==0) {
      this.hasErrMsg = true;
      this.hasSucMsg = false;
      this.errMsg = "ארעה שגיאה, נסו שוב מאוחר יותר";
      return;
    }
    if (!dataFromServer[0].actRes.success){
      this.hasErrMsg = true;
      this.hasSucMsg = false;
      this.errMsg = dataFromServer[0].actRes.error_message;
      return;
    }
    this.hasErrMsg=false;
    this.usersToApprove=[];
    for (let i:number = 0; i< dataFromServer.length ; i++){
      let profileFromServ:userProfileDetails = dataFromServer[i].upd;
      let newUPD:UPD = new UPD();
      newUPD.publicId = profileFromServ.publicId;
      newUPD.firstName= profileFromServ.firstName;
      newUPD.email = profileFromServ.email;
      newUPD.profilePath = profileFromServ.profilePath;
      if (profileFromServ.age != 0) newUPD.age = profileFromServ.age;
      if (profileFromServ.gender) newUPD.gender = profileFromServ.gender;
      if (profileFromServ.phone) newUPD.phone = profileFromServ.phone;
      if (profileFromServ.telegram) newUPD.telegram = profileFromServ.telegram;
      if (profileFromServ.usertext) newUPD.usertext = profileFromServ.usertext;
      if (profileFromServ.city) newUPD.city = profileFromServ.city;
      if (profileFromServ.neighborhood) newUPD.neighborhood = profileFromServ.neighborhood;
      if (profileFromServ.tempImgFolder) newUPD.tempImgFolder = profileFromServ.tempImgFolder;
      if (profileFromServ.notifyPref != null) {
        newUPD.notifyPref = profileFromServ.notifyPref;
        newUPD.notifyPref.defined = true;
        if (profileFromServ.notifyPref.mailMaxFreqDays>0) newUPD.notifyPref.notifyLimit = true;
      }
      else{
        //newUPD.notifyPref = new userNotifyPref();
        //newUPD.notifyPref.defined = false;
      }
       


      let folder = "/images/people_images/" + profileFromServ.tempImgFolder;
      newUPD.imgLoadedSafe = this.sanitizer.bypassSecurityTrustStyle("url("+folder+")");
      newUPD.imgLoadedSafeUrl = this.sanitizer.bypassSecurityTrustUrl(folder);
      this.usersToApprove.push(newUPD);
    }

  }


  //------------ GET CONVERSATIONS
  async log_in_and_get_conv_status(){
    this.hasErrMsg=false;
    let postBody = {
      "postType" : 15,
      "referToPerson" : this.id_check_harrasment,
      "userProfileDetails" : {
        
        password:this.login_password,
      }
    }
      let headers = new HttpHeaders()
        .set('Content-Type','application/json');
      let postObservable:Observable<FormatHttpGetResponse[]> = this._http.post<FormatHttpGetResponse[]>('/api/ClientMsg',postBody,{headers}); 
      postObservable.subscribe(
        dataFromServer => { this.onServerReply_GetConvStatus(dataFromServer);},
        error => this.apiValues = ['error on http in checkServerMsg','error found']
      )
  }

  async onServerReply_GetConvStatus(dataFromServer:FormatHttpGetResponse[]){
    if (dataFromServer== null || dataFromServer.length==0) {
      this.hasErrMsg = true;
      this.hasSucMsg = false;
      this.errMsg = "ארעה שגיאה, נסו שוב מאוחר יותר";
      return;
    }
    if (!dataFromServer[0].actRes.success){
      this.hasErrMsg = true;
      this.hasSucMsg = false;
      this.errMsg = dataFromServer[0].actRes.error_message;
      return;
    }


    this.populatePersonInfoList(dataFromServer,this.convStatPersonInfoList);

    if (dataFromServer.length>0 && dataFromServer[0].convStatArr != null  )    { //&& dataFromServer[0].convStatArr.length > 0
      this.convStatList = [];
      for (let i:number = 0; i< dataFromServer[0].convStatArr.length ; i++){
        let convStat = dataFromServer[0].convStatArr[i];
        let convI = new conversationsStatus({
          parent:this,
          initiationID:convStat.initiationID,timeOfInitation:convStat.timeOfInitation,
          otherPersonPublicId:convStat.otherPersonPublicId,otherPersonFirstName:convStat.otherPersonFirstName,
          userGender:"male",
          otherPersonGender:convStat.otherPersonGender,
          otherPersonOnlineStatus:convStat.otherPersonOnlineStatus,
          otherPersonPic:convStat.otherPersonPic,isMyInitiation:convStat.isMyInitiation,
          isMyTurn:convStat.isMyTurn,lastMessageType:convStat.lastMessageType,
          lastMessage:convStat.lastMessage,timeLastMessage:convStat.timeLastMessage
        })
        convI.msgLogList = convStat.msgLogList;
        convI.otherPersonPicSafe = this.sanitizer.bypassSecurityTrustStyle("url("+convI.otherPersonPic+")");

        this.convStatList.push(convI);
        if (convI.isMyInitiation){  
          let initiatorID:number = Number(convI.initiationID.substr(1,5));
          let pic:personInitiatedConvs  =  this.allConvStats.find(x=>x.publicID==initiatorID);
          if (pic == null)
          {
            pic = new personInitiatedConvs();
            pic.publicID = initiatorID;
            let personI:PersonInfo = this.convStatPersonInfoList.find(x=>x.publicId==initiatorID);
            if (personI != null){
              pic.name = personI.firstName;
              pic.age = personI.age;
              pic.imgPath = "/images/people_images/" + personI.imgFile;
              pic.imgLoadedSafe = this.sanitizer.bypassSecurityTrustStyle("url("+pic.imgPath+")");
              this.allConvStats.push(pic);

            }
          }
          pic.convs.push(convI)
          pic.convs.sort((a,b)=>(a.timeLastMessage < b.timeLastMessage)?1:-1);
          pic.timeLastMessage = pic.convs[0].timeLastMessage;
          this.allConvStats.sort((a,b)=>(a.timeLastMessage < b.timeLastMessage)?1:-1);
         }

      }


    }
    
    
    
  }


  //------------ SEND PROFILE DECISIONS
  async send_decisions(publicId:number=0){
    let decisionsToSend:ProfileDecision[] = [];
    for (let i:number=0; i<this.usersToApprove.length; i++){
      if (this.usersToApprove[i].manageIsReply && 
          (publicId==0 || publicId==this.usersToApprove[i].publicId) // either no input to public ID, or take only the user with the input.
        ){
        let dec:ProfileDecision = new ProfileDecision();
        dec.publicId = this.usersToApprove[i].publicId;
        dec.profilePath= this.usersToApprove[i].profilePath;
        dec.managerApprove= this.usersToApprove[i].managerApprove;
        dec.managerReplyText= this.usersToApprove[i].managerReplyText;
        dec.engagementBoost = this.usersToApprove[i].engagementBoost;
        decisionsToSend.push(this.usersToApprove[i])
      }
    }


    let postBody = {
      "postType" : 12,
      "userProfileDetails" : {
        
        password:this.managerPassword,
      },
      "ManagerDecisions" : decisionsToSend
    }
      let headers = new HttpHeaders()
        .set('Content-Type','application/json');
      let postObservable:Observable<FormatHttpGetResponse[]> = this._http.post<FormatHttpGetResponse[]>('/api/ClientMsg',postBody,{headers}); 
      postObservable.subscribe(
        dataFromServer => { this.onServerProfileDecisionsDone(dataFromServer,publicId);},
        error => this.apiValues = ['error on http in checkServerMsg','error found']
      )
  }

  onServerProfileDecisionsDone (dataFromServer:FormatHttpGetResponse[],publicId:number){
    let currentUPD:UPD = new UPD() ;

    if (publicId != 0){
      currentUPD = this.usersToApprove.find(x=>x.publicId==publicId);
    }

    if (dataFromServer== null || dataFromServer.length==0) {
      this.hasErrMsg = true;
      this.hasSucMsg = false;
      this.errMsg = "ארעה שגיאה, נסו שוב מאוחר יותר";
      if (publicId != 0){
        currentUPD.serverResult="error";
        currentUPD.showDetails=false;
      }
      return;
    }
    if (!dataFromServer[0].actRes.success){
      this.hasErrMsg = true;
      this.hasSucMsg = false;
      this.errMsg = dataFromServer[0].actRes.error_message;
      if (publicId != 0){
        currentUPD.serverResult="error";
        currentUPD.showDetails=false;
      }
      return;
    }
    if (publicId != 0){
      currentUPD.serverResult="ok";
      currentUPD.showDetails=false;
    }
    this.hasErrMsg=false;
    this.hasSucMsg=true; 
    this.sucMsg = "לא התקבלה שגיאה"; 

  }


  //------------ HELPING FUNCTIONS
  populatePersonInfoList(dataFromServer:FormatHttpGetResponse[],personInfoList:PersonInfo[]){
    if (dataFromServer.length>0 && dataFromServer[0].hgr != null && dataFromServer[0].hgr.length > 0 )    {
      for (let i:number = 0; i< dataFromServer[0].hgr.length ; i++){
        let genRes = dataFromServer[0].hgr[i];
        let thumbI = new PersonInfo({
          parent:this,firstName:genRes.firstName,lastName:genRes.lastName, imgFile:genRes.imgFile , isAllowed:genRes.isAllowed, onlineStatus:genRes.onlineStatus,
          neighborhood:genRes.neighborhood,city:genRes.city,
          age:genRes.age, publicId:genRes.publicId, 
          characteristics:genRes.characteristics, usertext:genRes.usertext
        })
        if (genRes.isExample== null){
          thumbI.isExample=false;
        } 
        else{
          thumbI.isExample=genRes.isExample;
        }
        //this.thumbInfoList.push(thumbI);
        personInfoList.push(thumbI);
        
        //if (newAvailSlotInfo.isFuture) this.availSlotList.push(newAvailSlotInfo);

      }
    }
  }


  //------------ IMAGE PROCESSING




  toggleShow( initiatorID:number){
    let pic = this.allConvStats.find(x=>x.publicID==initiatorID);
    if (pic.showContent) {
      pic.showContent= false;
    }
    else{
      pic.showContent= true;
    }
  }

  showImgEditView(publicID:number ){
    if (this.showMain) {
      this.showMain= false;
      this.showImgEdit = true;

      this.updToEditImg = this.usersToApprove.find(x=>x.publicId==publicID);
      
      //initilizing both the img element and the range elelment
      this.ZoomedImgWidth = 100; //percent
      this.ZoomedImgTop = 0;
      this.ZoomedImgRight = 0;
      this.imgZoomRangeVal = 100;
      this.firstTimeZoom = true;
      this.editStatus = ImgEditStatus.OFF;
      this.RedRectHeight = 100; 
      this.RedRectWidth = 100; 
      this.RedRectRight = 30;
      this.RedRectTop = 30;
      this.NewImgPolicy = IMGPOLICY.KEEP_ASPECT_RATIO;

      this.showTempImgResult = false;
    }
  }
  showMainView( ){
    if (this.showImgEdit) {
      this.showMain= true;
      this.showImgEdit = false;
    }
  }

  showCropResult(){
    //this.imgNatural_H
    //this.imgNatural_W
    this.communicationStat ="show result clicked";

    let imgComponent:HTMLImageElement = document.getElementById("imgToEdit") as HTMLImageElement;
    let ZoomedImg_W:number = imgComponent.clientWidth;
    let ZoomedImg_H:number = imgComponent.clientHeight;

    let cropHeightPercent = Math.round(100*this.RedRectHeight/ZoomedImg_H);
    let cropWidthPercent = Math.round(100*this.RedRectWidth/ZoomedImg_W);

    let cropTopPrecent    = Math.round(100*(this.RedRectTop-this.ZoomedImgTop)/ZoomedImg_H);
    
    let cropRightPercent   = Math.round(100*(this.RedRectRight-this.ZoomedImgRight)/ZoomedImg_W);
    let cropLeftPercent   = 100 - cropWidthPercent - cropRightPercent;

    let decisionsToSend:ProfileDecision[] = [];
    let decisionToSend:ProfileDecision = new ProfileDecision;
    decisionToSend.managerApprove = "tempEdit";
    let rotateflip:string = "";
    if (this.NewImgRotateFlip==IMGROTATEFLIP.ROTATE90) rotateflip = "90";
    if (this.NewImgRotateFlip==IMGROTATEFLIP.ROTATE180) rotateflip = "180";
    if (this.NewImgRotateFlip==IMGROTATEFLIP.ROTATE270) rotateflip = "270";
    decisionToSend.resizeParams = {
      "imgFilePath": this.updToEditImg.tempImgFolder,
      "imgNewFilePolicy": "TEMP",
      "cropLeftPrecent": cropLeftPercent,
      "cropTopPrecent": cropTopPrecent,
      "cropWidthPrecent": cropWidthPercent,
      "cropHeightPrecent": cropHeightPercent,
      "rotateFlip" : rotateflip
    }

    decisionsToSend.push(decisionToSend);

    let postBody = {
      "postType" : 18,
      "userProfileDetails" : {
        password:this.managerPassword,
      },
      "ManagerDecisions" : decisionsToSend
    }

    let headers = new HttpHeaders()
        .set('Content-Type','application/json');
      let postObservable:Observable<FormatHttpGetResponse[]> = this._http.post<FormatHttpGetResponse[]>('/api/ClientMsg',postBody,{headers}); 
      postObservable.subscribe(
        dataFromServer => { this.onServerResizeDone(dataFromServer);},
        error => this.apiValues = ['error on http in checkServerMsg','error found']
      )
    
      this.communicationStat ="crop request sent";

  }

  approveCropResult(){
    this.communicationStat ="approve crop clicked";
    let decisionsToSend:ProfileDecision[] = [];
    let decisionToSend:ProfileDecision = new ProfileDecision;
    decisionToSend.managerApprove = "approveEdit";
    decisionToSend.resizeParams = {
      "imgFilePath": this.updToEditImg.tempImgFolder,
      "imgNewFilePolicy": this.tmpImgEditRandString,
      "cropLeftPrecent": 0,
      "cropTopPrecent": 0,
      "cropWidthPrecent": 0,
      "cropHeightPrecent": 0,
      "rotateFlip" : (this.NewImgRotateFlip==IMGROTATEFLIP.ROTATE90)?"90":""
    }

    decisionsToSend.push(decisionToSend);

    let postBody = {
      "postType" : 18,
      "userProfileDetails" : {
        password:this.managerPassword,
      },
      "ManagerDecisions" : decisionsToSend
    }

    let headers = new HttpHeaders()
        .set('Content-Type','application/json');
      let postObservable:Observable<FormatHttpGetResponse[]> = this._http.post<FormatHttpGetResponse[]>('/api/ClientMsg',postBody,{headers}); 
      postObservable.subscribe(
        dataFromServer => { this.onServerImgEditApprovalDone(dataFromServer);},
        error => this.apiValues = ['error on http in checkServerMsg','error found']
      )
    
      this.communicationStat ="approval request sent";
  }

  restoreOrig(){
    this.communicationStat ="restore orig clicked";
    let decisionsToSend:ProfileDecision[] = [];
    let decisionToSend:ProfileDecision = new ProfileDecision;
    decisionToSend.managerApprove = "restoreOrig";
    decisionToSend.resizeParams = {
      "imgFilePath": this.updToEditImg.tempImgFolder,
      "imgNewFilePolicy": this.tmpImgEditRandString,
      "cropLeftPrecent": 0,
      "cropTopPrecent": 0,
      "cropWidthPrecent": 0,
      "cropHeightPrecent": 0,
      "rotateFlip" : (this.NewImgRotateFlip==IMGROTATEFLIP.ROTATE90)?"90":""
    }

    decisionsToSend.push(decisionToSend);

    let postBody = {
      "postType" : 18,
      "userProfileDetails" : {
        password:this.managerPassword,
      },
      "ManagerDecisions" : decisionsToSend
    }

    let headers = new HttpHeaders()
        .set('Content-Type','application/json');
      let postObservable:Observable<FormatHttpGetResponse[]> = this._http.post<FormatHttpGetResponse[]>('/api/ClientMsg',postBody,{headers}); 
      postObservable.subscribe(
        dataFromServer => { this.onServerImgEditRestoreOrig(dataFromServer);},
        error => this.apiValues = ['error on http in checkServerMsg','error found']
      )
      this.communicationStat ="restore request sent";
  }

  onServerImgEditRestoreOrig(dataFromServer:FormatHttpGetResponse[]){
    this.communicationStat ="restore orig server reply";
    if (dataFromServer== null || dataFromServer.length==0) {
      alert("error in editing.. :(");
      return;
    }
    if (dataFromServer[0].actRes.success == true) {
      let userJustEditedImg:UPD = this.usersToApprove.find(x=>x.publicId==this.updToEditImg.publicId) as UPD;
      let folder = "/images/people_images/" + userJustEditedImg.tempImgFolder;
      userJustEditedImg.imgLoadedSafe = this.sanitizer.bypassSecurityTrustStyle("url("+folder+")");
      this.showTempImgResult = false;
      this.showMainView();
    }
  }

  cleanCropResult(){
    this.showTempImgResult = false;
  }

  onServerImgEditApprovalDone (dataFromServer:FormatHttpGetResponse[]){
    this.communicationStat ="approval server reply";
    if (dataFromServer== null || dataFromServer.length==0) {
      alert("error in editing.. :(");
      return;
    }
    if (dataFromServer[0].actRes.success == true) {
      let userJustEditedImg:UPD = this.usersToApprove.find(x=>x.publicId==this.updToEditImg.publicId) as UPD;
      userJustEditedImg.imgLoadedSafe = this.tempImgResultPathSafe;
      this.showTempImgResult = false;
      this.showMainView();
    }

  }
  onServerResizeDone (dataFromServer:FormatHttpGetResponse[]){
    this.communicationStat ="crop server reply";
    if (dataFromServer== null || dataFromServer.length==0) {
      alert("error in editing.. :(");
      return;
    }
    if (dataFromServer[0].actRes.success == true) {
      this.showTempImgResult = true;
      this.tmpImgEditRandString = dataFromServer[0].actRes.error_message;
      this.tempImgResultPath = "edited." + dataFromServer[0].actRes.error_message + "." + this.updToEditImg.tempImgFolder;
      this.tempImgResultPathSafe = this.sanitizer.bypassSecurityTrustStyle("url(" + "/images/people_images/edited/" + this.tempImgResultPath + ")")
    }
    else alert("error in editing.. :(");
 

  }

  inputZoomChange()
  {
    if (this.firstTimeZoom){
      let imgComponent:HTMLImageElement = document.getElementById("imgToEdit") as HTMLImageElement;
      let img_W:number = imgComponent.clientWidth;
      let img_H:number = imgComponent.clientHeight;
      this.imgNatural_W = imgComponent.naturalWidth;
      this.imgNatural_H = imgComponent.naturalHeight;
      
      let outerDivComponent:HTMLElement = document.getElementById("picToEditOuterDiv2");
      let outerWidth:number = outerDivComponent.clientWidth;
  
      this.origImgZoomWidth = outerWidth;

      this.firstTimeZoom = false;
    }
    this.ZoomedImgWidth = Math.round(this.imgZoomRangeVal*this.origImgZoomWidth/100);
    this.ZoomedImgWidth = Math.round(this.imgZoomRangeVal);
  }


  //----------------------MOUSE HANDLING FOR IMG PROCESSING

  ImgMouseDown(event:MouseEvent){
    event.preventDefault();
    this.editStatus = ImgEditStatus.MOVE_IMG;
    this.mouseOnClickLocation = {x:event.clientX, y:event.clientY};
    this.ZoomedImgOnClickTop = this.ZoomedImgTop;
    this.ZoomedImgOnClickRight = this.ZoomedImgRight;
  }
  ImgTouchDown(event:TouchEvent){
    event.preventDefault();
    this.editStatus = ImgEditStatus.MOVE_IMG;
    this.mouseOnClickLocation = {x:event.changedTouches[0].clientX, y:event.changedTouches[0].clientY};
    this.ZoomedImgOnClickTop = this.ZoomedImgTop;
    this.ZoomedImgOnClickRight = this.ZoomedImgRight;
  }


  ImgMouseUp(event:MouseEvent){
    this.editStatus = ImgEditStatus.OFF;
  }
  ImgTouchUp(event:TouchEvent){
    this.editStatus = ImgEditStatus.OFF;
  }

  //----------------------------------------------------

  RedRectMouseDown(event:MouseEvent){
    if (this.editStatus == ImgEditStatus.RESIZE_RECT) return; // if we click on resize rect we will also get to red rect, but we don't intend to move it.
    event.preventDefault();
    this.editStatus = ImgEditStatus.MOVE_RECT;
    this.mouseOnClickLocation = {x:event.clientX, y:event.clientY};
    this.RedRectOnClickTop = this.RedRectTop;
    this.RedRectOnClickRight = this.RedRectRight;
    
  }
  RedRectTouchDown(event:TouchEvent){
    if (this.editStatus == ImgEditStatus.RESIZE_RECT) return; // if we click on resize rect we will also get to red rect, but we don't intend to move it.
    event.preventDefault();
    this.editStatus = ImgEditStatus.MOVE_RECT;
    this.mouseOnClickLocation = {x:event.changedTouches[0].clientX, y:event.changedTouches[0].clientY};
    this.RedRectOnClickTop = this.RedRectTop;
    this.RedRectOnClickRight = this.RedRectTop;
  }


  RedRectMouseUp(event:MouseEvent){
    if (this.editStatus == ImgEditStatus.RESIZE_RECT) return; // if we click on resize rect we will also get to red rect, but we don't intend to move it.
    this.editStatus = ImgEditStatus.OFF;
  }
  RedRectTouchUp(event:TouchEvent){
    if (this.editStatus == ImgEditStatus.RESIZE_RECT) return; // if we click on resize rect we will also get to red rect, but we don't intend to move it.
    this.editStatus = ImgEditStatus.OFF;
  }


  //----------------------------------------------------
  ResizeRectMouseDown(event:MouseEvent){
    event.preventDefault();
    
    this.editStatus = ImgEditStatus.RESIZE_RECT;
    this.mouseOnClickLocation = {x:event.clientX, y:event.clientY};
    this.RedRectOnClickWidth = this.RedRectWidth;
    this.RedRectOnClickHeight = this.RedRectHeight;
  }

  ResizeRectTouchDown(event:TouchEvent){
    event.preventDefault();
    this.editStatus = ImgEditStatus.RESIZE_RECT;
    this.mouseOnClickLocation = {x:event.changedTouches[0].clientX, y:event.changedTouches[0].clientY};
    this.RedRectOnClickWidth = this.RedRectWidth;
    this.RedRectOnClickHeight = this.RedRectHeight;
  }

  ResizeRectMouseUp(event:MouseEvent){
    this.editStatus = ImgEditStatus.OFF;
  }
  ResizeRectTouchUp(event:TouchEvent){
    this.editStatus = ImgEditStatus.OFF;
  }
  //----------------------------------------------------


  @HostListener('window:mousemove',['$event']) onMouseMove(event:MouseEvent){
    this.mouseCurrentLocation = {x:event.clientX, y:event.clientY};
    if (this.editStatus == ImgEditStatus.MOVE_IMG){
      this.ZoomedImgTop = this.ZoomedImgOnClickTop + (this.mouseCurrentLocation.y - this.mouseOnClickLocation.y);
      this.ZoomedImgRight = this.ZoomedImgOnClickRight - (this.mouseCurrentLocation.x - this.mouseOnClickLocation.x); // since it's "right" (and not "left"), we subtruct.
    }
    if (this.editStatus == ImgEditStatus.MOVE_RECT){
      this.RedRectTop = this.RedRectOnClickTop + (this.mouseCurrentLocation.y - this.mouseOnClickLocation.y);
      this.RedRectRight = this.RedRectOnClickRight - (this.mouseCurrentLocation.x - this.mouseOnClickLocation.x); // since it's "right" (and not "left"), we subtruct.
    }
    if (this.editStatus == ImgEditStatus.RESIZE_RECT){
      this.RedRectHeight = this.RedRectOnClickHeight + (this.mouseCurrentLocation.y - this.mouseOnClickLocation.y);
      this.RedRectWidth = this.RedRectOnClickWidth - (this.mouseCurrentLocation.x - this.mouseOnClickLocation.x); 
      if (this.NewImgPolicy==IMGPOLICY.KEEP_ASPECT_RATIO){
        if (this.RedRectHeight > this.RedRectWidth) this.RedRectHeight = this.RedRectWidth;
        else this.RedRectWidth = this.RedRectHeight;
      }
    }
  }

  @HostListener('window:touchmove',['$event']) onTouchMove(event:TouchEvent){
    this.mouseCurrentLocation = {x:event.changedTouches[0].clientX, y:event.changedTouches[0].clientY};
    if (this.editStatus == ImgEditStatus.MOVE_IMG){
      this.ZoomedImgTop = this.ZoomedImgOnClickTop + (this.mouseCurrentLocation.y - this.mouseOnClickLocation.y);
      this.ZoomedImgRight = this.ZoomedImgOnClickRight - (this.mouseCurrentLocation.x - this.mouseOnClickLocation.x); // since it's "right" (and not "left"), we subtruct.
    }
    if (this.editStatus == ImgEditStatus.MOVE_RECT){
      this.RedRectTop = this.RedRectOnClickTop + (this.mouseCurrentLocation.y - this.mouseOnClickLocation.y);
      this.RedRectRight = this.RedRectOnClickRight - (this.mouseCurrentLocation.x - this.mouseOnClickLocation.x); // since it's "right" (and not "left"), we subtruct.
    }
    if (this.editStatus == ImgEditStatus.RESIZE_RECT){
      this.RedRectHeight = this.RedRectOnClickHeight + (this.mouseCurrentLocation.y - this.mouseOnClickLocation.y);
      this.RedRectWidth = this.RedRectOnClickWidth - (this.mouseCurrentLocation.x - this.mouseOnClickLocation.x); 
      if (this.NewImgPolicy==IMGPOLICY.KEEP_ASPECT_RATIO){
        if (this.RedRectHeight > this.RedRectWidth) this.RedRectHeight = this.RedRectWidth;
        else this.RedRectWidth = this.RedRectHeight;
      }
    }
  }



}

class UPD {
  publicId:number;
  firstName:string="";
  email:string="";
  age:number=0;
  gender:string="";
  phone:string="";
  city:string="";
  neighborhood:string="";
  telegram:string="";
  usertext:string="";
  imgLoadedSafe:SafeStyle = null;
  imgLoadedSafeUrl:SafeUrl = null;
  tempImgFolder:string="";
  managerReplyText:string="";
  managerApprove:string = "no";
  engagementBoost:number=10;
  manageIsReply:boolean = false;
  profilePath:string="";
  notifyPref:userNotifyPref;
  resizeParams:ResizeParams = new ResizeParams;
  showDetails:boolean = false;
  serverResult:string="";
}

class ProfileDecision {
  publicId:number;
  profilePath:string="";
  managerReplyText:string="";
  managerApprove:string = "no";
  engagementBoost:number=10;
  resizeParams:ResizeParams = new ResizeParams;
}

class ResizeParams{
  imgFilePath: string;
  imgNewFilePolicy: string;
  cropLeftPrecent: number;
  cropTopPrecent: number;
  cropWidthPrecent: number;
  cropHeightPrecent: number;
  rotateFlip : string;
}

class personInitiatedConvs {
  convs : conversationsStatus[]=[];
  publicID: number=0;
  name: string="";
  age: number=0;
  imgPath:string ="";
  imgLoadedSafe:SafeStyle = null;
  timeLastMessage:Date;
  showContent: boolean = false;
}

