
import {ViewPeopleComponent} from './view-people/view-people.component';
import { FormatHttpGetResponse, conversationsStatus,LocEvent, RelationshipRecord } from './main-page/formatHttpGetResponse';

export class PersonInfo {
    parent: ViewPeopleComponent;
    publicId: number;
    firstName: string;
    lastName: string;
    age: number;
    imgFile:string;
    imgPath:string;
    neighborhood: string="";
    city: string="";
    characteristics: characteristic[];
    usertext: string;
    isAllowed: boolean;
    onlineStatus: string;
    hasConv:boolean = false;
    convWithPerson: conversationsStatus;
    isExample:boolean;
    loceventsMatch:LocEvent[];
    isEventMatch:boolean;
    isLocMatch:boolean;
    relRecord: RelationshipRecord;

    

    constructor({parent,publicId=0,firstName,lastName,age,imgFile,isAllowed,onlineStatus, neighborhood="",city="",characteristics=null, usertext, relRecord=null}){
        this.parent=parent;
        this.isAllowed=false;
        this.publicId=publicId;
        this.firstName=firstName;
        this.lastName=lastName;
        this.age = age;
        this.imgFile = imgFile;
        this.isAllowed = isAllowed;
        this.onlineStatus = onlineStatus;
        this.neighborhood = neighborhood;
        this.city = city;
        this.characteristics = characteristics;
        this.usertext = usertext;
        //this.imgPath = "/images/people_images/" + imgFile;
        this.updateImgPath();
        this.isEventMatch = false;
        this.isLocMatch = false;
        this.relRecord = relRecord;
      }

      updateImgPath(){
        this.imgPath = "/images/people_images/" + this.imgFile;
      }

}

export class characteristic {
    character: string;
    valType: string;           //can be: "selection"/"freeText"/"number"/"bool"
    textVal: string;
    numberVal: number;
    boolVal: boolean;

}