import { ChangeDetectorRef, Component, Input, OnInit, Output, ViewChild } from '@angular/core';
import { PersonInfo} from '../person-info';
import { delay } from 'q';
import { HttpClient, HttpParams, HttpHeaders, HttpEventType } from '@angular/common/http';
import { FormatHttpGetResponse, userProfileDetails, HttpGeneralResponse,characteristic,conversationsStatus, userNotifyPref} from '../main-page/formatHttpGetResponse';
import { Observable } from 'rxjs';
import { getParseErrors } from '@angular/compiler';
import { EventEmitter } from 'events';
import { DomSanitizer, SafeUrl, SafeStyle } from '@angular/platform-browser';
import { setMaxListeners } from 'process';

import { DynamicFormComponent } from '../dynamic-form/dynamic-form.component';
import { EDIT_PROFILE_SECTIONS, NEIGHBORHOOD_OPTIONS } from './edit-profile-form-sections';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { CookieService, SessionData } from '../services/cookie.service';
import { FindFriendsComponent } from '../find-friends/find-friends.component';
import { ProfileViewComponent } from '../profile-view/profile-view.component';


export enum ProfileState {
  BasicInfo = 'basicInfo',
  ContactInfo = 'contactInfo',
  VerifyPhone = 'verifyPhone',
  ImageAndMore = 'imageAndMore',
  ProfileSaved = 'profileSaved'
}

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.css']
})
export class EditProfileComponent implements OnInit {
  @Input() parent: FindFriendsComponent;
  ProfileState = ProfileState; // Expose the enum to the template
  profileState: ProfileState = ProfileState.BasicInfo;
  formButtonText: string = '';
  headerText: string ='';
  allFormSections = EDIT_PROFILE_SECTIONS;
  userForm: FormGroup;
  profileRequestsInFlight: boolean = false;

  //session
  sessionData: SessionData | undefined;

  public message:string;
  public progress:number = 0;
  public imgLoadedSafe:SafeStyle | null = null;

  public isLoggedIn:boolean = false;
  

  public firstName:string ;
  public email:string;
  public password:string;
  public tempImgFolder:string;
  public age:number;
  public gender:string;
  public phone:string;
  public city:string="";
  public neighborhood:string="";
  public telegram:string;
  public usertext:string;
  public purpose_opt_1:boolean = false;
  public purpose_opt_2:boolean = false;
  public purpose_opt_3:boolean = false;
  public purpose_opt_4:boolean = false;

  public notifysms:boolean = true;
  public notifymail:boolean = true;
  public notifyLimit:boolean = false;
  public notifyLimitVal:number;
  
  public agreeUserTerms:boolean = false;

  
  isShowLoad:boolean = false;
  isShowSend:boolean = true;
  hasErrMsg:boolean = false;
  hasSucMsg:boolean = false;
  errMsg:string = "הודעת שגיאה לדוגמה";
  sucMsg:string = "הודעת הצלחה לדוגמה";


  formErrMsg = '';
  hasFormErrMsg = false;

  navBarLoggedIn:string = "undefined";

  userSpan:string = "none";

  apiValues: string[] = [];
  @Output() public onUploadFinished = new EventEmitter();

  constructor(private _http: HttpClient, private sanitizer:DomSanitizer, private router: Router, private cookieService: CookieService,) { }

  ngOnInit() {
    this.sessionData = this.cookieService.getSessionData();

    if (this.sessionData.userType=="registered") {
      this.isLoggedIn = true;
      this.navBarLoggedIn = 'yes';
    }

    if (this.isLoggedIn){
      this.getProfileDetailsFromServer(); //get profile data. 
    }
    document.body.style.overflow="auto";
    

    this.updateFormSections();
    this.updateFormTexts();
  }

  updateFormSections() {
    this.allFormSections.forEach(section => {
      section.isVisible = section.name === this.profileState;
    });
  }

  

  updateFormTexts() {
    this.hasFormErrMsg = false;
    switch (this.profileState) {
      case ProfileState.BasicInfo:
        this.headerText = 'מצטרפים? אחלה! בואו נתחיל...';
        this.formButtonText = 'המשיכו לטקסט חופשי ותמונה';
        break;
      case ProfileState.ImageAndMore:
        this.headerText = 'אז מי אתם בעצם?';
        this.formButtonText = 'המשיכו לפרטי התחברות';
        break;
      case ProfileState.ContactInfo:
        this.headerText = 'יש לנו את זה! כמעט סיימנו...';
        this.formButtonText = 'לבדיקה וסיום';
        break;
      case ProfileState.VerifyPhone:
        this.headerText = 'רק מוודאים שהמספר נכון :)';
        this.formButtonText = 'כפתור אחרון ללחוץ עליו!';
        break;
      case ProfileState.ProfileSaved:
        this.headerText = 'אחלה! הפרופיל נשמר בהצלחה :)';
        this.formButtonText = 'ועכשיו, להכיר חברים חדשים!';
        break;
      default:
        this.headerText = '';
        this.formButtonText = 'המשך';
        break;
    }
    // override in case of logged in user
    if (this.isLoggedIn){
      this.headerText = 'עדכנו פרטי פרופיל'; 
    }

    
  }


  handleBackwardsButton() {
    this.parent.logActivity("Edit profile backwards click", "click");
    switch (this.profileState) {
      case ProfileState.BasicInfo:
        
        break;
      case ProfileState.ImageAndMore:
        this.profileState = ProfileState.BasicInfo;
        break;
      case ProfileState.ContactInfo:
        this.profileState = ProfileState.ImageAndMore;
        break;
      case ProfileState.VerifyPhone:
        this.profileState = ProfileState.ContactInfo;
        break;
    }
    this.updateFormSections(); // Refresh the visible form section
    this.updateFormTexts(); // Update the button text
  }

  handleProfileButtonClick() {
    this.parent.logActivity("Edit profile next click", "click");
    
    if (this.profileState==ProfileState.ProfileSaved){
      //this.router.navigate(['/findfriends']); 
      if (this.parent.selectedProfile != null){
        this.parent.showProfile(this.parent.selectedProfile)
      }
      else{
        window.location.reload();
      }
      return;
    }

    if (this.validateCurrentSection()) 
    {
      // If no errors, clear the error message and proceed
      this.formErrMsg = '';
      this.hasFormErrMsg = false;
    }
    else {
      return; // If the section is invalid, don't proceed
    }
 
    // Handle the button logic based on the current state
    switch (this.profileState) {
      case ProfileState.BasicInfo:
        this.profileState = ProfileState.ImageAndMore;
        break;
      case ProfileState.ImageAndMore:
        this.profileState = ProfileState.ContactInfo;
        break;
      case ProfileState.ContactInfo:
        if(this.isLoggedIn) {
          this.request_initcode();
        }
        else{
          this.checkPhoneAndEmailAndSendInitCode();
        }
        break;
      case ProfileState.VerifyPhone:
        this.proceedToCreateProfile();
        break;
    }
    this.updateFormSections(); // Refresh the visible form section
    this.updateFormTexts(); // Update the button text
  }

  validateCurrentSection(): boolean {
    // Get the currently visible section
    const currentSection = this.allFormSections.find(section => section.isVisible);
  
    if (currentSection) {
      const sectionGroup = this.userForm.get(currentSection.name) as FormGroup;
  
      let hasError = false;
  
      for (const field of currentSection.fields) {
        const control = sectionGroup.get(field.name);
  
        if (control) {
          control.markAsTouched();
  
          if (field.type === 'file') {
            if (!this.imgLoadedSafe && !hasError) {
              this.formErrMsg = `חסרה תמונה :) זה חשוב!`;
              this.hasFormErrMsg = true;
              hasError = true;
            }
          } else if (field.type === 'checkbox') {
            // Check if the field has the 'forceCheckTrue' validator
            const requiresCheckTrue = field.validators && field.validators.includes('forceCheckTrue');
            if (requiresCheckTrue && !control.value && !hasError) {
                this.formErrMsg = `יש לאשר את תנאי השימוש להמשך`;
                this.hasFormErrMsg = true;
                hasError = true;
            }
          } else if (control.invalid && !hasError) {
            this.formErrMsg = `חסרים פרטים :) השלימו ונמשיך`;
            this.hasFormErrMsg = true;
            hasError = true;
          }
        }
      }
  
      return !hasError; // Return true if no errors, false otherwise
    }
  
    return false; // Return false if no section is visible (though this should ideally not happen)
  }

  cancelImageLoad() {
    // Logic to cancel the image load
    this.imgLoadedSafe = null;
    this.tempImgFolder=""; 
    this.progress = 0;
    this.message = '';
  }

  async proceedToCreateProfile(){
    let userProfileDetails : Partial<userProfileDetails> =  {
      
      firstName: this.userForm.get("basicInfo.firstName").value,
      email: this.userForm.get("contactInfo.email").value,
      password: this.userForm.get("contactInfo.password").value,

      tempImgFolder:this.tempImgFolder,

      age: this.userForm.get("basicInfo.age").value,
      gender: this.userForm.get("basicInfo.gender").value,
      phone: this.userForm.get("contactInfo.phoneNumber").value,
      city: this.userForm.get("basicInfo.city").value,
      neighborhood: this.userForm.get("basicInfo.neighborhood").value,
      usertext: this.userForm.get("imageAndMore.about").value,

      notifyPref: {
        mailNewMsg: false,
        smsNewMsg: true,
      } as Partial<userNotifyPref> as userNotifyPref,

      agreeUserTerms: this.userForm.get("contactInfo.agreeUserTerms").value
    };

    await this.CreateOrEditProfile(userProfileDetails);
  }

  onSelectChange(fieldName: string, selectedValue: string) {
    switch (fieldName) {
      case 'city':
        this.updateNeighborhoodOptions(selectedValue);
        break;
      // Add more cases if other select fields require special handling
      default:
        // Handle generic cases or do nothing if no special handling is needed
        break;
    }
  }

  updateNeighborhoodOptions(selectedCity: string) {
    const neighborhoodField = this.userForm.get("basicInfo.neighborhood");
  
    // Reset the neighborhood field value if necessary
    neighborhoodField.patchValue(null); // This clears the value to ensure the user selects a new option
  
    // Update the options for the neighborhood field
    this.allFormSections.forEach(section => {
      if (section.name === 'basicInfo') {
        const neighborhoodControl = section.fields.find(field => field.name === 'neighborhood');
        if (neighborhoodControl) {
          neighborhoodControl.options = NEIGHBORHOOD_OPTIONS[selectedCity] || [];
        }
      }
    });
  }
    ////////////////////////////// SERVER REQUESTS //////////////////////////////////////////////////////

  public async getProfileDetailsFromServer(){
    let postBody = {
      "postType" : 9,
    }
      
      
      let headers = new HttpHeaders()
        .set('Content-Type','application/json');
      let postObservable:Observable<FormatHttpGetResponse[]> = this._http.post<FormatHttpGetResponse[]>('/api/ClientMsg',postBody,{headers}); 
      postObservable.subscribe(
        dataFromServer => { this.onServerGetProfileDetails(dataFromServer);},
        error => this.apiValues = ['error on http in checkServerMsg','error found']
      )
  }

  //-----------------
  // learned from:
  // youtube.com/watch?v=v67Nunlp5w8&t=470s
  // "Uploading Files with ASP.NET Core and Angular"
  // -------------------------------------------------
  public uploadFile = (files) => {
    
    if (files.length === 0)
      return;
      
      this.imgLoadedSafe = null;
      let fileToUpload = <File>files[0];
      const formData = new FormData();
      formData.append('file', fileToUpload, fileToUpload.name);

      this._http.post<object>('/api/UploadImg', formData, { reportProgress: true, observe: 'events'})
      .subscribe (event => {
        if (event.type === HttpEventType.UploadProgress){
          this.progress = Math.round(100* event.loaded / event.total)
        }
        else if (event.type === HttpEventType.Response){
          this.message = "upload done";
          this.onUploadFinished.emit(event.body.toString())
          setTimeout(() => {
            this.onServerImgLoadSuccess(event.body);
          }, 100); // 100-milisecond delay

        }
      })

  }

  


  async CreateOrEditProfile(userProfileDetails?: Partial<userProfileDetails>){
    
    this.isShowLoad = true;
    let isNewCard = true;
    let postBody = {
      "postType" : 6,
      "messageType" : "",
      "userProfileDetails" : userProfileDetails || {
        firstName : this.firstName,
        email:this.email,
        password:this.password,
        tempImgFolder:this.tempImgFolder,
        age:this.age,
        gender:this.gender,
        phone:this.phone,
        city:this.city,
        neighborhood:this.neighborhood,
        telegram:this.telegram,
        usertext:this.usertext,
        purposeopt:[this.purpose_opt_1,this.purpose_opt_2,this.purpose_opt_3,this.purpose_opt_4],
        notifyPref:{
          mailNewMsg : this.notifymail,
          smsNewMsg : this.notifysms,
          mailMaxFreqDays : this.notifyLimit?this.notifyLimitVal:0
        },
        agreeUserTerms:this.agreeUserTerms
      }
      
    }

    if (this.isLoggedIn) {
      isNewCard = false;
      postBody.postType=7;
      postBody.messageType = "update_" + this.userForm.get("verifyPhone.verificationCode").value;
   }
    else {
      postBody.messageType = "newpro_" + this.userForm.get("verifyPhone.verificationCode").value;
    }
      
      
      let headers = new HttpHeaders()
        .set('Content-Type','application/json');
      let postObservable:Observable<FormatHttpGetResponse[]> = this._http.post<FormatHttpGetResponse[]>('/api/ClientMsg',postBody,{headers}); 
      postObservable.subscribe(
        dataFromServer => { this.onServerCreateOrEditProfile(dataFromServer,isNewCard);},
        error => this.apiValues = ['error on http in checkServerMsg','error found']
      )
      //await delay(1000);
      //let convStatModalBtn = document.getElementById("btnModalConvStat");
      //convStatModalBtn.click();
    
  }


  request_initcode(){
    if (this.profileRequestsInFlight) return;
    
    this.isShowLoad = true;
    this.profileRequestsInFlight = true;

    let phoneValue: string = this.userForm.get("contactInfo.phoneNumber").value;

    let postBody = {
      "postType" : 13,
      "messageType" : "phoneNumApproval",
      "userProfileDetails" : {
        phone:phoneValue,
      }
    }
    let headers = new HttpHeaders()
      .set('Content-Type','application/json');
    let postObservable:Observable<FormatHttpGetResponse[]> = this._http.post<FormatHttpGetResponse[]>('/api/ClientMsg',postBody,{headers}); 
    postObservable.subscribe(
      dataFromServer => { this.onServerReply_RequestInitCode(dataFromServer);},
      error => this.apiValues = ['error on http in checkServerMsg','error found']
    )
  }


  async checkPhoneAndEmailAndSendInitCode(){
    if (this.profileRequestsInFlight) return;
    
    this.isShowLoad = true;
    this.profileRequestsInFlight = true;
  
    let phoneValue: string = this.userForm.get("contactInfo.phoneNumber").value;
    let emailValue: string = this.userForm.get("contactInfo.email").value;
  
    let postBody = {
      "postType": 13,
      "messageType": "checkPhoneEmailAndSendInitCode",
      "userProfileDetails": {
        phone: phoneValue,
        email: emailValue
      }
    };
  
    let headers = new HttpHeaders().set('Content-Type','application/json');
    let postObservable: Observable<FormatHttpGetResponse[]> = this._http.post<FormatHttpGetResponse[]>('/api/ClientMsg', postBody, { headers }); 
    postObservable.subscribe(
      dataFromServer => { this.onServerReply_CheckPhoneAndEmail(dataFromServer); },
      error => { this.apiValues = ['error on http in checkServerMsg','error found']; }
    );
  }

    ////////////////////////////// SERVER RESPONSES //////////////////////////////////////////////////////
  onServerImgLoadSuccess(dataFromServer:object){
    let a = dataFromServer;
    if ((<imgLoadResponse>a).success){
      let folder = "/images/loaded_images/" + (<imgLoadResponse>a).folder + "/" + (<imgLoadResponse>a).file;
      this.imgLoadedSafe = this.sanitizer.bypassSecurityTrustStyle("url("+folder+")");
      this.tempImgFolder = (<imgLoadResponse>a).folder;
      this.hasErrMsg=false;
    }
    else{
      this.hasErrMsg=true;
      this.errMsg = (<imgLoadResponse>a).error_message;
    }
  }

  async onServerCreateOrEditProfile(dataFromServer:FormatHttpGetResponse[], isNewCard:boolean){
    this.isShowLoad = false;
    if (dataFromServer== null || dataFromServer.length==0) {
      this.hasFormErrMsg = true;
      this.hasSucMsg = false;
      this.formErrMsg = "ארעה שגיאה, נסו שוב מאוחר יותר";
      this.parent.logActivity("Error edit profile", "serverError");
      return;
    }
    
    const fileInput = document.getElementById('userImgFile') as HTMLInputElement;
    if (fileInput) {
      fileInput.value = ''; // This clears the selected file
    }

    
    if (!dataFromServer[0].actRes.success){
      this.hasFormErrMsg = true;
      this.hasSucMsg = false;
      this.formErrMsg = dataFromServer[0].actRes.error_message;
      this.parent.logActivity("Error edit profile", "serverError");
      return;
    }
    else{
      this.hasFormErrMsg = false;
      this.hasSucMsg = true;
      this.profileState = ProfileState.ProfileSaved;
      this.updateFormTexts(); 
      this.parent.logActivity("Updated profile", "serverResponse");
      if (isNewCard){
        
        this.sessionData = this.cookieService.getSessionData();
        if (this.sessionData.userType=="registered") {
          this.parent.navBarLoggedIn = 'yes';
          this.parent.navBarUserMessage = 'הי ' + this.sessionData.userFirstName + '!';
          this.parent.bottomBarLoggedIn = true;
        }

        this.parent.onServerReply_updateSingleNext(dataFromServer, new ProfileViewComponent());
      }
    }


    }

  async onServerGetProfileDetails(dataFromServer:FormatHttpGetResponse[]){
    if (dataFromServer== null || dataFromServer.length==0) {
      this.navBarLoggedIn = "no";
      this.parent.logActivity("Error get profile details", "serverError");
      return;
    }
    if (!dataFromServer[0].actRes.success){
      this.hasErrMsg = true;
      this.errMsg = dataFromServer[0].actRes.error_message;
      this.navBarLoggedIn = "no";
      this.parent.logActivity("Error get profile details", "serverError");
      return;
    }



    this.hasErrMsg = false;
    let profileFromServ:userProfileDetails = dataFromServer[0].upd;
    if (dataFromServer != null && profileFromServ.firstName){
      this.firstName = profileFromServ.firstName,

      // update UI elements
      this.isLoggedIn = true;
      this.navBarLoggedIn = "yes";

      this.headerText = 'עדכנו פרטי פרופיל'; 

      this.allFormSections.forEach(section => {
        if (this.isLoggedIn) {
          section.fields = section.fields.filter(field => field.name !== 'email' && field.name !== 'password');
        }
      });

      // set profile span - for old users that might need to update profile
      if (dataFromServer[0].profileSpan != null ){
        if (dataFromServer[0].profileSpan.includes("talker") && !dataFromServer[0].profileSpan.includes("friends") ){
          this.userSpan="talker"; 
          // open modal - no more request talk, you can join the site by adding details
        }
        if (dataFromServer[0].profileSpan.includes("listener") && !dataFromServer[0].profileSpan.includes("friends")){
          this.userSpan = "listener"; 
          // open modal - no more request talk, you can join the site by adding details
        }
      }



      // Update the form with the profile details
      const matchedCity = NEIGHBORHOOD_OPTIONS[profileFromServ.city] ? profileFromServ.city : 'אחר';
      this.updateNeighborhoodOptions(matchedCity);
      const matchedNeighborhood = NEIGHBORHOOD_OPTIONS[matchedCity] ? 
            NEIGHBORHOOD_OPTIONS[matchedCity].find(option => option.value === profileFromServ.neighborhood) ? 
                    profileFromServ.neighborhood : 'אחר'
            : 'אחר';

      this.userForm.patchValue({
        basicInfo: {
          firstName: profileFromServ.firstName || '',
          age: profileFromServ.age !== 0 ? profileFromServ.age : null,
          city: matchedCity,
          neighborhood: matchedNeighborhood,
          gender: profileFromServ.gender || ''
        },
        contactInfo: {
          phoneNumber: profileFromServ.phone || '',
        },
        imageAndMore: {
          about: profileFromServ.usertext || '',
          // Assuming you'll manage the image upload/display elsewhere
        },
        verifyPhone: {
          verificationCode: '' // This will be entered by the user during verification
        }
      });

      
      // Handle the image loading
      let folder = "/images/people_images/" + profileFromServ.tempImgFolder;
      this.imgLoadedSafe = this.sanitizer.bypassSecurityTrustStyle("url(" + folder + ")");

      this.parent.logActivity("Got profile details", "serverResponse");

    }
    else{
      this.isLoggedIn = false;
      this.navBarLoggedIn = "no";
    }
    
    

  }

  onServerReply_RequestInitCode(dataFromServer:FormatHttpGetResponse[]){
    this.profileRequestsInFlight = false;
    this.isShowLoad = false;
    if (dataFromServer== null || dataFromServer.length==0) {
      this.formErrMsg = 'ארעה שגיאה, כדאי לנסות שוב';
      this.hasFormErrMsg = true;
      return;
    }
    if (!dataFromServer[0].actRes.success){
      this.formErrMsg = dataFromServer[0].actRes.error_message;
      this.hasFormErrMsg = true;
      return;
    }

    this.profileState = ProfileState.VerifyPhone;
    this.updateFormSections(); // Refresh the visible form section
    this.updateFormTexts(); // Update the button text

  }

  onServerReply_CheckPhoneAndEmail(dataFromServer: FormatHttpGetResponse[]){
    this.profileRequestsInFlight = false;
    this.isShowLoad = false;
    if (!dataFromServer || dataFromServer.length == 0) {
      this.formErrMsg = 'ארעה שגיאה, כדאי לנסות שוב';
      this.hasFormErrMsg = true;
      return;
    }

    if (!dataFromServer[0].actRes.success){
      this.formErrMsg = dataFromServer[0].actRes.error_message;
      this.hasFormErrMsg = true;
      return;
    }
        
    this.profileState = ProfileState.VerifyPhone;
    this.updateFormSections(); // Refresh the visible form section
    this.updateFormTexts(); // Update the button text

  }

}


     ////////////////////////////// OTHER CLASS DEFINITIONS //////////////////////////////////////////////////////


class imgLoadResponse {
  success:boolean;
  error_message:string;
  folder:string;
  file:string;
}


