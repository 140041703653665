import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SessionData, CookieService } from '../services/cookie.service';


@Component({
  selector: 'app-welcome-page',
  templateUrl: './welcome-page.component.html',
  styleUrls: ['./welcome-page.component.css']
})
export class WelcomePageComponent implements OnInit {
  
  isLoggedIn:boolean = false;
  isShowLanding:boolean = true;
  isShowLoad:boolean = false;
  navBarLoggedIn:string = "no";

  sessionData: SessionData | undefined;

  
  constructor(private cookieService: CookieService, private router: Router) { }

  ngOnInit() {

    // Access the cookies set by the server
    this.sessionData = this.cookieService.getSessionData();

    if (this.sessionData.userType=="registered"){
      this.router.navigate(['/findfriends'])
    }
  
  }

  showMainPage(){
    document.getElementById("welcome-part-id").style.display="block";
    this.isShowLoad = false;
  }

 

}
