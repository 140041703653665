import { Component, Input, OnInit } from '@angular/core';
import { FindFriendsComponent } from '../find-friends/find-friends.component';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { delay } from 'q';
import { Observable } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { FormatHttpGetResponse, RelationshipRecord } from '../main-page/formatHttpGetResponse';
import { PersonInfo } from '../person-info';
import { Profile } from '../the-six/the-six.component';

@Component({
  selector: 'app-profiles-likes',
  templateUrl: './profiles-likes.component.html',
  styleUrls: ['./profiles-likes.component.css']
})
export class ProfilesLikesComponent implements OnInit {

  @Input() parent: FindFriendsComponent;
  @Input() profiles: Profile[] = [];



  // Database
  thumbInfoList: PersonInfo[]=[]; 

  private logList: { key: string, value: string }[] = [];

  constructor() { }

  ngOnInit() {
 
  }

  showProfile(profile: Profile) {
    this.parent.showProfile(profile);
  }


  // Method to return cached image URL or original URL if not cached
  getCachedImage(url: string): string {

    const cachedValue = this.parent.imageCache[url] && this.parent.imageCache[url].src ? this.parent.imageCache[url].src : 'Not Cached';

    if (!this.logList.find(log => log.key === url && log.value === cachedValue)) {
      this.logList.push({ key: url, value: cachedValue });
      //console.log('likes - Key:', url);
      //console.log('likes - Value:', cachedValue);
    }
  
    return this.parent.imageCache[url] && this.parent.imageCache[url].src ? this.parent.imageCache[url].src : url;
    
    
  }

  // Handle image load and cache the image
  onImageLoad(profile: Profile, event: Event): void {
    profile.isImgLoaded = true;
    
    // Cache the image if it's not already cached
    //const imgElement = event.target as HTMLImageElement;
    const imgElement = (event.target as HTMLImageElement).cloneNode() as HTMLImageElement; // Clone the element


    if (!this.parent.imageCache[profile.imageUrl]) {
      this.parent.imageCache[profile.imageUrl] = imgElement;
      
      if (!this.logList.find(log => log.key === profile.imageUrl && log.value === imgElement.src)) {
        this.logList.push({ key: profile.imageUrl, value: imgElement.src });
        //console.log('likes - Added to Cache - Key:', profile.imageUrl);
        //console.log('likes - Added to Cache - Value:', imgElement.src);
      }

    }
  }

}
