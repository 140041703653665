import { Component, OnInit, Input } from '@angular/core';
import { conversationsStatus} from '../main-page/formatHttpGetResponse';
import { DomSanitizer, SafeUrl, SafeStyle } from '@angular/platform-browser';
import { delay } from 'q';


@Component({
  selector: 'app-conv-stat',
  templateUrl: './conv-stat.component.html',
  styleUrls: ['./conv-stat.component.css']
})
export class ConvStatComponent implements OnInit {
  @Input() convStatInp : conversationsStatus;
  @Input() DomIdPrefix : string;
  
  msg_format:string=""; //can be: userSentPing, userSentCom, userSentTxt, userGotPing, userSentCom, userSentTxt

  userGender:string="female"; //can be: male, female
  otherGender:string=""; //can be: male, female
  otherOnlineStatus:string="";

  userSentInfo:string=""; // for example "שלחת אליה פינג"
  userSentContent:string=""; 
  otherSentInfo:string=""; //for example "שלחה טלפון"
  otherSentContent:string=""; //for example "054-987-6543"
  
  howToRespond:string=""; //for example "איך תרצה להגיב?";
  showMsgs:string=""; // for example "הצגי את כל ההודעות";
  hideMsgs:string=""; // for example "הסתירי את כל ההודעות";
  openAsChat:string=""; // 
  showOrHideMsgs:string=""; // for picking one of the above
  

  timeleftString:string="";

  resizable_div_id:string=""
  allMsgButton_div_id:string="";
  allMsgBox_div_id:string=""
  textEnteredByUser:string ="";

  hasErrMsg:boolean = false;
  errMsg:string = "הודעת שגיאה לדוגמה";

  isShowTextExtra:boolean = false;
  isShowPhoneExtra:boolean = false;
  isShowPingExtra:boolean = false;
  isShowDeleteExtra:boolean = false;
  isShowTelegramExtra:boolean = false;
  isAllowShowAllMsg:boolean = false;
  isChatAllowed: boolean = false;
  isShowAllMsg:boolean = false;
  
  isHighlightYellow:boolean = false;
  hasNewWatch:boolean = false;

  pingExtraHeight:number = 45;
  phoneExtraHeight:number = 45;
  textExtraHeight:number = 60;
  telegramExtraHeight:number = 45;
  deleteExtraHeight:number = 0;
  allmsgExtraHeight:number = 150;




  constructor(private sanitizer:DomSanitizer) 
  {
    
  }

  ngOnInit() {
    this.convStatInp.parent.inFocusPersonController=this;
    this.convStatInp.parent.convStatComponentList.push(this);
    this.isChatAllowed = this.convStatInp.parent.isChatAllowed;
    this.convStatInp.otherPersonPicSafe = this.sanitizer.bypassSecurityTrustStyle("url("+this.convStatInp.otherPersonPic+")");
    let d = new Date();
    let timeNow = d.getTime();
    let b= new Date(this.convStatInp.timeLastMessage)
    let c = b.getTime();
    let timeleftMS = 10*60*1000-(timeNow-c);
    let timeleftSeconds = Math.floor((timeleftMS/1000) % 60);
    let timeleftMinutes = Math.floor((timeleftMS/60000) % 60);
    let minutes:string = (timeleftMinutes>0) ? String(timeleftMinutes) : "0";
    let seconds:string = (timeleftSeconds>0) ? (timeleftSeconds>9)? String(timeleftSeconds) :"0"+String(timeleftSeconds) : "00";
    this.timeleftString = minutes + ":" + seconds;

    this.userGender = this.convStatInp.userGender;

    if (this.userGender=="female") {
      this.howToRespond="איך תרצי להגיב?";
      this.showMsgs="הציגי את כל ההודעות";
      this.hideMsgs="הסתירי את כל ההודעות";
      this.openAsChat="פתחי בחלון צ'אט";
    }
    if (this.userGender=="male") {
      this.howToRespond="איך תרצה להגיב?";
      this.showMsgs="הצג את כל ההודעות";
      this.hideMsgs="הסתר את כל ההודעות";
      this.openAsChat="פתח בחלון צ'אט";
    }
    this.showOrHideMsgs = this.showMsgs;
    this.otherGender=this.convStatInp.otherPersonGender;
    this.otherOnlineStatus = this.convStatInp.otherPersonOnlineStatus;
    this.resizable_div_id=this.DomIdPrefix + this.convStatInp.initiationID;
    this.allMsgButton_div_id=this.DomIdPrefix + this.convStatInp.initiationID + "allMsgButton";
    this.allMsgBox_div_id=this.DomIdPrefix + this.convStatInp.initiationID + "allMsgBox";
    
    if (this.convStatInp.msgLogList.length >0 ) this.isAllowShowAllMsg = true;
    this.getMsgFormat();
    this.prepareAllMsg();

    if (this.convStatInp.msgLogList.length > 0 && this.convStatInp.msgLogList[this.convStatInp.msgLogList.length-1].isNewWatch) this.hasNewWatch = true;
    if (this.hasNewWatch) this.isHighlightYellow = true;

    
  }

  

  public async addHighlight(){
    await delay(10);
    this.isHighlightYellow = true;
    let divToExtend = document.getElementById(this.resizable_div_id);
    let modalDiv = document.getElementById("ModalConvStat");
    divToExtend.scrollIntoView({behavior:'smooth',block:'center'});


    this.removeHighlight();
  }
  public async  removeHighlight(){
      await delay(3000);
      this.isHighlightYellow = false;
      this.convStatInp.highlight="";

  }

  ngOnChanges(){
    
    let a = "stophere";
    //doesnt work, since we don't get into this function when the input change :(
    /*if (this.convStatInp.isShowRedNotify==true && this.isShowAllMsg && this.closedOnceAllMsgOnRed==false)
    {
      this.closedOnceAllMsgOnRed = true
      this.allMsgClicked();
    }*/
  }

  ngDoCheck(){
    let a = "stophere";
  }

  ngAfterContentInit(){
    let a = "stophere";
  }

  ngAfterContentChecked(){
    let a = "stophere";
  }

  ngAfterViewInit(){
    if (this.convStatInp.highlight=="yellow"){
     // this.isHighlightYellow = true;
     // let divToExtend = document.getElementById(this.resizable_div_id);
     // let modalDiv = document.getElementById("ModalConvStat");
     // divToExtend.scrollIntoView({behavior:'smooth',block:'center'});
      this.addHighlight();
    }
  }



  //-------------- PING

  pingClicked(){
    if (this.isShowPingExtra==false){
      //close ohters
      this.PhoneCloseIfOpen();
      this.TextCloseIfOpen();
      this.TelegramCloseIfOpen();
      //open me
      this.isShowPingExtra=true;
      this.extraHeightToggle("145px",this.pingExtraHeight,this.pingExtraHeight);
    }
    else{
      this.PingCancelClicked();
    }
  }

  public PingCancelClicked(){
    this.isShowPingExtra=false;
     this.extraHeightToggle("100px",-this.pingExtraHeight,-this.pingExtraHeight);
   }
   public PingCloseIfOpen(){
    if (this.isShowPingExtra) this.PingCancelClicked();
  }
  public async PingSendClicked(){
    this.sendReqInParent("ping");
  }


  //-------------- PHONE
  phoneClicked(){
    if (this.isShowPhoneExtra==false){
      //close ohters
      this.PingCloseIfOpen();
      this.TextCloseIfOpen();
      this.TelegramCloseIfOpen();
      //open me
      this.isShowPhoneExtra=true;
      this.extraHeightToggle("145px",this.phoneExtraHeight,this.phoneExtraHeight);
    }
    else{
      this.PhoneCancelClicked();
    }
  }
  public PhoneCancelClicked(){
    this.isShowPhoneExtra=false;
     this.extraHeightToggle("100px",-this.phoneExtraHeight,-this.phoneExtraHeight);
   }
  public PhoneCloseIfOpen(){
    if (this.isShowPhoneExtra) this.PhoneCancelClicked();
  }

   public async PhoneSendClicked(){
    this.sendReqInParent("phone");
   }

  //-------------- TEXT
  textClicked(){
    if (this.isShowTextExtra==false){
      //close ohters
      this.PingCloseIfOpen();
      this.PhoneCloseIfOpen();
      this.TelegramCloseIfOpen();
      //open me
      this.isShowTextExtra=true;
      this.extraHeightToggle("250px",this.textExtraHeight,this.textExtraHeight);
    }
    else{
      this.TextCancelClicked();
    }
  }
  public TextCancelClicked(){
    this.isShowTextExtra=false;
     this.extraHeightToggle("100px",-this.textExtraHeight,-this.textExtraHeight);
   }
   public TextCloseIfOpen(){
    if (this.isShowTextExtra) this.TextCancelClicked();
  }

   public async TextSendClicked(){
    this.sendReqInParent("text",this.textEnteredByUser);
   }

  //-------------- TELEGRAM

  telegramClicked(){
    if (this.isShowTelegramExtra==false){
      //close ohters
      this.PhoneCloseIfOpen();
      this.TextCloseIfOpen();
      //open me
      this.isShowTelegramExtra=true;
      this.extraHeightToggle("145px",this.telegramExtraHeight,this.telegramExtraHeight);
    }
    else{
      this.TelegramCancelClicked();
    }
    //this.sendReqInParent("telegram");
  }
  public TelegramCancelClicked(){
    this.isShowTelegramExtra=false;
     this.extraHeightToggle("100px",-this.telegramExtraHeight,-this.telegramExtraHeight);
   }
   public TelegramCloseIfOpen(){
    if (this.isShowTelegramExtra) this.TelegramCancelClicked();
  }
   public async TelegramSendClicked(){
    this.sendReqInParent("telegram");
   }

   //----------------- IMG
  imgClicked(){
    this.convStatInp.parent.showPersonProfile(this.convStatInp.otherPersonPublicId);
  }
  headerClicked(){
    this.convStatInp.parent.showPersonProfile(this.convStatInp.otherPersonPublicId);
  }

   //------------------ ALL MSG
   allMsgClicked(){
    if (this.isShowAllMsg==false){
      this.isShowAllMsg=true;
      this.showOrHideMsgs = this.hideMsgs;
      this.extraHeightToggle("270px",150,0);
      //----scroll down
      this.scrollToLast()
    }
    else{
      this.isShowAllMsg=false;
      this.showOrHideMsgs = this.showMsgs;
      this.extraHeightToggle("120px",-150,0);
    }
    if (this.convStatInp.isShowRedNotify){
      this.updateSigViewed();
    }
  }

  public async scrollToLast(){
    await delay(100);
    let elementId_index:number = this.convStatInp.msgLogList.length-1;
    let elementId_index_string:string = elementId_index.toString();
    let elementId:string = this.allMsgBox_div_id+elementId_index_string;
    let itemToView = document.getElementById(elementId);
    if (itemToView != null)     itemToView.scrollIntoView({behavior:'smooth',block:'center'});
  }

  //----------------- UPDATE SIG VIEWED
  updateSigViewed(){
    this.convStatInp.parent.pushSigViewed(this.convStatInp.initiationID,this.convStatInp.convStatAllSigAware,this.convStatInp.msgLogList.filter(x=>x.fromID==this.convStatInp.otherPersonPublicId).length);
  }

  //------------------- OPEN CHAT
  openChatClicked(){
    this.convStatInp.parent.showChat(this.convStatInp.initiationID);
  }


  public async  extraHeightToggle(newheight:string, addHeight:number=0,addTop:number=0){
    let divToExtend = document.getElementById(this.resizable_div_id);
    let divToExtendHeight = divToExtend.style.getPropertyValue("height");
    let divToExtendHeightNum:number = +divToExtendHeight.substring(0,divToExtendHeight.length-2);
    
    let divToLower = document.getElementById(this.allMsgButton_div_id);
    let divToLowerTop = divToLower.style.getPropertyValue("top");
    let divToLowerTopNum:number = +divToLowerTop.substring(0,divToLowerTop.length-2);

    let newtop:string = divToLowerTop;

    if (addHeight != 0){
      newheight = (divToExtendHeightNum + addHeight).toString() + "px";
    }
    if (addTop != 0){
      newtop = (divToLowerTopNum + addHeight).toString() + "px";
    }

    if (divToExtendHeight=="120px"){
       divToExtend.style.height = newheight;
       divToLower.style.top = newtop;
       //let collapseGlyph = document.getElementById("collapseGlyph");
       //collapseGlyph.classList.replace("glyphicon-triangle-bottom","glyphicon-triangle-top");
       //await delay(200);
       //divToExtend.scrollIntoView({behavior:'smooth',block:'end'});
    }
    else{
     divToExtend.style.height = newheight;
     divToLower.style.top = newtop;
     //let collapseGlyph = document.getElementById("collapseGlyph");
     //collapseGlyph.classList.replace("glyphicon-triangle-top","glyphicon-triangle-bottom");
    }
 }
 

 deleteClicked(){
  this.sendReqInParent("delete", "");
}
clearDeletedClicked(){
  this.sendReqInParent("clear_del", "");
}

  sendReqInParent(messageType:string, message:string=""){
    let postBody = {
      "postType" : 3,
      "callerId" : 0, // to be set in parent
      "referToPerson" : this.convStatInp.otherPersonPublicId,
      "messageType" : messageType,
      "message" : message,
    }
    this.convStatInp.parent.sendConvRequest(postBody);

  }


  
  getMsgFormat(){
    if (this.convStatInp.isMyTurn){
      switch (this.convStatInp.lastMessageType){
        case "ping":
        {
          this.msg_format="userGotPing";
          if (this.otherGender=="female") this.otherSentInfo="שלחה פינג!";
          if (this.otherGender=="male") this.otherSentInfo="שלח פינג!";
          break;
        }
        case "phone":
        {
          this.msg_format="userGotCom";
          if (this.otherGender=="female") this.otherSentInfo="שלחה טלפון:";
          if (this.otherGender=="male") this.otherSentInfo="שלח טלפון:";
          this.otherSentContent = this.convStatInp.lastMessage;
          break;
        }  
        case "telegram":
        {
          this.msg_format="userGotCom";
          if (this.otherGender=="female") this.otherSentInfo="שלחה טלגרם:";
          if (this.otherGender=="male") this.otherSentInfo="שלח טלגרם:";
          this.otherSentContent = this.convStatInp.lastMessage;
          break;
        }
        case "kik":
        {
          this.msg_format="userGotCom";
          if (this.otherGender=="female") this.otherSentInfo="שלחה kik:";
          if (this.otherGender=="male") this.otherSentInfo="שלח kik:";
          this.otherSentContent = this.convStatInp.lastMessage;
          break;
        }
        case "text":
        {
          this.msg_format="userGotTxt";
          if (this.otherGender=="female") this.otherSentInfo="שלחה טקסט:";
          if (this.otherGender=="male") this.otherSentInfo="שלח טקסט:";
          this.otherSentContent = this.convStatInp.lastMessage;
          break;
        }
        case "delete":
        {
          this.msg_format="deleted";
          this.userSentInfo="התקבלה בקשה לסיום שיחה:";
          
          this.userSentContent = this.convStatInp.lastMessage;
          this.otherSentContent = this.userSentContent; // because this is how it is in the html.. need to fix to generic "sent content"
          break;
        }
      }
    }
    else{
      switch (this.convStatInp.lastMessageType){
        case "ping":
        {
          this.msg_format="userSentPing";
          if (this.otherGender=="female") this.userSentInfo="שלחת אליה פינג";
          if (this.otherGender=="male") this.userSentInfo="שלחת אליו פינג";
          break;
        }
        case "phone":
        case "telegram":
        case "kik":
        {
          this.msg_format="userSentCom";
          switch (this.convStatInp.lastMessageType){
            case "phone":
            {
              if (this.otherGender=="female") this.userSentInfo="שלחת אליה מספר טלפון";
              if (this.otherGender=="male") this.userSentInfo="שלחת אליו מספר טלפון";
              break;
            }
            case "telegram":
            {
              if (this.otherGender=="female") this.userSentInfo="שלחת אליה טלגרם";
              if (this.otherGender=="male") this.userSentInfo="שלחת אליו טלגרם";
              break;
            }
            case "kik":
            {
              if (this.otherGender=="female") this.userSentInfo="שלחת אליה kik";
              if (this.otherGender=="male") this.userSentInfo="שלחת אליו kik";
              break;
            }

          }
          
          break;
        }
        case "text":
        {
          this.msg_format="userSentTxt";
          if (this.otherGender=="female") this.userSentInfo="שלחת אליה טקסט:";
          if (this.otherGender=="male") this.userSentInfo="שלחת אליו טקסט:";
          this.userSentContent = this.convStatInp.lastMessage;
          this.otherSentContent = this.userSentContent; // because this is how it is in the html.. need to fix to generic "sent content"
          break;
        }
        case "delete":
        {
          this.msg_format="deleted";
          this.userSentInfo="התקבלה בקשה לסיום שיחה:";
          
          this.userSentContent = this.convStatInp.lastMessage;
          this.otherSentContent = this.userSentContent; // because this is how it is in the html.. need to fix to generic "sent content"
          break;
        }
      }
    }
    if (this.otherSentContent.length>35 ){
      if (this.userGender=="female") this.otherSentContent = "[הציגי את כל ההודעות בקישור למטה]";
      if (this.userGender=="male") this.otherSentContent = "[הצג את כל ההודעות בקישור למטה]";
    }
    else {
      this.otherSentContent = "\"" + this.otherSentContent + "\"";
    }
  
  }


  prepareAllMsg(){
    if (this.convStatInp.msgLogList.length > 0){
      for (let i:number = 0; i< this.convStatInp.msgLogList.length ; i++){
        this.convStatInp.msgLogList[i].index = i;
        if (this.convStatInp.msgLogList[i].fromID==this.convStatInp.otherPersonPublicId){
          this.convStatInp.msgLogList[i].isFromUser = false;
          this.convStatInp.msgLogList[i].fromFirstName= this.convStatInp.otherPersonFirstName;
          if (this.convStatInp.msgLogList[i].msgType == "ping"){
            if (this.otherGender=="female") this.convStatInp.msgLogList[i].content="[שלחה אליך פינג]";
            if (this.otherGender=="male") this.convStatInp.msgLogList[i].content="[שלח אליך פינג]";
          }
        }
        else{
          if (this.userGender=="female") this.convStatInp.msgLogList[i].fromFirstName="את";
          if (this.userGender=="male") this.convStatInp.msgLogList[i].fromFirstName="אתה";
          this.convStatInp.msgLogList[i].isFromUser = true;
          if (this.convStatInp.msgLogList[i].msgType == "ping"){
            if (this.otherGender=="female") this.convStatInp.msgLogList[i].content="[שלחת אליה פינג]";
            if (this.otherGender=="male") this.convStatInp.msgLogList[i].content="[שלחת אליו פינג]";
          }
        }
        if (this.convStatInp.msgLogList[i].isFromUser){
          this.convStatInp.msgLogList[i].msgLogCssClass="msgFromUser";
        }
        else{
          this.convStatInp.msgLogList[i].msgLogCssClass="msgFromOther";
        }
        this.convStatInp.msgLogList[i].msgLogCssClassFinal = this.convStatInp.msgLogList[i].msgLogCssClass;


      }
    }
  }


  requestClicked(convID:number){
    //this.convStatInp.parent.dosomething()
  }
}


