import { DomSanitizer, SafeUrl, SafeStyle, SafeHtml } from '@angular/platform-browser';

export class SiteTexts {

    constructor(){ }

    static getQnA(){
        let qna_object:QnA[] = [

          {
          question: 
          "קצת על האתר.",
          answer:
          [
          "האתר הזה הוא מיזם חברתי המיועד להכרויות בין אנשים.",
          "...........",
          "ואנחנו לא מתכוונים לכיוון רומנטי או זוגי או שקשור למיניות, לא משהו ליד או באיזור, כאלו יש מספיק🤦‍♂️.",
          "במקום זה - הכרויות חבריות נטו. פשוט, למצוא אנשים לרגע, או חברים לתמיד, או שותפים למצב הרוח.",
          "ולהפגש! 😎 כלומר באמת להפגש... פנים מול פנים, בלי מסך. וככה, להכיר את האנשים שגרים סביבנו, למצוא מגוון ועניין, להרחיב אופקים וליצור קשרים חדשים בקהילה.",
          "זה יכול להיות כיף ומשוגע, או רגוע ומנחם. זה יכול ללכת לקשרים אמיצים, או גילוי זוויות הסתכלות שונות על החיים. העיקר - לפגוש ולהיפגש.",
          "מעבר לכך, יש פה גם מקום לתרום מעצמך לאנשים אחרים, שצריכים אוזן. שצריכים שלא ישפטו אותם. שיפרגנו, שיגידו מילה טובה.",
          "ואל תשכחו - האתר הזה תינוק. הוא לומד ללכת ועושה טעויות. הוא יתפתח איך שתעצבו אותו, אז מקווים שתעשו עבודה טובה... :)",
          ],
          },
        
          {
          question: 
          "איך זה עובד?",
          complex_answer:[
            {
              question:
              "1 - נרשמים",
              answer:[
              "לא באופן אנונימי. למה? כי חברות מתחילה בלבטוח אחד בשני. וכן, גם סתם פגישה חד פעמית דורשת אמון. מטרתנו לעשות טוב אחד לשני, וזה מתחיל בלדעת שבשני הצדדים עומד בן אדם. לכן הפרופילים אינם אנונימיים."
              ],
              complex_answer:[],
            },
            {
              question:
              "2 - מעלים תמונה",
              answer:[
              "תמונת צבע, מחויכת, מזמינה. שימו לב: מטרת התמונה היא אך ורק ליצור ביטחון והזמנה לשיחה. זו לא תחרות יופי, ולא בוחרים חברים לפי פנים. אם זה הכיוון שלכם, אתם לא באתר הנכון (למרות שגם באתר הנכון תמונות יכולות להטעות...). כמו כן - חשוב לדעת שלמרות שאתם מעלים תמונה, אתם לא חשופים בבת אחת להמוני משתתפים. תמשיכו לקרוא, תבינו שהחשיפה פה היא מדורגת, לאט לאט, כל פעם עוד מישהו. זה בסדר להראות את פרצופנו כאן כמו שזה בסדר להראות אותו ברחוב.",
              ],
              complex_answer:[],
            },
            {
              question:
              "3 - מגרילים אדם חדש",
              answer:[
              "פשוט ככה - המערכת תאפשר בכל 7 שניות לפנות לאדם חדש, עד עשר פניות ביום, חמישים לחודש. אתם לא חייבים לפנות לאדם שהוגרל, אבל תצטרכו להקדיש לפחות כמה שניות לחשוב על מי שמולכם, ולשקול פניה. וכך, לזכור שהאדם שמוצג בפניכם הוא לא תמונה באינטרנט, הוא אדם ומגיעה לו דקה של מחשבה וסיכוי שווה. אם אתם מכירים את התלונות באתרי הכרויות על תחושת שוק בשר - זה בדיוק מה שאנחנו רוצים למנוע. אנחנו מגרילים עבורכם את האדם הבא, אין כאן סחר בפרצופים, אין שליחת הודעות בסיטונאות ואין הצפה באינבוקס.",
              ],
              complex_answer:[],
            },
            {
              question:
              "4 - אם רוצים - פונים לחבר",
              answer:[
              "פניה ראשונית היא תמיד 'פינג', לא טקסט. לא צריך לחשוב על משפט מחץ. המשך ההתקשרות יהיה רק אם החבר/ה יגיב/תגיב במהלך השבוע. ואם לא תקבלו תגובה? לא קרה כלום.",
              ],
              complex_answer:[],
            },
            {
              question:
              "5 - צופים ב'סטטוס הודעות' ומגיבים לאנשים שפנו אליכם",
              answer:[
              "אנחנו מעודדים תגובה! ומוסיפים לכם נקודות בהתאם :) בכל זאת, אם לא תגיבו, לא תיפגשו אנשים חדשים.",
              ],
              complex_answer:[],
            },
          ]
          },
        
          {
          question: 
          "החזון שלנו.",
          complex_answer:[
            {
              question:"מקום",
              answer:["לכולם יש מקום! גיוון מייצר שמחה, שיפוטיות לא תורמת לאף אחד.",]
            },
            {
              question:"לא לבד",
              answer:["אף אחד אף פעם לא צריך להרגיש לבד, יותר ממה שהוא רוצה.",]
            },
            {
              question:"מוגנות",
              answer:["אין אדם זר, יש אדם חדש - יוצרים מוגנות וביטחון לפגישות עם אנשים שלא הכרנו.",]
            },
            {
              question:"פנים",
              answer:["פחות מסך, יותר פרצופים אמיתיים, ניואנסים בשיחה ושפת גוף. חוזרים לאנושיות.",]
            },
          ]
          },
        
        
        ];
        return qna_object;
    }
    static getPrivacy(){
      let privacy_object:QnA[] = [

        {
        question: 
        "1. ברוכים הבאים",
        answer:
        [
        "ברוכים הבאים לאתר האינטרנט ''סתם לדבר''. האתר בבעלותם ותפעולם של מחזיקי הדומיין stam-ledaber.com (להלן: \"האתר\" או \"אנו\"). האתר מתייחס בכבוד לפרטיותך ומחויב לשמור ולהגן על המידע האישי שהוא מחזיק אודותייך. מדיניות פרטיות זו (להלן: \"המדיניות\") מתארת את האופן בו אנו אוספים מידע, סוג המידע הנאסף, אופן השימוש במידע, זכויותיך, לרבות הדרך שבה אתה, כמושא המידע, יכול להסירו או לשנותו, וכן מידע חשוב אחר. השימוש שלך באתר כפוף למדיניות זו, אנו מתחייבים כלפיך בכיבוד מדיניות זו.",
        "1.1 המסמך מנוסח בלשון זכר אך פונה אל שני המינים."
        ],
        },
      
        {
        question: 
        "2. ממי אנו אוספים מידע?",
        answer:
        [
          "האתר אוסף מידע אישי מהמשתמשים הרשומים לאתר בלבד. המידע נמסר כחלק מתהליך ההרשמה ומהשימוש באתר, במטרה לעשות שימוש בשירותי האתר. כמו כן, האתר אוסף מידע שאינו אישי ממבקרים מזדמנים באתר."
        ]
        },
      
        {
        question: 
        "3. איזה מידע אנו אוספים?",
        answer:
        [
          "המידע אותו אנו אוספים: שמכם המלא, כתובת דואר אלקטרוני, גיל, מין תמונות, העדפות משתמש, עיר ושכונת מגורים (\"טופס הרישום\"). ככל שהמידע משתנה, יש לעדכנו בהתאם, בכדי שיהיה נכון בעת השימוש באתר. כל מידע שייאסף נמסר על-ידי המשתמש בלבד ומרצונו האישי ובהסכמתו. אנו לא אוספים מידע ביוזמתנו למעט מידע אשר נאסף על ידי cookies המופעלים על ידי האתר.  "
        ]
        },
        {
          question: 
            "4. השימוש במידע שנאסף",
          answer:
            [
              "השימוש במידע שנאסף ייעשה על פי מדיניות פרטיות זו או על פי הוראות הדין, לאחת מהמטרות הבאות:",
              "1. להשתמש בשירותים שקיימים באתר.",
              "2. לשפר ו/או להעשיר את השימוש בשירותים שקיימים באתר.",
              "3. לשנות או לבטל שירותים שקיימים באתר.",
              "4. לספק תמיכה ולטפל בבקשות או בתלונות."
            ]
        },
        {
          question: 
            "5. שיתוף מידע עם גורמים נוספים",
          answer:
            [
              "1. ככלל, הנהלת האתר לא תעביר את פרטיו האישיים (שאינם מפורסמים לכלל המשתמשים) של המשתמש לאף גורם צד שלישי אלא בהתקיים אחד או יותר מהמקרים הבאים:",
              "2. במקרה של מחלוקת משפטית בין הנהלת האתר למשתמש.",
              "3. במקרה שהמשתמש ביצע עבירות מנוגדות לדין או לתנאי השימוש באתר.",
              "4. במקרה של מתן צו המורה על מסירת מידע המשתמש.",
              "5. במקרה של העברת הפעילות מהנהלת האתר לגורם צד ג' וכן במקרה של מיזוג עם גוף צד ג'.",
              "6. לגורמי אכיפה במידה והאתר סבור כי יש במידע שנאסף או בשימוש באתר משום פגיעה אפשרית באדם או עבירה פלילית או חדש לכל אחד מאלה (לרבות, איום, הטרדה, הטרדה מאיימת וכיוב') ",
            ]
        },
        {
          question: 
            "גישה למידע משתמשים",
          answer:
            [
              "6. בעת השימוש והגלישה באתר, המשתמש מסכים לאיסוף ו/או שמירה ו/או שימוש במידע האישי שלו באופן המתואר במדיניות זו. המשתמש אינו חייב למסור מידע אישי כאמור. מסירת המידע האישי מהווה הסכמה של המשתמש לשימושים המתוארים במדיניות הפרטיות. במידה והמשתמש מסרב למסור מידע אישי, הנהלת האתר לא תוכל להציע למשתמש את שירותי האתר.",
              "7. המשתמש מצהיר כי ידוע לו שהגישה למידע נמצאת בידי הנהלת האתר, לרבות המידע שניתן על ידי המשתמשים, שיחות הצ'אט, וכל שאר המידע הכלול בפעילות האתר - זאת לצרכי בקרה, פיקוח ושיפור השירות."
            ]
        },
        {
          question: 
            "סודיות ופרטיות",
          answer:
            [
              "8. הנהלת האתר מכבדת את פרטיות המשתמשים בשירותי האתר. כל פרטיו האישיים של המשתמש ישמרו במאגרי המידע של הנהלת האתר.",
              "9. הנהלת האתר לא תעשה כל שימוש או שינוי בפרטיו האישיים של המשתמש, למעט עריכת תמונה לצורך התאמתה לגרפיקת האתר, שינוי פרטי משתמש שינוסחו ו/או יוצגו באתר באופן שחורג מתנאי השימוש בו. פרטי המשתמשים החורגים יוסרו מהאתר, המשתמש יקבל הודעה על כך. למשתמש קיימת האפשרות לנסח מחדש את פרטיו, בצורה הולמת ומתאימה.",
              "10. הנהלת האתר תהא רשאית להשתמש בפרטיו האישיים של המשתמש לצורך ניתוח מידע סטטיסטי והצגתו.",
            ]
        },
        {
          question: 
            "\"עוגיות\" (\"Cookies\")",
          answer:
            [
              "11. הנהלת האתר תהא רשאית לעשות שימוש ב - \"עוגיות\" (\"Cookies\") בכדי לספק למשתמש חווית שימוש מהירה ויעילה, ולחסוך מהמשתמש את הצורך להזין את פרטיו האישיים בכל כניסה לאתר.",
              "12. כמו כן, יעשה איסוף נתונים סטטיסטיים אודות השימוש באתר, התאמת האתר למשתמש ולצורכי אבטחת מידע.",
              "13. באפשרות המשתמש לבטל את השימוש ב-\"Cookies\" וזאת באמצעות הגדרות הדפדפן.",
            ]
        },
        {
          question: 
            "אבטחת מידע",
          answer:
            [
              "14. הנהלת האתר פועלת לפי תקנות הגנת הפרטיות (אבטחת מידע), תשע\"ז - 2017. אנו מתייחסים לנושא האבטחה ברצינות רבה ולכן עושים מאמצים סבירים ומיישמים כלים ונהלים שונים על מנת להגן על המידע האישי שלך ולהבטיח שהוא אינו נגיש לגורמים בלתי מורשים. כלים ונהלים אלה מצמצמים אמנם את הסיכון לפרצת אבטחה, אך עם זאת אינם מספקים ביטחון מוחלט. עליך לדעת כי העברת מידע באינטרנט טומנת בחובה סיכונים, וכן ישנו סיכוי שגורמים אחרים יצליחו למצוא דרך לעקוף את אמצעי האבטחה שלנו. אי לכך, אנו לא יכולים להבטיח שירות חסין מכל טעויות, תקלות, יירוטי מידע בלתי חוקיים או גישה בלתי מורשית למידע השמור או לכל סיכוני אבטחת מידע אחרים, או לשמירה על פרטיות התקשורת שלך איתנו. כאשר אתה משתמש באתר או בשירותים אתה עושה זאת על דעתך ובאחריותך בלבד, ולכן אנו ממליצים לך לנקוט במשנה זהירות כאשר אתה משתמש באלו ובוחר לשתף מידע אישי, בין היתר באמצעות שיחות צ'אט או בדף פרופיל המשתמש. מידע אישי ישמר על ידינו אך ורק למשך הזמן הנחוץ לקיום המטרות שלשמם המידע נאסף וצורך ציות להוראות הדין"
            ]
        },
        {
          question: 
            "זכות עיון במידע",
          answer:
            [
              "15. בהתאם לחוק הגנת הפרטיות, התשמ\"א - 1981, אתה זכאי לעיין במידע אודותיך המוחזק במאגר מידע, בכפוף להוראות כל דין. ",
              "16. אם בעקבות בקשתך לעיין במידע אודותיך מצאת כי הוא אינו נכון, שלם, ברור או מעודכן, אתה רשאי לפנות אלינו בבקשה לתקן את המידע או למוחקו. פנייה כזו יש להפנות אל הנהלת האתר באמצעות דוא\"ל: info@stam-ledaber.com",
            ]
        },
        {
          question: 
            "שינויים במדיניות פרטיות",
          answer:
            [
              "17. הנהלת האתר רשאית לשנות בכל עת את תנאי המדיניות הפרטיות על פי ראותה ושיקול דעתה הבלעדי. אנו ממליצים לבדוק כל שינוי במדיניות הפרטיות לפני שתמסור לאתר מידע אישי.",
              "18. במקרה של שינויים מהותיים שיבוצעו בתנאי מדיניות הפרטיות, הנהלת האתר תפרסם בהקדם הודעה על כך בעמוד הבית של האתר ו/או באמצעות כתובת הדוא\"ל של המשתמש.",
              "19. שינוי מהותי יכנס לתוקף 7 ימים מיום מתן ההודעה כאמור בסעיף 18, וכן כל שינוי אחר יכנס לתוקפו החל מתאריך השינוי הנקוב בו. המשך גלישה המשתמש באתר והשימוש בשירותי האתר לאחר השינויים משמעו קבלתן והסכמת המשתמש לשינויים."
            ]
        },


      
      
      ];
      return privacy_object;
    }
    static getUsageCond(){
      let usageCond_object:QnA[] = [
        
        {
          question: 
            "כללי",
          answer:
            [
              "<b>1.</b> אתר \"סתם לדבר\" (להלן: \"האתר\") משמש ליצירת היכרויות חבריות (שאינן למטרות רומנטיות) בין אנשים (להלן: \"השירות\"). האתר בבעלותם ותפעולם של מחזיקי הדומיין stam-ledaber.com (להלן: \"אנו\" או \"אנחנו\" או \"נציגי האתר\" או \"הנהלת האתר\").",
              "<b>2. השימוש באתר:</b> השימוש באתר ובתכנים המוצגים בו באמצעות מכשירי קצה שונים כפוף לתנאי שימוש אלו (\"תנאי השימוש\") ומדיניות הפרטיות (<span style=\"color:blue\" onclick=\"document.getElementById('b_private').click();\">מדיניות פרטיות</span>) (שניהם יחד: \"התנאים\"). אנא קראו תנאים אלה בעיון. השימוש באתר ובשירות מעיד על הסכמתכם ואישורכם לאמור בתנאים. <b>אם אינכם מסכימים לתנאי מהתנאים לעיל, הנכם מתבקשים לא לעשות כל שימוש באתר או בשירות. המשתמש מצהיר כי ידוע לו שמטרת השימוש באתר הינה היכרות חברית בלבד ולא יצירת קשרים רומנטיים.</b> ההתנהלות באתר, לרבות פנייה ויצירת קשר עם משתמשים אחרים, צריכה להיעשות בשפה ובאופן שהולם את רוח האתר ומטרתו. שימוש באתר שלא למטרה שהוגדרה בסעיף זה, תאפשר, בין היתר, להנהלת האתר להפסיק את שימושכם בו (לרבות על ידי חסימת גישה או הגבלת גישה ושימוש). אין הנהלת האתר אחראית לשימוש החורג ממטרות האתר. אנו מעודדים ומבקשים ממשתמשי האתר לדווח על כל חריגה ממטרות השימוש באתר על מנת לאפשר לנו להבטיח את השימוש בו באופן התואם את מטרותיו, בצורה בטוחה ומכבדת. ",
              "<b>3. מטרת התנאים:</b> מטרת התנאים היא הסדרת היחסים בין נציגי האתר לבין כל גורם אשר עושה שימוש בשירות ו/או גולש באתר (\"המשתמש\"). נציגי האתר רשאים לבצע שינוי במסמך תנאי שימוש זה, בכל זמן ועל פי שיקול דעתם הבלעדי וללא צורך במסירת הודעה כלשהי מראש. יחד עם זאת, בעת עריכת שינוי מהותי, תסופק הודעה בעמוד הבית של האתר, ואף בדרכים נוספות (על פי שיקול דעתם הבלעדי של נציגי האתר). תוקפו של שינוי תנאי השימוש יחל לאחר 7 ימים .",
              "<b>4. הגבלת גיל:</b> הרישום לאתר והשימוש בו מותרים מגיל 18 ומעלה בלבד. בהירשמכם לאתר, אתם מצהירים שאתם מעל גיל 18. אין הנהלת האתר אחראית לוודא את גיל המשתמשים.",
              "<b>5. אחריות האתר:</b> הנהלת האתר לא תהא אחראית לטעויות בתכנים, להשמטת פרטים ו/או לאי-דיוקים. כמו כן, הנהלת האתר לא תהא אחראית לתכנים המוזנים על ידי המשתמשים. הנהלת האתר עושה ככל שביכולתה לוודא שהמידע המוצג באתר יהיה מידע שלם ומדויק אך עלולים להופיע בו, בתום לב, אי דיוקים או שגיאות. בכפוף לדין הקיים, הנהלת האתר לא תישא באחריות כלשהיא הנובעת מהן או קשורה אליהן. ",
            ]
        },
        {
          question: 
            "הרשמה לאתר",
          answer:
            [
              "<b>6. מסירת מידע:</b> בעת פתיחת חשבון באתר, תתבקשו לספק מידע מדויק, מלא ועדכני אודותיכם, בין היתר: שם פרטי, גיל, כתובת דואר אלקטרוני, מספר טלפון נייד, עיר מגורים ושכונה (\"טופס הרישום\"). ככל שהמידע משתנה, יש לעדכנו בהתאם, בכדי שיהיה נכון בעת השימוש באתר. מידע אשר יתגלה כמטעה או מוטעה מהווה הפרה של תנאי השימוש ועשוי לגרור אחריו חסימה או הסרה של המשתמש באתר. אנו מתחייבים לעשות שימוש בפרטים האישיים לתפעול האתר בלבד ולהימנע מלהעביר אותם לצדדים שלישיים שאינם קשורים למתן שירותי האתר, אלא אם התקבל לכך אישור מפורש ומראש והכל  כמפורט במדיניות הפרטיות. בעוד אנו נוקטים באמצעים סבירים לשם אבטחת המידע, הרי שכל פעילות באינטרנט חשופה מטבעה לסיכונים, ולכן אין אנו מתחייבים לחסינות מוחלטת מפני גישה בלתי-מורשית למידע. משכך, אין אנו אחראים לנזק שעשוי להיגרם כתוצאה מחדירה בלתי מורשית ומדליפתו של מידע בעקבותיה.",
              "<b>7. סיסמה:</b> בנוסף לפרטים שתתבקשו למסור, כאמור לעיל, הנכם מתבקשים לבחור סיסמה, אשר תשמש לזיהוי לצורך קבלת השירותים. הינכם אחראים באופן מלא לשמירת סודיות הסיסמה ומתחייבים להודיע להנהלת האתר על דליפה של הסיסמה שלכם, שימוש שאינו מורשה בחשבונכם האישי ו/או על כל הפרת סודיות אחרת. ",
              "<b>8. צד ג':</b>  להנהלת האתר לא תהא כל אחריות לשימוש בלתי מורשה של כל צד ג' באמצעות הנתונים המופיעים בטופס הרישום. במידה ותבחרו לחלוק מידע זה עם צד ג' כלשהו, תישאו באחריות לכל הפעולות שיתבצעו באמצעות הנתונים שלכם.",
            ]
        },
        {
          question: 
            "הסרה או הגבלה של תכנים באתר",
          answer:
            [
              "<b>9. פרסום באתר:</b> אין לפרסם באתר בכל דרך שהיא, בין היתר, תכנים בעלי אופי מיני, תכנים מאיימים או פוגעניים, תכנים בעלי אופי גזעני, תכנים העשויים לעלות לכדי הוצאת לשון הרע, תכנים המעודדים לביצוע עבירות פליליות או עוולות אזרחיות, תכנים אשר יש בהם משום פגיעה בפרטיות, תכנים מזיקים, תכנים העשויים לפגוע ברגשות הציבור, תכנים המפירים קניין רוחני, תכנים אשר מכילים וירוס מחשב מכל סוג שהוא או כל סוג של תכנים אשר אינם רלוונטיים לשירותים המסופקים באתר.",
              "<b>10. פיקוח על הודעות משתמשים:</b> הנהלת האתר עושה ותעשה את מירב המאמצים לאכוף את מטרות השימוש בו כפי שהוגדר בתנאים (בין היתר באמצעות פיקוח על תוכן ההודעות). להנהלת האתר שמורה הזכות לסנן ואף לחסום משתמשים שמתנהלים בצורה לא הולמת ואשר נוגדת את כללי השימוש באתר, בין אם בעקבות דיווח של משתמשי האתר ובין אם על פי שיקול דעתה הבלעדי. אם הנהלת האתר הסירה את חשבונכם מסיבה מסוימת - הינכם רשאים לערער על החלטה זו לכתובת המייל הבאה info@stam-ledaber.com. בקשת הערעור תיבחן בהתאם לנסיבות. להנהלת האתר שמורה הזכות להחליט על תוצאת הערעור כראות עיניה ועל-פי שיקול דעתה הבלעדי.",
              "<b>11. פרסום באתר:</b> במידה ומצאתם במידע הנמצא באתר, או במידע שסופק על ידי גורם שלישי במסגרת האתר, חומר הנחזה כבלתי חוקי, לא מוסרי או כזה שפוגע בזכויות של צדדים שלישיים, אתם מתבקשים להודיע על כך למנהלי האתר בהקדם בכתובת info@stam-ledaber.com. אנו נבחן את הפנייה, וככל שתמצא כמוצדקת, נסיר את התכנים המפרים בתוך פרק זמן סביר מעת קבלת הפנייה.",
            ]
        },
        {
          question: 
            "הגבלת אחריות הנהלת האתר או מי מטעמה",
          answer:
            [
              "<b>12. </b>בכפוף לדין החל, הנהלת האתר ו/או מי מטעמה לא יישאו בכל אחריות, נזק ישיר/עקיף, אי נוחות, אובדן, עוגמת נפש וכיוצא באלו ביחס לתכנים, לזמינות ולשירותים באתר ו/או באתרים המקושרים (לרבות תכנים הנמסרים לפרסום), ודרך כל מכשיר קצה. כמו כן, הנהלת האתר לא תהא אחראית לתוכנם, מהימנותם, דיוקם, אמינותם והשפעתם על המחשבים של הגולשים באתר וכן בגין גישה בלתי מורשית, התקפות, פריצה וחדירה למידע או כל ניסיון לבצע כל אחד מאלה.",
              "<b>13. קישורים:</b> הנהלת האתר איננה מתחייבת כי כל הקישורים שימצאו באתר יהיו תקינים. הנהלת האתר רשאית לסלק מהאתר קישורים, להוסיף קישורים חדשים או להימנע מהוספת קישורים חדשים, על פי שיקול דעתה הבלעדי והמוחלט. הימצאותו באתר של קישור לאתר מסוים אינה מהווה אישור לכך שהמידע באתר זה מלא, מהימן, עדכני או אמין.",
              "<b>14. שירותי האתר:</b> אין הנהלת האתר מתחייבת לספק את שירותיה ללא טעויות או בלא הפסקות. כמו כן, הנהלת האתר רשאית לשנות את מבנה ומראה האתר, והן את השירותים הניתנים באתר וזמינותם. הנהלת האתר אף רשאית להפסיק לאלתר את פעילות האתר בכל זמן, וזאת מבלי להודיע על כך בהודעה מוקדמת וללא מחויבות לשמירת נתונים כלשהם, על פי שיקול דעתה.",
            ]
        },
        {
          question: 
            "קבצי Cookie",
          answer:
            [
              "<b>15. </b> קובץ \"Cookie\" הינו קובץ טקסט הנאסף על ידי שרת האינטרנט שלכם ונשמר בכונן הקשיח במחשבכם. שימוש ב-\"Cookie\" מאפשר לשרתי האתר לשחזר את המידע שנשמר בכונן הקשיח ולזהות אתכם במהירות וביעילות כאשר אתם חוזרים ומבקרים באתר. כתוצאה מכך הביקור שלכם באתר הופך לקל יותר. המידע שנשמר הינו מקודד, כך שרק הנהלת האתר יכולה לקרוא ולהבין את המידע הזה. כאשר אתם משתמשים באתר זה אתם למעשה מאפשרים להנהלת האתר לשמור \"Cookie\" במחשבכם ולהשתמש במידע שנאסף על מנת להפוך את השירות שניתן באתר לשימושי יותר עבורכם.",
            ]
        },
        {
          question: 
            "קניין רוחני",
          answer:
            [
              "<b>16. </b> כל זכויות הקניין הרוחני באתר ו/או בשירות, הם רכושה הבלעדי של הנהלת האתר. אין להעתיק, לשכפל, להפיץ, לתרגם, ליצור עבודות נגזרות, לפרסם את דפי האתר ו/או לעשות בהם או במידע שבתוכם שימוש כלשהו ללא הסכמתה המפורשת של הנהלת האתר, מראש ובכתב. כל שימוש בנתונים המתפרסמים באתר או באמצעות השירות, בלא הסכמת הנהלת האתר מראש ובכתב הינו אסור, לרבות סימני מסחר, תמונות, אייקונים, מחירים, תיאור פריטים וכל מידע או תצוגה המופיעים באתר או בשירות. בין אם הנתונים הינם בבעלותם הבלעדית של הנהלת האתר, ובין אם הנתונים בבעלות של שותפי הנהלת האתר. כמו כן, אין להכניס באתר שינויים, לבצע בפומבי, לשנות, לשדר או למסור לצד שלישי כל חלק מן האתר בלא קבלת הסכמתה של הנהלת האתר בכתב ומראש.",
              "<b>17. </b> הנהלת האתר לא תהא אחראית כלל לזכויות הנוגעות לתמונות ו/או תכנים שיפורסמו על ידי המשתמשים. על משתמשי האתר להקפיד שלא להעלות תכנים העלולים להפר זכויות יוצרים.",
              "<b>18. </b> חדירה לאתר על מנת ליטול ממנו תוכן או לסרוק אותו לשם נטילת או בחינת תוכן (Scraping) בין בדרך ידנית ובין בדרך של אמצעים אוטומטיים הינה אסורה ומהווה פגיעה בזכות הקניינית של האתר. ",
            ]
        },
        {
          question: 
            "שיפוי",
          answer:
            [
              "<b>19. </b> הנכם מתחייבים להגן ולשפות את הנהלת האתר ונציגיה בגין כל תביעה, דרישה, נזק, הפסד, אחריות, תשלום או כל הוצאה שתגרם - ובכלל זה שכר טרחת עורך דין והוצאות משפט הנובעים מהפרת תנאי שימוש אלה מצידכם ו/או תביעה של צד שלישי בעבור הפרה מצדכם של כל זכות שלו ו/או כל נזק שגרמתם לצד שלישי בעקבות השימוש באתר או בשירות",
            ]
        },
        {
          question: 
            "הסכמה לתנאי השימוש באתר",
          answer:
            [
              "<b>20. </b> במידה ותפרו את תנאי שימוש אלו, להנהלת האתר קיימת הזכות להפסיק את שימושכם באתר או בשירות. כמו כן, בעת שימושכם באתר או בשירות אתם מתחייבים שלא להפר אף לא אחד מהסעיפים הבאים:",
              "   <b>(א)</b>   לפגוע באדם אחר ובזכויותיו, לרבות להתחקות אחריו או להטרידו.",
              "   <b>(ב)</b>   התחזות לאדם, לרבות נציג של הנהלת האתר.",
              "   <b>(ג)</b>   הצגה כוזבת של קשר בין המשתמש לבין צד שלישי.",
              "   <b>(ד)</b>   הפצת \"דואר זבל\" (spam) לשרתי האתר, לנציגי הנהלת האתר או ללקוחות האתר",
              "   <b>(ה)</b>   העתקה, לקיחת מידע, תרגום, פירוק או הרכבה מחדש של כל אחד מ: (1) חלקי הקוד המרכיבים את האתר; (2) החומרה והתוכנה המאפשרות את השירות באתר.",
              "   <b>(ו)</b>   פגיעה בכל דרך שהיא בזכויות יוצרים, בסימני מסחר, או בכל זכות קניינית אחרת אשר מצויה באתר זה.",
              "   <b>(ז)</b>   הצבת האתר, או כל חלק ממנו, בתוך מסגרת של אתר אחר (frame) או כחלק מאתר אחר (mirror) ללא הסכמה בכתב ומראש של הנהלת האתר.",
              "   <b>(ח)</b>   שימוש בכל כלי המיועד לחשוף את מבנה מאגר הנתונים והקוד באתר זה.",
            ]
        },
        {
          question: 
            "שונות",
          answer:
            [
              "<b>21. </b>הנהלת האתר אינה מפלה על בסיס גיל, מין, גזע, לאום, דת, העדפה מינית או כל סטטוס אחר.",
              "<b>22. </b>במקרה של אי התאמה בין תנאי שימוש אלו לבין כל הוראות תנאי שימוש אחרות המצויות באתר, תנאי שימוש אלו יגברו.",
              "<b>23. </b>על תנאי שימוש אלו יחולו אך ורק דיני מדינת ישראל. במידה והינכם משתמשים בשירות או באתר כתושבים של מדינה זרה, או שהינכם נמצאים בעת שימושכם באתר במדינה זרה, זוהי מחויבותכם לוודא שהתנאים תואמים גם את דיני המדינה הזרה, והנהלת האתר לא תישא באחריות בעבור כל הפרת דין של מדינה זרה שבוצעה על ידכם.",
              "<b>24. </b>מקום השיפוט הבלעדי לכל עניין הנוגע להסכם זה ולשימוש בשירות או באתר הינו בית המשפט באזור תל אביב-יפו. על כל עניין כאמור יחולו דיני מדינת ישראל בלבד.",
            ]
        },
        {
          question: 
            "יצירת קשר",
          answer:
            [
              "<b>25. </b>בכל נושא ועניין ניתן לפנות להנהלת האתר דרך המייל בכתובת info@stam-ledaber.com",
            ]
        },



      
      
      ];
      return usageCond_object;
    }
        
}

export class QnA {
    question: string;
    questionHtml?: SafeHtml="";
    answer?: string[];
    answerHtml?: SafeHtml[]=[];
    complex_answer?: QnA[];
}