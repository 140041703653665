import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormatHttpGetResponse } from '../main-page/formatHttpGetResponse';
import { retry } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class ServerAccessService {

  private inflightRequests: { [key: string]: boolean } = {};

  constructor(private _http: HttpClient) {}

  // Generic server call function
  callServer(
    postBody: any,               // The body of the request
    inflightKey: string,         // Unique key to manage inflight state
    callback: (data: any) => void,   // The callback to handle the response
    endpoint: string = '/api/ClientMsg', // API endpoint, default to '/api/ClientMsg'
    delaySec: number = 0,         // Optional delay before the request (default is no delay)
    onEmptyResponse?: () => void, // Callback for empty or null response
    onErrorResponse?: (errorMessage: string) => void // Callback for actRes.error
  ): void {
    if (this.inflightRequests[inflightKey]) return;

    this.inflightRequests[inflightKey] = true;
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    const request = () => {
      this._http.post<any>(endpoint, postBody, { headers })
      .pipe(retry(3)) 
      .subscribe(
        (dataFromServer) => {
          this.inflightRequests[inflightKey] = false;

          // Handle empty response
          if (!dataFromServer || dataFromServer.length === 0) {
            if (onEmptyResponse) onEmptyResponse();
            return;
          }

          // Handle actRes.error
          if (!dataFromServer[0].actRes.success) {
            if (onErrorResponse) {
              onErrorResponse(dataFromServer[0].actRes.error_message);
            }
            return;
          }

          callback(dataFromServer);
        },
        (error) => {
          this.inflightRequests[inflightKey] = false;
          console.error(`Error in ${inflightKey} request`, error);
        }
      );
    };

    // If delay is required, apply it
    if (delaySec > 0) {
      setTimeout(() => request(), delaySec * 1000);
    } else {
      request();
    }
  }
}
