import { Component, OnInit } from '@angular/core';

import { delay } from 'q';
import { HttpClient, HttpParams, HttpHeaders, HttpEventType } from '@angular/common/http';
import { FormatHttpGetResponse, HttpGeneralResponse,characteristic,conversationsStatus,userProfileDetails} from '../main-page/formatHttpGetResponse';
import { Observable } from 'rxjs';
import { DomSanitizer, SafeUrl, SafeStyle, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-log-in',
  templateUrl: './log-in.component.html',
  styleUrls: ['./log-in.component.css']
})
export class LogInComponent implements OnInit {

  login_email:string;
  login_password:string;
  forgotpass_email:string;
  newpass_email:string;
  init_code:string;
  new_password:string;
  new_password2:string;

  isShowMainEmail:boolean = true;
  isShowMainPass:boolean = true;

  isShowLoad:boolean = false;
  isShowSend:boolean = true;
  isShowForgotPass:boolean = true;
  isShowEmailForForgotPass:boolean = false;
  isShowSendInitCode:boolean = false;
  isShowIHaveInitCode:boolean = false;
  isShowWeSentPass:boolean = false;
  isShowInitCodeInput:boolean = false;
  isShowNewPass:boolean = false;
  isShowChangePass:boolean = false;
  isShowDelete:boolean = false;

  hasErrMsg:boolean = false;
  hasSucMsg:boolean = false;
  errMsg:string = "";
  sucMsg:string = "";
  apiValues:string[];

  isLoggedIn:boolean = false;
  firstName:string = "";

  navBarLoggedIn:string = "undefined";

  constructor(private _http: HttpClient, private sanitizer:DomSanitizer, private route: ActivatedRoute) { }
  
  pageTypeID:string="log";

  ngOnInit() {
    this.getProfileDetails();
    document.body.style.overflow="auto";
    //this.sendStatRequest("EnteredLogIn");
    
    
  }

  /*-------------------------------------------*/
  /*-------------------------------------------*/
  /*             SERVER REQUESTS               */
  /*-------------------------------------------*/
  /*-------------------------------------------*/


  public async getProfileDetails(){
    let postBody = {
      "postType" : 9,
    }
      let headers = new HttpHeaders()
        .set('Content-Type','application/json');
      let postObservable:Observable<FormatHttpGetResponse[]> = this._http.post<FormatHttpGetResponse[]>('/api/ClientMsg',postBody,{headers}); 
      postObservable.subscribe(
        dataFromServer => { this.onServerMsgUpdateProfileDetails(dataFromServer);},
        error => this.apiValues = ['error on http in checkServerMsg','error found']
      )
  }

  async sendStatRequest(message:string=""){
    let postBody = {
      "postType" : 99,
      "message" : message,
    }
    let headers = new HttpHeaders()
      .set('Content-Type','application/json');
    let postObservable:Observable<FormatHttpGetResponse[]> = this._http.post<FormatHttpGetResponse[]>('/api/ClientMsg',postBody,{headers}); 
    postObservable.subscribe(
      dataFromServer => { this.onServerReply_GetStatAck(dataFromServer);},
      error => this.apiValues = ['error on http in checkServerMsg','error found']
    )
  }
  
  async log_on(){
    this.isShowLoad = true;
    this.isShowEmailForForgotPass = false;
    this.isShowSendInitCode = false;
    this.isShowIHaveInitCode = false;
    this.isShowInitCodeInput = false;
    this.isShowWeSentPass = false;
    this.isShowNewPass = false;
    this.isShowChangePass = false;

    let postBody = {
      "postType" : 8,
      "userProfileDetails" : {
        email:this.login_email,
        password:this.login_password,
      }
    }
      let headers = new HttpHeaders()
        .set('Content-Type','application/json');
      let postObservable:Observable<FormatHttpGetResponse[]> = this._http.post<FormatHttpGetResponse[]>('/api/ClientMsg',postBody,{headers}); 
      postObservable.subscribe(
        dataFromServer => { this.onServerLogInOrOut(dataFromServer);},
        error => this.apiValues = ['error on http in checkServerMsg','error found']
      )
  }

  async log_out(){
    this.isShowLoad = true;
    let postBody = {
      "postType" : 10,
      "userProfileDetails" : {
        email:this.login_email,
        password:this.login_password,
      }
    }
      let headers = new HttpHeaders()
        .set('Content-Type','application/json');
      let postObservable:Observable<FormatHttpGetResponse[]> = this._http.post<FormatHttpGetResponse[]>('/api/ClientMsg',postBody,{headers}); 
      postObservable.subscribe(
        dataFromServer => { this.onServerLogInOrOut(dataFromServer);},
        error => this.apiValues = ['error on http in checkServerMsg','error found']
      )
  }

  async send_init_code(){
    this.isShowLoad = true;
    let postBody = {
      "postType" : 13,
      "userProfileDetails" : {
        email:this.forgotpass_email,
      }
    }
      let headers = new HttpHeaders()
        .set('Content-Type','application/json');
      let postObservable:Observable<FormatHttpGetResponse[]> = this._http.post<FormatHttpGetResponse[]>('/api/ClientMsg',postBody,{headers}); 
      postObservable.subscribe(
        dataFromServer => { this.onServerSentInitCode(dataFromServer);},
        error => this.apiValues = ['error on http in checkServerMsg','error found']
      )
  }

  async change_password(){
    this.isShowLoad = true;
    let postBody = {
      "postType" : 14,
      "userProfileDetails" : {
        email:this.forgotpass_email,
        password:this.new_password+this.new_password2+this.init_code,
      }
    }
      let headers = new HttpHeaders()
        .set('Content-Type','application/json');
      let postObservable:Observable<FormatHttpGetResponse[]> = this._http.post<FormatHttpGetResponse[]>('/api/ClientMsg',postBody,{headers}); 
      postObservable.subscribe(
        dataFromServer => { this.onServerChangePassword(dataFromServer);},
        error => this.apiValues = ['error on http in checkServerMsg','error found']
      )
  }

  async deleteProfile(){
    this.isShowLoad = true;
    let postBody = {
      "postType" : 19,
      "userProfileDetails" : {
        email:this.login_email,
        password:this.login_password,
      }
    }
      let headers = new HttpHeaders()
        .set('Content-Type','application/json');
      let postObservable:Observable<FormatHttpGetResponse[]> = this._http.post<FormatHttpGetResponse[]>('/api/ClientMsg',postBody,{headers}); 
      postObservable.subscribe(
        dataFromServer => { this.onServerDeleteProfile(dataFromServer);},
        error => this.apiValues = ['error on http in checkServerMsg','error found']
      )
  }

  

  /*-------------------------------------------*/
  /*-------------------------------------------*/
  /*             SERVER REPLYS                 */
  /*-------------------------------------------*/
  /*-------------------------------------------*/

  async onServerReply_GetStatAck(dataFromServer:FormatHttpGetResponse[]){
    // do nothing
  }

  async onServerMsgUpdateProfileDetails(dataFromServer:FormatHttpGetResponse[]){
    if (dataFromServer== null || dataFromServer.length==0) {
      this.navBarLoggedIn = "no";
      return;
    }
    if (!dataFromServer[0].actRes.success){
      this.hasErrMsg = true;
      this.errMsg = dataFromServer[0].actRes.error_message;
      this.navBarLoggedIn = "no";
      return;
    }
    this.hasErrMsg = false;
    let profileFromServ:userProfileDetails = dataFromServer[0].upd;
    if (dataFromServer != null && profileFromServ.firstName){
      this.firstName = profileFromServ.firstName,

      this.pageTypeID = this.route.snapshot.paramMap.get('pageTypeID');
      switch (this.pageTypeID){
        case "log": {
          //do nothing
          break;
        }
        case "deleteProfile": {
          //set param to show delete me button
          this.isShowDelete = true;
          this.isShowSend = false;
          break;
        }
        default: { 
          //do nothing
          break;
        } 
      }

      this.isLoggedIn = true;
      this.navBarLoggedIn = "yes";
    }
    else{
      this.isLoggedIn = false;
      this.navBarLoggedIn = "no";
    }
  }

  onServerLogInOrOut (dataFromServer:FormatHttpGetResponse[]){
    this.isShowLoad = false;
    if (dataFromServer== null || dataFromServer.length==0) {
      this.hasErrMsg = true;
      this.hasSucMsg = false;
      this.errMsg = "ארעה שגיאה, נסו שוב מאוחר יותר";
      return;
    }
    if (!dataFromServer[0].actRes.success){
      this.hasErrMsg = true;
      this.hasSucMsg = false;
      this.errMsg = dataFromServer[0].actRes.error_message;
      return;
    }
    this.hasErrMsg = false;
    this.isShowSend = false;
    let goto_viewPeople = document.getElementById("goto_viewPeople");
    goto_viewPeople.click();
  }

  onServerDeleteProfile (dataFromServer:FormatHttpGetResponse[]){
    this.isShowLoad = false;
    if (dataFromServer== null || dataFromServer.length==0) {
      this.hasErrMsg = true;
      this.hasSucMsg = false;
      this.errMsg = "ארעה שגיאה, נסו שוב מאוחר יותר";
      return;
    }
    if (!dataFromServer[0].actRes.success){
      this.hasErrMsg = true;
      this.hasSucMsg = false;
      this.errMsg = dataFromServer[0].actRes.error_message;
      return;
    }
    this.hasErrMsg = false;
    this.isShowSend = false;
    this.isShowDelete = false;
    this.isLoggedIn = false;
    this.hasSucMsg = true;
    this.isShowForgotPass = false;
    this.isShowMainEmail = false;
    this.isShowMainPass = false;
    this.sucMsg = "הפרופיל הוסר מהמערכת. אם תירצו לחזור אלינו מתישהו, פיתחו פרופיל חדש.. נשמח לפגוש אתכם בעתיד!";
  }

  onServerSentInitCode (dataFromServer:FormatHttpGetResponse[]){
    this.isShowLoad = false;
    if (dataFromServer== null || dataFromServer.length==0) {
      this.hasErrMsg = true;
      this.hasSucMsg = false;
      this.errMsg = "ארעה שגיאה, נסו שוב מאוחר יותר";
      return;
    }
    if (!dataFromServer[0].actRes.success){
      this.hasErrMsg = true;
      this.hasSucMsg = false;
      this.errMsg = dataFromServer[0].actRes.error_message;
      return;
    }
    this.hasErrMsg = false;
    this.hasSucMsg = false;
    this.isShowMainEmail = false;
    this.isShowMainPass = false;
    this.isShowSend = false;
    this.isShowSendInitCode = false;
    this.isShowIHaveInitCode = false;
    this.isShowEmailForForgotPass = false;
    this.isShowWeSentPass = true;
    this.isShowInitCodeInput = true;
    this.isShowNewPass = true;
    this.isShowChangePass = true;
    this.newpass_email = this.forgotpass_email;
    
    
  }

  async onServerChangePassword (dataFromServer:FormatHttpGetResponse[]){
    this.isShowLoad = false;
    if (dataFromServer== null || dataFromServer.length==0) {
      this.hasErrMsg = true;
      this.hasSucMsg = false;
      this.errMsg = "ארעה שגיאה, נסו שוב מאוחר יותר";
      return;
    }
    if (!dataFromServer[0].actRes.success){
      this.hasErrMsg = true;
      this.hasSucMsg = false;
      this.errMsg = dataFromServer[0].actRes.error_message;
      return;
    }
    this.hasErrMsg = false;
    this.isShowSend = false;
    this.isShowEmailForForgotPass = false;
    this.isShowSendInitCode = false;
    this.isShowIHaveInitCode = false;
    this.isShowInitCodeInput = false;
    this.isShowWeSentPass = false;
    this.isShowNewPass = false;
    this.isShowChangePass = false;


    this.hasSucMsg = true;
    this.sucMsg = "הסיסמה שונתה בהצלחה! התחברו והמשיכו לחפש חברים חדשים...";
    await delay(3000);
    let goto_viewPeople = document.getElementById("goto_viewPeople");
    goto_viewPeople.click();
  }

  /*-------------------------------------------*/
  /*-------------------------------------------*/
  /*              GUI FUNCTIONS                */
  /*-------------------------------------------*/
  /*-------------------------------------------*/

  forgot_password(){
    this.isShowSend = false;
    this.isShowMainEmail = false;
    this.isShowMainPass = false;
    this.isShowForgotPass = false;
    this.isShowEmailForForgotPass = true;
    this.isShowSendInitCode = true;
    this.isShowWeSentPass = false;
    this.isShowIHaveInitCode = true;
    this.forgotpass_email = this.login_email;
    this.isShowDelete = false;
  }

  i_have_init_code(){
    this.isShowEmailForForgotPass = false;
    this.hasErrMsg = false;
    this.hasSucMsg = false;
    this.isShowMainEmail = false;
    this.isShowMainPass = false;
    this.isShowSend = false;
    this.isShowSendInitCode = false;
    this.isShowIHaveInitCode = false;
    this.isShowEmailForForgotPass = false;
    this.isShowWeSentPass = false; //we didn't send password
    this.isShowInitCodeInput = true;
    this.isShowNewPass = true;
    this.isShowChangePass = true;
    this.newpass_email = this.forgotpass_email;
    this.isShowInitCodeInput = true;
  }


}
