import { Component, OnInit, Input} from '@angular/core';
import { ViewPeopleComponent } from '../view-people/view-people.component';
import { HttpClient, HttpParams, HttpHeaders, HttpEventType } from '@angular/common/http';
import { FormatHttpGetResponse, userProfileDetails, HttpGeneralResponse,characteristic,conversationsStatus, userPeoplePref, LocEvent, userTodoPref} from '../main-page/formatHttpGetResponse';
import { DomSanitizer, SafeUrl, SafeStyle } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { CursorError } from '@angular/compiler/src/ml_parser/lexer';

@Component({
  selector: 'app-preference',
  templateUrl: './preference.component.html',
  styleUrls: ['./preference.component.css']
})
export class PreferenceComponent implements OnInit {
  @Input() parentInp : ViewPeopleComponent;
  apiValues: string[] = [];


  
  hasErrMsg:boolean = false;
  hasSucMsg:boolean = false;
  isShowLoad:boolean = false;
  isShowSaveButton:boolean = true;
  errMsg:string = "הודעת שגיאה לדוגמה";
  sucMsg:string = "הודעת הצלחה לדוגמה";

  userProfilePartial:userProfileDetails = null;
  location_pref:string="anywhere";
  location_saved_val:string="";
  locevents:LocEvent[] = [];

  sectionShown:number=1;
  sectionMin:number=1;
  sectionMax:number=1;

  locevent_pref:string[] = [];

  

  constructor(private _http: HttpClient, private sanitizer:DomSanitizer) { }

  ngOnInit() {
    //this.getProfileDetails();
    this.locevent_pref.push("LE1001_NB1002_C1001");
    this.locevent_pref.push("LE1003_NB1002_C1001");

  }

  /*-------------------------------------------*/
  /*-------------------------------------------*/
  /*              GUI FUNCTIONS                */
  /*-------------------------------------------*/
  /*-------------------------------------------*/

  closeModal()
  {
    this.parentInp.closeModal('modalPreference','btnModalPreference');
  }

  showSaveButton()
  {
    this.isShowSaveButton = true;
    this.hasSucMsg = false;
    this.hasErrMsg = false;
  }


  showPrevSection(){
    if (this.sectionShown > this.sectionMin){
      this.sectionShown -= 1;
      let allSections = document.getElementById("all_sections");
      let divToShow = document.getElementById("section_"+this.sectionShown.toString())
      //divToShow.scrollIntoView({behavior:'smooth',block:'start'});
      allSections.scrollTo({top:-450,behavior:'smooth'})
    }
  }
  showNextSection(){
    if (this.sectionShown < this.sectionMax){
      this.sectionShown += 1;
      let divToShow = document.getElementById("section_"+this.sectionShown.toString())
      let allSections = document.getElementById("all_sections");
      //divToShow.scrollIntoView({behavior:'smooth',block:'start'});
      allSections.scrollTo({top:450,behavior:'smooth'})
    }
  }

 

  /*-------------------------------------------*/
  /*-------------------------------------------*/
  /*             SERVER REQUESTS               */
  /*-------------------------------------------*/
  /*-------------------------------------------*/

  public async getProfileDetails(){
    

    this.hasErrMsg = false;
    this.hasSucMsg = false;
    this.isShowLoad = true;
    this.isShowSaveButton = true;


    let postBody = {
      "postType" : 9,
      "message" : "pref"
    }
      
      
      let headers = new HttpHeaders()
        .set('Content-Type','application/json');
      let postObservable:Observable<FormatHttpGetResponse[]> = this._http.post<FormatHttpGetResponse[]>('/api/ClientMsg',postBody,{headers}); 
      postObservable.subscribe(
        dataFromServer => { this.onServerMsgGetProfileDetails(dataFromServer);},
        error => this.apiValues = ['error on http in checkServerMsg','error found']
      )
  }

  public async getTodoInNbhood(){
    if (this.userProfilePartial.neighborhood=="" || this.userProfilePartial.neighborhood==null ||this.userProfilePartial.city=="" || this.userProfilePartial.city==null){
      return;
    }

    let postBody = {
      "postType" : 17,
    }
      
      
      let headers = new HttpHeaders()
        .set('Content-Type','application/json');
      let postObservable:Observable<FormatHttpGetResponse[]> = this._http.post<FormatHttpGetResponse[]>('/api/ClientMsg',postBody,{headers}); 
      postObservable.subscribe(
        dataFromServer => { this.onServerMsgTodoInNbhood(dataFromServer);},
        error => this.apiValues = ['error on http in checkServerMsg','error found']
      )
  }


  async updatePref(){
    this.hasErrMsg = false;
    this.hasSucMsg = false;
    this.isShowLoad = true;
    
    // -----------
    // handle null
    // -----------

    if (this.userProfilePartial.peoplePref==null){
      this.userProfilePartial.peoplePref = new userPeoplePref();
    }
    if (this.userProfilePartial.todoPref==null){
      this.userProfilePartial.todoPref  = new userTodoPref();
    }

    // -----------
    // update values
    // -----------
    this.userProfilePartial.peoplePref.location = this.location_pref;
    this.userProfilePartial.todoPref.locationIds = [];
    this.userProfilePartial.todoPref.eventIds = [];

    for (let i:number = 0; i< this.locevents.length ; i++){
      let currentLE:LocEvent = this.locevents[i];
      if (currentLE.selectionVal){
        if (currentLE.type=="location") this.userProfilePartial.todoPref.locationIds.push(currentLE.loceventId);
        if (currentLE.type=="event") this.userProfilePartial.todoPref.eventIds.push(currentLE.loceventId);
      }
    }


    let postBody = {
      "postType" : 7,
      "message" : "pref",
      "userProfileDetails" : this.userProfilePartial
      }
      let headers = new HttpHeaders()
        .set('Content-Type','application/json');
      let postObservable:Observable<FormatHttpGetResponse[]> = this._http.post<FormatHttpGetResponse[]>('/api/ClientMsg',postBody,{headers}); 
      postObservable.subscribe(
        dataFromServer => { this.onServerMsgUpdateProfileDetails(dataFromServer);},
        error => this.apiValues = ['error on http in checkServerMsg','error found']
      )
      //await delay(1000);
      //let convStatModalBtn = document.getElementById("btnModalConvStat");
      //convStatModalBtn.click();
    
  }

  /*-------------------------------------------*/
  /*-------------------------------------------*/
  /*             SERVER REPLYS                 */
  /*-------------------------------------------*/
  /*-------------------------------------------*/


  async onServerMsgGetProfileDetails(dataFromServer:FormatHttpGetResponse[]){
    this.isShowLoad = false;
    if (dataFromServer== null || dataFromServer.length==0) return;
    if (!dataFromServer[0].actRes.success){
      this.hasErrMsg = true;
      this.errMsg = dataFromServer[0].actRes.error_message;
      return;
    }
    this.hasErrMsg = false;
    
    let profileFromServ:userProfileDetails = dataFromServer[0].upd;
    if (dataFromServer != null && profileFromServ.firstName){
      this.userProfilePartial = profileFromServ;
      if (this.userProfilePartial.peoplePref != null && this.userProfilePartial.peoplePref.location != null && this.userProfilePartial.peoplePref.location!= "")
      {
          this.location_pref = this.userProfilePartial.peoplePref.location;
          this.location_saved_val = this.location_pref;
      }
      

      this.getTodoInNbhood();
    }
    else{
    }
  }


  async onServerMsgUpdateProfileDetails(dataFromServer:FormatHttpGetResponse[]){
    this.isShowLoad = false;
    if (dataFromServer== null || dataFromServer.length==0) {
      this.hasErrMsg = true;
      this.hasSucMsg = false;
      this.errMsg = "ארעה שגיאה, נסו שוב מאוחר יותר";
      return;
    }
    if (!dataFromServer[0].actRes.success){
      this.hasErrMsg = true;
      this.hasSucMsg = false;
      this.errMsg = dataFromServer[0].actRes.error_message;
      return;
    }
    else{
      this.hasErrMsg = false;
      this.hasSucMsg = true;
      this.isShowSaveButton = false;
      this.location_saved_val = this.userProfilePartial.peoplePref.location;

      for (let i:number = 0; i< this.locevents.length ; i++){
        let currentLE:LocEvent = this.locevents[i];
        currentLE.index = i;
        
       // if (this.locevent_pref.includes(currentLE.loceventId)) {
        if (this.userProfilePartial.todoPref != null && 
              (
              this.userProfilePartial.todoPref.eventIds.includes(currentLE.loceventId)
              || this.userProfilePartial.todoPref.locationIds.includes(currentLE.loceventId)
              )
           )
        {
          currentLE.savedVal = true;
          currentLE.selectionVal = true;
        }
        else{
          currentLE.savedVal = false;
          currentLE.selectionVal = false;
        }
        //this.locevents[i].img = "/images/safsalim_small.jpg" 
        currentLE.imgSafe = this.sanitizer.bypassSecurityTrustStyle("url("+currentLE.img+")");
      }




      this.sucMsg = "אחלה! השינויים נשמרו בהצלחה";
    }
  }


  async onServerMsgTodoInNbhood(dataFromServer:FormatHttpGetResponse[]){
    {
      if (dataFromServer== null || dataFromServer.length==0) {
        this.hasErrMsg = true;
        this.hasSucMsg = false;
        this.errMsg = "ארעה שגיאה, נסו שוב מאוחר יותר";
        return;
      }
      if (!dataFromServer[0].actRes.success){
        this.hasErrMsg = true;
        this.hasSucMsg = false;
        this.errMsg = dataFromServer[0].actRes.error_message;
        return;
      }
      this.locevents = dataFromServer[0].locevents;
      if (this.locevents == null) this.locevents = [];

      for (let i:number = 0; i< this.locevents.length ; i++){
        let currentLE:LocEvent = this.locevents[i];
        currentLE.index = i;
        
       // if (this.locevent_pref.includes(currentLE.loceventId)) {
        if (this.userProfilePartial.todoPref != null && 
              (
              this.userProfilePartial.todoPref.eventIds.includes(currentLE.loceventId)
              || this.userProfilePartial.todoPref.locationIds.includes(currentLE.loceventId)
              )
           )
        {
          currentLE.savedVal = true;
          currentLE.selectionVal = true;
        }
        else{
          currentLE.savedVal = false;
          currentLE.selectionVal = false;
        }
        //this.locevents[i].img = "/images/safsalim_small.jpg" 
        currentLE.imgSafe = this.sanitizer.bypassSecurityTrustStyle("url("+currentLE.img+")");
      }




    }
  }

}
