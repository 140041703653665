import { Component, Input, OnInit } from '@angular/core';
import {TimeSlotOption} from '../../main-page/formatHttpGetResponse';
import {TalkResponseComponent} from '../talk-response.component';


@Component({
  selector: 'app-availability-slots-selection',
  templateUrl: './availability-slots-selection.component.html',
  styleUrls: ['./availability-slots-selection.component.css']
})


export class AvailabilitySlotsSelectionComponent implements OnInit {
  @Input() parent: TalkResponseComponent;

  opt_template:TimeSlotOption[] = [
    {
      type: 'availabilityToday',
      label: 'זמינות היום',
      info: '',
      selected: false,
      fromTime: '00:00',
      toTime: '23:59',
    },
    {
      type: 'weekdaysAvailability',
      label: 'בימים א-ה',
      info: 'אם אתם במקרה זמינים גם בלילות זה יהיה נפלא',
      selected: true,
      fromTime: '00:00',
      toTime: '23:59',
    },
    {
      type: 'fridayAvailability',
      label: 'בימי שישי',
      info: '',
      selected: false,
      fromTime: '00:00',
      toTime: '23:59',
    },
    {
      type: 'saturdayAvailability',
      label: 'בשבתות',
      info: '',
      selected: false,
      fromTime: '00:00',
      toTime: '23:59',
    },
  ];


  isShowOnlineByFeedbackInfo:boolean = false;
  isShowOnlineByPayInfo:boolean = false;
  
  
 

  constructor() { }

  ngOnInit() {
    this.parent.options = this.opt_template;
    this.parent.selectionChildComponent = this;
    this.copyTimeslotOptions();
  }


  updateTimeSlot(option: any) {
    // Add or remove time slot definition based on checkbox selection
    if (option.selected) {
      option.fromTime = ''; // Reset from time
      option.toTime = '';   // Reset to time
    }
  }

  copyTimeslotOptions() {
    for (const option of this.parent.options) {
      const parentOption = this.parent.user_options_from_server.find((parentOption) => parentOption.type === option.type);
      if (parentOption) {
        option.selected = parentOption.selected;
        option.fromTime = parentOption.fromTime;
        option.toTime = parentOption.toTime;
        if (option.selected){
          this.validateTimeSpan(option);
        }
      }
    }
    this.parent.selectedGender = this.parent.selectedGender_from_server;
    
  }

  validateTimeSpan(option:TimeSlotOption){
    
      const fromTime = new Date(`1970-01-01T${option.fromTime}`);
      const toTime = new Date(`1970-01-01T${option.toTime}`);

      if (option.fromTime=="" || option.toTime=="") {
        option.isValid = false;
      } else {
        option.isValid = true;
      }
      if (toTime <= fromTime){
        option.spanToNextDay = true;
      } else {
        option.spanToNextDay = false;
      }


  }

}
