import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FindFriendsComponent } from '../find-friends/find-friends.component';

@Component({
  selector: 'app-bottom-bar',
  templateUrl: './bottom-bar.component.html',
  styleUrls: ['./bottom-bar.component.css']
})
export class BottomBarComponent implements OnInit {
  @Input() parent: FindFriendsComponent;
  @Input() isLoggedIn : boolean = false;
  @Input() chatBadgeCount: number | null = null; 
  @Input() chatBadgeLoading: boolean = false; 
  @Input() likesBadgeCount: number | null = null; 
  @Input() likesBadgeLoading: boolean = false; 
  
  buttomType: string = 'buttons';
  constructor(private router: Router) { }

  ngOnInit() {
  }

  btnNewPeopleClicked() {
    if (this.parent != null){
      this.parent.switchViewByClick("theSix");
    }
    else{
      this.router.navigate(['/findfriends', 'rand_pick']);
    }
  }

  btnChatsClicked() {
    if (this.parent != null){
      this.parent.switchViewByClick("chatsList");
    }
    else {
      this.router.navigate(['/findfriends', 'chats_list']);
    }
  }

  btnLikesClicked() {
    if (this.parent != null){
      this.parent.switchViewByClick("profilesLikes");
    }
    else {
      this.router.navigate(['/findfriends', 'im_wanted']);
    }
  }

  btnProfileClicked() {
    if (this.parent != null){
      this.parent.switchViewByClick("editProfile");
    }
    else {
      this.router.navigate(['/editprofile']);
    }
  }

}
