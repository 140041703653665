import { Component, OnInit, Input } from '@angular/core';
import { PersonInfo} from '../person-info';

@Component({
  selector: 'app-thumb-card',
  templateUrl: './thumb-card.component.html',
  styleUrls: ['./thumb-card.component.css']
})
export class ThumbCardComponent implements OnInit {
  @Input() thumbInput: PersonInfo;
  
  constructor() { }
  isImgLoaded : boolean = false;

  ngOnInit() {
  }

  requestClicked(msgId:number){
    
    
  }
  showImage(){
    this.isImgLoaded = true;
  }

}
