import { sanitizeIdentifier } from '@angular/compiler';
import { DomSanitizer, SafeUrl, SafeStyle } from '@angular/platform-browser';

import {ViewPeopleComponent} from '../view-people/view-people.component';
import { SessionData } from '../services/cookie.service';
import { Profile } from '../the-six/the-six.component';

export class FormatHttpGetResponse {
    /*
     * simple struct to associate user message with the msgBox
     */
     msgId: number;
     dateBegin: MyDate;
     dateEnd: MyDate;
     location: string;
     isRequested: boolean;
     interested: number;
     userFirstName: string;
     userGender:string;
     lastLogin:Date;
     statusCode:number;
     actRes: ActRes;
     hgr: HttpGeneralResponse[];
     convStatArr : conversationsStatus[];
     listInterested: string[];
     locevents : LocEvent[];
     upd:userProfileDetails;
     sigUpdates:SigUpdate[];
     acknowledgedLogIds:string[];
     logActivities: MNG_logActivity[];
     statistics:number[];
     premiumFeatures:FeatureInfo[];
     privileges:string[];
     talkRequestStatuses:SearchStatus[];
     userAvailability: UserAvailability;
     talkRequestors:HttpGeneralResponse[];
     profileSpan:string[];
     relRecord: RelationshipRecord;

  
    constructor(
        {msgId, dateBegin, dateEnd, location, isRequested, interested}:
        {msgId:number,dateBegin: MyDate, dateEnd: MyDate, location:string, isRequested: boolean, interested: number}
        ){
      this.msgId=msgId;
      this.dateBegin = dateBegin;
      this.dateEnd = dateEnd;
      this.location=location;
      this.isRequested = isRequested;
      this.interested = interested;

    }
}

export class ActRes{
    success:boolean=true;
    error_message:string="";
}

/*
// PersonInfo - 
        Used for thumbs and profile presentation. 
        This is "other people profile" data that I'm allowed to see.

// userProfileDetails  - 
        Used in edit-profile, while getting profile from server. 
        Used to recieve data from server as part of FormatHttpGetResponse
        This is "my profile" data.

// UPD - 
        Used only in manage, user profile to update. 
        This is what manager need to know about peoples profile.
        
// HttpGeneralResponse - 
        Used to recieve data from server, as part of formatHttpGetResponse. translated to person info in order to use it. 
*/



export class userProfileDetails{
    updCreationTime:Date;
    publicId:number;
    email:string="";
    password:string="";
    agreeUserTerms:boolean = false;
    age:number;
    firstName:string;
    gender:string;
    phone:string;
    neighborhood:string="";
    city:string="";
    telegram:string;
    usertext:string;
    purposeopt:boolean[];
    tempImgFolder:string;
    profilePath:string="";
    notifyPref:userNotifyPref;
    peoplePref:userPeoplePref;
    todoPref:userTodoPref;
  }

  export class userNotifyPref{
    mailNewMsg:boolean = false;
    smsNewMsg:boolean = false;
    mailReminder:boolean = false;
    smsReminder:boolean = false;
    mailMaxFreqDays:number = 0; //every 0 days I can send mail
    smsMaxFreqDays:number = 0;
    mailRemindAfterDays:number = 3; // if no read or no action after read and 3 days passed - remind
    smsRemindAfterDays:number = 3; // if no read or no action after read and 3 days passed - remind
    notifyLimit:boolean = false;
    defined:boolean = false;
   }
   
   export class userPeoplePref
   {
       location:string; // nbhood, friends-of-nbhood, city, anywhere
       meetInGroup:string; // none, few_3_4, many
   }

   export class userTodoPref
   {
       eventIds:string[]=[]; 
       locationIds:string[]=[]; // none, few_3_4, many
   }

   export class LocEvent
   {
    index:number;  
    type:string;
       loceventId:string;
       name:string;
       text:string;
       link:string;
       where:string;
       img:string;
       imgSafe:SafeStyle;
       eventStartTime:Date;
       selectionVal:boolean;
       savedVal:boolean;

   }

   export class SigUpdate
   {
    convId:string ;
    new_red_sig:string ;
    new_info_sig:string ;
    convstat_obj:conversationsStatus ;
   }

   export class SigViewedReport
   {
    convId:string ;
    viewed_sig:string ;
    amountOfMsgs:number ;
   }
   


export class MyDate{
    year: number; month:number; day:number;
    hours:number; minutes:number; seconds:number;
}

export class HttpGeneralResponse
    {
        publicId:number;
        firstName:string;
        lastName:string ;
        age:number ;
        imgFile:string ;
        isAllowed:boolean;
        onlineStatus:string;
        isExample:boolean;
        gsImgFile:string;
        neighborhood:string ="";
        city:string="";
        characteristics:characteristic[] ;
        usertext:string;
        loceventsMatch:string[];
        imgFileSafe:SafeStyle;
        phone:string;
        relRecord: RelationshipRecord;

    }

        
export class characteristic
    {
        character:string ;
        valType:string ;           //can be: "selection"/"freeText"/"number"/"bool"
        textVal:string ;
        numberVal:number ;
        boolVal:boolean ;
    }

    export class RelationshipRecord {
        userId: number;  // The ID of the user
        linkedUserId: number;  // The ID of the linked user
        dateLinked: Date;  // The date when this link was created
        dateUpdated: Date;  // The date when this link was updated
        //interestTags: InterestTag[];  // Tags indicating levels of interest (e.g., "CoffeeMeet", "CasualTalk", "PotentialDate", "PhysicalInteraction", etc.)
        interestTags : InterestTags;
        relationshipStatusTags: RelationshipStatus[];  // Tags indicating levels of relationship (e.g., "ExchangedContactInfo", "MetInPerson")
        reported: boolean;  // Has the user reported this link
        reportReason: string;  // Reason for reporting
      
        constructor(init?: Partial<RelationshipRecord>) {
          Object.assign(this, init);
        }
        
      }

    
      export class InterestTags {
        agreeToNone: boolean = false;
        coffeeAgree: boolean = false;
        wouldLikeCasualTalk: boolean = false;
        friendship: boolean = false;
        potentialDate: boolean = false;
        physicalInteraction: boolean = false;
      }
      
      // Enum for Relationship Status
      export enum RelationshipStatus {
        ExchangedContactInfo = 'ExchangedContactInfo',
        MetInPerson = 'MetInPerson',
        OngoingCommunication = 'OngoingCommunication',
        EndedInteraction = 'EndedInteraction'
      }
      
     
      


export class conversationsStatus  
{
    parent:ViewPeopleComponent;
    initiationID:string;
    timeOfInitation:Date;
    otherPersonPublicId:number;
    otherPersonFirstName:string;
    otherPersonGender:string;
    otherPersonOnlineStatus:string;
    userGender:string;
    otherPersonPic:string;
    otherPersonPicSafe:SafeStyle;
    isMyInitiation:boolean;
    isMyTurn:boolean;
    lastMessageType:string;
    lastMessage:string;
    timeLastMessage:Date;
    msgLogList:MsgLog[];
    highlight:string="";
    isWaitingApproval:boolean = false;
    isShowRedNotify:boolean = false;
    convStatAllSigAware:string = "";

    otherPersonAge:number;
    myPublicID:number;
    myFirstName:string;
    myGender:string;
    myAge:number;
    myImagePath:string;


    
    
    constructor({parent,initiationID,timeOfInitation,otherPersonPublicId,otherPersonFirstName,otherPersonGender,otherPersonOnlineStatus,userGender,otherPersonPic,isMyInitiation,isMyTurn,lastMessageType,lastMessage,timeLastMessage}){
        this.parent=parent;
        this.initiationID=initiationID;
        this.timeOfInitation=timeOfInitation;
        this.otherPersonPublicId=otherPersonPublicId;
        this.otherPersonFirstName = otherPersonFirstName;
        this.otherPersonGender = otherPersonGender;
        this.otherPersonOnlineStatus = otherPersonOnlineStatus;
        this.userGender = userGender
        this.otherPersonPic=otherPersonPic;
        this.isMyInitiation=isMyInitiation;
        this.isMyTurn = isMyTurn;
        this.lastMessageType = lastMessageType;
        this.lastMessage = lastMessage;
        this.timeLastMessage = timeLastMessage;
        this.updateImgPath();
    }

    static createFromSessionAndProfile(sessionData: SessionData, userProfile: Profile): conversationsStatus {
        const initiationID = "i" + sessionData.userPublicID + "_" + Math.floor(Math.random() * 1000000).toString();
        let otherPersonPicToSet = userProfile.imageUrl.replace("/images/people_images/", "");
        let newConv = new conversationsStatus({
            parent: null,
            initiationID: initiationID,
            timeOfInitation: new Date(),
            otherPersonPublicId: userProfile.userId,
            otherPersonFirstName: userProfile.name,
            otherPersonGender: 'male',
            otherPersonOnlineStatus: userProfile.onlineStatus != "" ? 'Online' : 'Offline',
            userGender: sessionData.userGender!,
            otherPersonPic: otherPersonPicToSet,
            isMyInitiation: true, // based on desired initiation logic
            isMyTurn: true,       // can be updated based on conditions
            lastMessageType: '',
            lastMessage: '',
            timeLastMessage: new Date(),
            
        });
        newConv.msgLogList = [];
        return newConv;
    }

    
    updateImgPath(){
        this.otherPersonPic = "/images/people_images/" + this.otherPersonPic;
    }

    clone(){
        let convI = new conversationsStatus({
            parent:this,
            initiationID:this.initiationID,timeOfInitation:this.timeOfInitation,
            otherPersonPublicId:this.otherPersonPublicId,otherPersonFirstName:this.otherPersonFirstName,
            otherPersonGender:this.otherPersonGender, otherPersonOnlineStatus:this.otherPersonOnlineStatus, userGender:this.userGender,
            otherPersonPic:this.otherPersonPic,isMyInitiation:this.isMyInitiation,
            isMyTurn:this.isMyTurn,lastMessageType:this.lastMessageType,
            lastMessage:this.lastMessage,timeLastMessage:this.timeLastMessage
          })
        return convI;
    }
}

export class MsgLog{
    fromID:number;
    toID:number;
    msgType:string;
    content:string;
    isNewWatch:boolean;
    timeCreated:Date;
    index:number;
    msgID:string="";
    msgStatus:string=""; //sending, sent_approved, error_send, was_read
    msgViewType:string=""; //chat, date, sysmsg

    fromFirstName:string;
    isFromUser:boolean;
    msgLogCssClass:string = "";
    msgLogCssClassFinal:string = "";
}

export class FeatureInfo {
    featureType: string; // Type of premium feature
    purchaseDate: Date; // Date when the feature was purchased
    purchaseMethod: string; // Method of purchase
    isActiveNow: boolean; // Indicates whether the feature is currently active 
    isExpired: boolean; // Indicates whether the feature is expired (it may be not active due to hours of the day or other condition, but not expired)
    activeUntil: Date; // Date and time until which the feature will be active
}

export class SearchStatus {
    displayText:string;
    updateTime:Date;
    sequenceNum:number;
    statusType:number; // 0 = update ongoing, 1= success, 2= failure
    isActive:boolean;

    constructor(statusType:number,displayText:string, isActive:boolean){
        this.updateTime = new Date (Date());
        this.displayText = displayText;
        this.statusType = statusType;
        this.isActive = isActive;

    }

}

export class TimeSlotOption {
    type: string;
    label: string;
    info: string;
    selected: boolean;
    fromTime: string; // Change to string
    toTime: string;   // Change to string
    isValid?: boolean; //validation of from-to
    spanToNextDay?: boolean // in case the to is less than from, assume it's the next day and mark it in label on GUI
}

export class UserAvailability {
    userID:number;
    updatedAt:Date;
    timeSlotOptions:TimeSlotOption[];
    genderSelection:GenderSelection = new GenderSelection();
}

export class GenderSelection {
    male: boolean = false;
    female: boolean = false;
    other: boolean = false;
}


export class FormField {
    type: string;       // Field type (e.g., email, password, text, etc.)
    label: string;      // Field label (e.g., "דוא"ל (שם משתמש)")
    small?: string; //Field small comment below as more info for the user 
    name: string;       // Field name (used as the form control name)
    validators: string[]; // Array of validator names (e.g., "required", "email", etc.)
    options?: { label: string; value: string }[]; // Updated: Array of objects for select options
    placeholder?: string; //string placeholder for textboxes mainly
    rows?: number; // Optional property for textareas
    checkboxLabel?: string; //Optional property for checkbox
    

  
   /* constructor(data: any) {
      this.type = data.type || '';
      this.label = data.label || '';
      this.small = data.small || '';
      this.name = data.name || '';
      this.validators = data.validators || [];
      this.options = data.options || [];
      this.placeholder = data.placeholder || '';
    }*/
  }

export class FormSection{
    name:string;
    title:string;
    fields:FormField[];
    isVisible: boolean;
}


export class MNG_logActivity{
    id: string;
    time: Date;
    activity: string;
    act_type: string;
    mentionedIds: number[];
}
