import { Component, OnInit, Input } from '@angular/core';
import { AvailSlotInfo} from '../avail-slot-info';

@Component({
  selector: 'app-avail-slot',
  templateUrl: './avail-slot.component.html',
  styleUrls: ['./avail-slot.component.css']
})
export class AvailSlotComponent implements OnInit {
  @Input() availRealInp: AvailSlotInfo;
  constructor() { }

  ngOnInit() {
  }

  requestClicked(msgId:number){
    this.availRealInp.parent.showModal(this.availRealInp.msgId);
  }

}
