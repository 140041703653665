import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CookieService {

  constructor() { }

  private getAllCookies(): { [key: string]: string } {
    const cookies: { [key: string]: string } = {};
    const documentCookies = document.cookie.split(';');

    documentCookies.forEach(cookie => {
      const [key, value] = cookie.split('=').map(c => c.trim());
      if (key && value) {
        cookies[key] = decodeURIComponent(value); // Store the cookie value in a dictionary
      }
    });

    return cookies;
  }


  setCookie(name: string, value: string, days: number): void {
    const date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    const expires = `expires=${date.toUTCString()}`;
    document.cookie = `${name}=${value};${expires};path=/`;
  }

  deleteCookie(name: string): void {
    this.setCookie(name, '', -1);
  } 

  // Method to get session data from cookies and return an instance of SessionData
  getSessionData(): SessionData {
    const cookies = this.getAllCookies();

    return new SessionData(
      cookies['session_UserType'],       
      cookies['session_UserPublicID'],   
      cookies['session_UserFirstName'],  
      cookies['session_UserGender'],     
      cookies['session_UserLifeCycleState'] 
    );
  }


}


export class SessionData {
  constructor(
    public userType: string | undefined,
    public userPublicID: string | undefined,
    public userFirstName: string | undefined,
    public userGender: string | undefined,
    public userLifeCycleState: string | undefined
  ) {}
}