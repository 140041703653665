import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FindFriendsComponent } from '../find-friends/find-friends.component';
import { FormatHttpGetResponse, MsgLog, conversationsStatus } from '../main-page/formatHttpGetResponse';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { delay } from 'q';


@Component({
  selector: 'app-chat-view',
  templateUrl: './chat-view.component.html',
  styleUrls: ['./chat-view.component.css']
})
export class ChatViewComponent implements OnInit {
  @Input() parent: FindFriendsComponent;
  @Input() convInActiveChat : conversationsStatus;

  @ViewChild('scrollContainer', { static: false }) private scrollContainer: ElementRef;

  textEnteredByUserInChat:string = "";
  userGender:string = "male"; //delete me


  constructor(private _http: HttpClient) { }

  ngOnInit() {
    this.parent.prepareAllMsg();
    this.parent.childChatViewComponent = this;

  }
  ngAfterViewInit(){
    this.scrollToLastInChat(false);
  }


  async ChatTextSendClicked(){
    if (this.parent.convReqInFlight) return; this.parent.convReqInFlight = true;
    this.parent.convReqInFlight = !this.parent.convReqInFlight;
    if (this.textEnteredByUserInChat.length<1) return;
    if (this.textEnteredByUserInChat.length>160) {
      let errMsg = "הטקסט צריך להיות באורך תו אחד לפחות, וקטן מ160 תווים. \n";
      errMsg += "כרגע יש " + this.textEnteredByUserInChat.length.toString() + " תווים." ;
      alert(errMsg);
      return;
    }

    let ml:MsgLog = new MsgLog();
    ml.index = this.convInActiveChat.msgLogList.length;
    ml.fromID = 0;
    ml.msgType=="text";
    ml.content = this.textEnteredByUserInChat;
    ml.timeCreated = new Date();
    ml.msgID = `${this.convInActiveChat.initiationID}_${ml.timeCreated.toISOString().replace(/[-:T.]/g, '').slice(0, 14)}_${this.generateShortId(4)}`;
    ml.msgViewType = 'chat';
    ml.msgStatus = 'sending';
    
    this.convInActiveChat.msgLogList.push(ml);
    this.parent.prepareAllMsg();
    this.scrollToLastInChat();

    let postBody = {
      "postType" : 3,
      "callerId" : 0, // to be set in parent
      "referToPerson" : this.convInActiveChat.otherPersonPublicId,
      "messageType" : "text_"+ml.msgID,
      "message" : this.textEnteredByUserInChat,
    }
    this.parent.logActivity("send text to U"+ this.convInActiveChat.otherPersonPublicId, "click");
    this.parent.sendConvRequest(postBody, ml);
    this.clearChatTextBox();
  }

 
/*
  async sendConvRequest2(postBody, msglogItem:MsgLog=new MsgLog()){
    if (this.parent.convReqInFlight) return; this.parent.convReqInFlight = true;

    let highlightfirst:boolean=false;
    if (postBody.messageType != "clear_del") highlightfirst = true;
    let headers = new HttpHeaders()
      .set('Content-Type','application/json');
    let postObservable:Observable<FormatHttpGetResponse[]> = this._http.post<FormatHttpGetResponse[]>('/api/ClientMsg',postBody,{headers}); 
    postObservable.subscribe(
      dataFromServer => { this.parent.onServerReply_GetConvStatus(dataFromServer,msglogItem,highlightfirst);},

    )
  }
*/
  async clearChatTextBox(){
    this.textEnteredByUserInChat = "";
  }

  public async scrollToLastInChat(isSmooth:boolean=true){
    await delay(100);

    try {
      this.scrollContainer.nativeElement.scrollTo({
        top: this.scrollContainer.nativeElement.scrollHeight,
        behavior: isSmooth ? 'smooth' : 'auto'
      });
    } catch (err) {
      console.error('Scroll error:', err);
    }
 
  }

/*
  prepareAllMsg(){
    if (this.convInActiveChat.msgLogList.length > 0){
      for (let i:number = 0; i< this.convInActiveChat.msgLogList.length ; i++){
        this.convInActiveChat.msgLogList[i].index = i;
        if (this.convInActiveChat.msgLogList[i].fromID==this.convInActiveChat.otherPersonPublicId){
          this.convInActiveChat.msgLogList[i].isFromUser = false;
          this.convInActiveChat.msgLogList[i].fromFirstName= this.convInActiveChat.otherPersonFirstName;
          if (this.convInActiveChat.msgLogList[i].msgType == "ping"){
            if (this.convInActiveChat.otherPersonGender=="female") this.convInActiveChat.msgLogList[i].content="[שלחה אליך פינג]";
            if (this.convInActiveChat.otherPersonGender=="male") this.convInActiveChat.msgLogList[i].content="[שלח אליך פינג]";
          }
        }
        else{
          if (this.userGender=="female") this.convInActiveChat.msgLogList[i].fromFirstName="את";
          if (this.userGender=="male") this.convInActiveChat.msgLogList[i].fromFirstName="אתה";
          this.convInActiveChat.msgLogList[i].isFromUser = true;
          if (this.convInActiveChat.msgLogList[i].msgType == "ping"){
            if (this.convInActiveChat.otherPersonGender=="female") this.convInActiveChat.msgLogList[i].content="[שלחת אליה פינג]";
            if (this.convInActiveChat.otherPersonGender=="male") this.convInActiveChat.msgLogList[i].content="[שלחת אליו פינג]";
          }
        }
        if (this.convInActiveChat.msgLogList[i].isFromUser){
          this.convInActiveChat.msgLogList[i].msgLogCssClass="msgFromUser";
        }
        else{
          this.convInActiveChat.msgLogList[i].msgLogCssClass="msgFromOther";
        }
        this.convInActiveChat.msgLogList[i].msgLogCssClassFinal = this.convInActiveChat.msgLogList[i].msgLogCssClass;


      }
    }
  }*/

  generateShortId(length: number = 4): string {
    let result = '';
  
    for (let i = 0; i < length; i++) {
      // Generate a random integer between 1 and 36
      const randomInt = Math.floor(Math.random() * 36) + 1;
      // Convert the integer to a base-36 string
      result += randomInt.toString(36);
    }
  
    return result;
  }

}
