import { Component, Input, OnInit } from '@angular/core';
import { FindFriendsComponent } from '../find-friends/find-friends.component';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { delay } from 'q';
import { Observable } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { FormatHttpGetResponse, RelationshipRecord } from '../main-page/formatHttpGetResponse';
import { PersonInfo } from '../person-info';



export interface Profile {
  userId:number ;
  imageUrl: string;    // URL of the profile image
  name: string;        // Name of the profile
  age: number;         // Age of the profile user
  usertext: string; // Short description or status of the profile
  isAllowed: boolean;  // Indicates whether the profile is allowed (for styling purposes)
  isImgLoaded: boolean; // Tracks if the image is loaded (used for showing placeholder)

  city: string;           // City where the user lives
  neighborhood: string;   // Neighborhood of the user
  likes: number;          // Number of likes or upvotes the user has received
  trustRate: number;      // Trust rating of the user
  onlineStatus: string;  // Indicates whether the user is currently online
  relRecord: RelationshipRecord;
}

@Component({
  selector: 'app-the-six',
  templateUrl: './the-six.component.html',
  styleUrls: ['./the-six.component.css']
})
export class TheSixComponent implements OnInit {

  @Input() parent: FindFriendsComponent;
  @Input() profiles: Profile[] = [];

  // requestBotton 
  requestBottonTxt:string= "הגרל חבר!";
  nextRequestCountDown : number = 0;

  // Database
  thumbInfoList: PersonInfo[]=[]; 

  constructor(private _http: HttpClient, private sanitizer:DomSanitizer) { }

  ngOnInit() {
    if (this.profiles.length==0){
      this.serverReq_updateTheSix(0);
    }
  }

  showProfile(profile: Profile) {
    if (profile.isAllowed){
      this.parent.showProfile(profile);
    }
    else {
      this.parent.showError("אפשר לפנות רק לחברים שהוגרלו (בתמונות הצבעוניות)")
    }
  }


   async serverReq_updateTheSix(secDelay: number) {
    if (this.nextRequestCountDown > 0) {
      return;
    }

    this.requestBottonTxt = "מגריל...";
    this.nextRequestCountDown = 5;
    await delay(secDelay * 1000);
    this.parent.serverReq_updateTheSix(this);
  }



  async countDownDec(){
    await delay(1000);
    this.nextRequestCountDown -= 1;
    if (this.nextRequestCountDown>0){
        this.requestBottonTxt = "ניתן להגריל שוב בעוד " +this.nextRequestCountDown.toString()+ " שניות";
        this.countDownDec();
    }
    else{
      this.requestBottonTxt = "הגרל שוב!";
    }
  }

  // Method to return cached image URL or original URL if not cached
  getCachedImage(url: string): string {
    return this.parent.imageCache[url] && this.parent.imageCache[url].src ? this.parent.imageCache[url].src : url;
    
  }

  // Handle image load and cache the image
  onImageLoad(profile: Profile, event: Event): void {
    profile.isImgLoaded = true;
    
    // Cache the image if it's not already cached
    //const imgElement = event.target as HTMLImageElement;
    const imgElement = (event.target as HTMLImageElement).cloneNode() as HTMLImageElement; // Clone the element
    if (!this.parent.imageCache[profile.imageUrl]) {
      this.parent.imageCache[profile.imageUrl] = imgElement;
    }
  }


}
